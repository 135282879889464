import React from 'react'
import { AdvancedChart } from 'react-tradingview-embed'

export default function TradingChart({selectedMarket}) {
  return (
    <>
      <div className="main-chart mb15">
        <AdvancedChart
          widgetProps={{
            theme: 'dark',
            symbol: `BINANCE:${selectedMarket.id?.toUpperCase()}`,
            allow_symbol_change: true,
            toolbar_bg: '#000',
            height: 550
          }}
        />
      </div>
    </>
  )
}
