// import React, { Component } from 'react'
// import { BrowserRouter, Route } from 'react-router-dom'
// import { ThemeProvider } from './context/ThemeContext'
// import Index from './pages'
// import { Provider } from 'react-redux'
// import { store, persistor } from './redux/store'
// import { PersistGate } from 'redux-persist/integration/react'
// import { setThemeColor } from './redux/slices/themeSlice'
// const ScrollToTop = () => {
//   window.scrollTo(0, 0)
//   return null
// }
// document.body.classList.toggle('dark')

// export default class App extends Component {
//   state = {
//     theme: 'dark'
//   }
//   render() {
//     return (
//         <PersistGate persistor={persistor}>
//           <BrowserRouter>
//             <Route component={ScrollToTop} />
//             <ThemeProvider
//               value={{
//                 data: this.state,
//                 update: () => {
//                   this.setState((state) => ({
//                     theme:
//                       state.theme === 'light' ? (this.theme = 'dark') : (this.theme = 'light')
//                   }))
//                 }
//               }}
//             >
//               <Index />
//             </ThemeProvider>
//           </BrowserRouter>
//         </PersistGate>
//     )
//   }
// }
import { configureStore } from '@reduxjs/toolkit'
import React, { Component, useEffect, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider } from './context/ThemeContext'
import Index from './pages'
// import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { setThemeColor } from './redux/slices/themeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import {languageMap} from './translations'
import { ToastContainer } from 'react-toastify'
import languageReducer from './redux/slices/languageSlice'
import { _getUserInfo } from './redux/actions'
import { setUser, logoutUser } from './modules/user/redux/slices/userDataSlice'
import KYCReminderModal from './modules/kyc/components/KYCReminderModal'
const ScrollToTop = () => {
  window.scrollTo(0, 0)
  return null
}

document.body.classList.toggle('dark')

const App = () => {
  // const themeColor = useSelector((state) => state.themeColor?.value)
  const lang = useSelector((state) => state.language.code)
  // console.log(lang)
  const [theme, setTheme] = useState('dark')

  const [KYCReminderOpen, setKYCReminderOpen] = useState(true)
  const dispatch = useDispatch()
  // dispatch(setThemeColor(theme))

  useEffect(() => {
    _getUserInfo(
      (data) => {
        dispatch(setUser(data))
        if (data.errors) {
          // console.log(data.errors)
          dispatch(logoutUser())
        }
      },
      (error) => {
        // console.log(error)
        if (error.errors) {
          dispatch(logoutUser())
        }
      }
    )
  }, [])
  return (
    <PersistGate persistor={persistor}>
      <IntlProvider locale='en' messages={languageMap[lang]}>
        <BrowserRouter>
          <Route component={ScrollToTop} />
          <ThemeProvider
            value={{
              data: theme,
              update: () => {
                setTheme(theme === 'dark' ? 'light' : 'dark')
                // dispatch(setThemeColor(theme === 'dark' ? 'light' : 'dark'))
              }
            }}
          >
            <Index />
            <ToastContainer newestOnTop />

            {/* KYC REMINDER */}
            <KYCReminderModal
              open={KYCReminderOpen}
              handleClose={() => setKYCReminderOpen(false)}
            />

          </ThemeProvider>
        </BrowserRouter>
      </IntlProvider>
    </PersistGate>
  )

}
export default App