import React from 'react'

// packages
import Shimmer from "react-shimmer-effect"


function ShimmerLine({
  opacity = '.4',
  width = '96px',
  height = '12px',
  borderRadius = '5px',
  style
}) {

  const StyleSheet = {
    line: {
      width,
      height,
      // alignSelf: "center",
      // marginLeft: "16px",
      borderRadius
    }
  }

  return (
    <div style={{
      opacity,
      ...style
    }}>
        <Shimmer>
          <div style={StyleSheet.line} />
        </Shimmer>
      </div>
  )
}

export default ShimmerLine