import { createSlice } from '@reduxjs/toolkit'

const currencySlice = createSlice({
  name: 'currency',
  initialState: { code: 'usd' },
  reducers: {
    setcurrency: (state, action) => {
      state.code = action.payload
    }
  }
})

export const { setcurrency } = currencySlice.actions

export default currencySlice.reducer
