import React from 'react'

// styles
import Styles from './insightCard.module.css'

// icons
import UpArrow from '../../../../assets/icons/up-arrow.svg'
import DownArrow from '../../../../assets/icons/down-arrow.svg'

function InsightCard({
    title,
    titleIcon,
    price,
    percentageChange
}) {
  return (
    <div className={Styles.card}>

        <div className={Styles.header}>
            {titleIcon}
            {title}
        </div>

        <div className={Styles.price}>{price}</div>

        {
          percentageChange >= 0 ? (
            <div className={Styles.coinChange} style={{color: 'var(--positive-color)'}}>
              +{percentageChange}
              <img
                src={UpArrow}
              />
            </div>
          ) : (
            <div className={Styles.coinChange} style={{color: 'var(--negative-color)'}}>
              {percentageChange}
              <img
                src={DownArrow}
              />
            </div>
          )
        }

    </div>
  )
}

export default InsightCard