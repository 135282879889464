import React, { useState, useEffect } from 'react'

// styles
import Styles from './kycVerification.module.css'

// icons
import { ReactComponent as MobileIcon } from '../../../assets/icons/imported/mobile.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/imported/edit.svg'

// components
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import InputCode from '../../../components/InputCode'
import CheckboxInput from '../../../components/CheckboxInput/CheckboxInput'
import { toast } from 'react-toastify'

// translations
import { FormattedMessage, injectIntl } from "react-intl"

// apis
import { _sendCode } from '../redux/actions'
const formInputStyle = {
    backgroundColor: 'black'
}

const formInputContainerStyle = {
    flex: 1
}

function KYCVerification({
    step = 'SMS' // verifyNumber - SMS - form
}) {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [politicallyExposedChecked, setPoliticallyExposedChecked] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const [privacyChecked, setPrivacyChecked] = useState(false)
    const [riskChecked, setRiskChecked] = useState(false)

    const sendVerification = () => {
        _sendCode(
            {
                phone_number: phoneNumber
            },
            (data) => {
                toast.success(
                    <FormattedMessage id={data.message} />
                  )
            },
            () => {
    
            }
        )
    }
    useEffect(() => {
        console.log(phoneNumber)
    }, [phoneNumber])
  return (
    <>
        {
            step === 'verifyNumber' && (
                <div className={Styles.verifyNumberContainer}>
                    <h3>KYC PROCESS</h3>

                    {/* step number container */}
                    <div className={Styles.stepNumberContainer}>
                        <div>1</div>
                        <div>
                            <span>Verify Phone</span>
                            <span>Lorem Ipsum is simply dummy text of the printing.</span>
                        </div>
                    </div>

                    <Input
                        type='phoneNumber'
                        label='Enter phone number'
                        inputIconContainerStyle={{backgroundColor: 'black'}}
                        onChange={e => { 
                            setPhoneNumber(e) 
                        }}
                    />

                    <Button
                        text='Send'
                        onClick={sendVerification}
                        style={{marginTop: 20}}
                    />
                </div>
            )
        }

        {
            step === 'SMS' && (
                <div className={Styles.smsContainer}>

                    {/* icon */}
                    <div className={Styles.smsIconContainer}>
                        <MobileIcon width={17}/>
                    </div>

                    <h5>SMS Phone Verification</h5>

                    <span className={Styles.smsDescription}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has</span>

                    <Button
                        text='+971 1234567890'
                        theme='outlined'
                        icon={<EditIcon width={12}/>}
                        iconPosition='right'
                        style={{margin: '20px 0', width: 'fit-content'}}
                    />

                    <InputCode
                        length={4}
                        inputStyle={{
                            backgroundColor: 'black',
                            fontSize: 13,
                            borderColor: 'black',
                            borderRadius: 5
                        }}
                    />

                    <Button
                        text='Resend code in 3 mins'
                        theme='link'
                    />

                    <Button
                        text='Confirm'
                    />
                </div>
            )
        }

        {
            step === 'form' && (
                <div className={Styles.formContainer}>
                    <h3>KYC PROCESS</h3>

                    <div className={Styles.stepNumberContainer}>
                        <div>2</div>
                        <div>
                            <span style={{color: 'white', fontSize: 16}}>Verification Form</span>
                        </div>
                    </div>

                    <span className={Styles.formSectionTitle}>Personal Information</span>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='Nationality'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='Employment Status'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <span className={Styles.formSectionTitle}>Address Information</span>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='Apartment number    '
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='Street name'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='City / Town'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='Block Name / Number'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='Country'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='Postal code'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <span className={Styles.formSectionTitle}>Additional Information</span>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='Industry you work in'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='Purpose of opening account'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <div className={Styles.formRowContainer}>
                        <Input
                            inputLabel='Expected monthly account volume'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                        <Input
                            inputLabel='What are your source of funds?'
                            inputIconContainerStyle={{...formInputStyle}}
                            containerStyle={{...formInputContainerStyle}}
                        />
                    </div>

                    <div className={Styles.formRowContainer}>
                        <CheckboxInput
                            checkboxId='political-person'
                            labelFor='political-person'
                            label='Are you a politically exposed person?'
                            checked={politicallyExposedChecked}
                            setChecked={setPoliticallyExposedChecked}
                            containerStyle={{flex: 1}}
                        />
                        <CheckboxInput
                            checkboxId='privacy'
                            labelFor='privacy'
                            label='I have read and agree to the privacy and policy'
                            checked={privacyChecked}
                            setChecked={setPrivacyChecked}
                            containerStyle={{flex: 1}}
                        />
                    </div>

                    <div className={Styles.formRowContainer}>
                        <CheckboxInput
                            checkboxId='terms'
                            labelFor='terms'
                            label='I have read and agree to the terms and conditions'
                            checked={termsChecked}
                            setChecked={setTermsChecked}
                            containerStyle={{flex: 1}}
                        />
                        <CheckboxInput
                            checkboxId='risks'
                            labelFor='risks'
                            label='I have read & understood the risks related to Cryptocurrency'
                            checked={riskChecked}
                            setChecked={setRiskChecked}
                            containerStyle={{flex: 1}}
                        />
                    </div>

                    <Button
                        text='Submit'
                        style={{
                            width:'20%',
                            margin: '80px auto',
                            marginBottom: 10
                        }}
                    />

                </div>
            )
        }
    </>
  )
}

export default KYCVerification