// import { createSlice } from '@reduxjs/toolkit'

// export const tradesSlice = createSlice({
//   name: 'trades',
//   initialState: {
//     value: []
//   },
//   reducers: {
//     changeTrade: (state, payload) => {
//       state.value = payload.payload
//     },
//     clearTrades: (state, payload) => {
//       state.value = []
//     },
//     addTrade: (state, payload) => {
//       state.push(payload.payload)
//     }
//   }
// })

// // Action creators are generated for each case reducer function
// export const { changeTrade, clearTrades, addTrade } = tradesSlice.actions

// export default tradesSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    value: []
  },
  reducers: {
    changeTrade: (state, action) => {
      state.value = action.payload
    },
    clearTrades: (state) => {
      state.value = []
    },
    addTrade: (state, payload) => {
      state.value.push(payload.payload)
    }
  }
})

export const { changeTrade, clearTrades, addTrade } = tradesSlice.actions

export default tradesSlice.reducer