import React from 'react'

// styles
import Styles from './blogCard.module.css'

// icons
import { CgProfile } from 'react-icons/cg'
import { AiOutlineClockCircle } from 'react-icons/ai'

// components
import Button from '../../../components/Button/Button'

function BlogCard() {
  return (
    <div className={Styles.cardContainer}>

        {/* blog thumbnail */}
        <div className={Styles.thumbnailContainer}>

        </div>

        {/* blog writer + date */}
        <div className={Styles.blogInfoContainer}>
            {/* writer */}
            <div>
                <CgProfile color='var(--primary-color)' size={13}/>
                ALEXTINA
            </div>

            {/* date */}
            <div>
                <AiOutlineClockCircle  color='var(--primary-color)' size={13}/>
                DEC 28, 2022
            </div>

        </div>

        {/* blog title */}
        <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        </h2>

        {/* blog description */}
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis ullamcorper nibh, at viverra mi. Aenean non ultrices arcu, eu sagittis metus
        </p>

        <Button
            text='Read More'
            style={{width: 'fit-content'}}
        />

    </div>
  )
}

export default BlogCard