import React, { useEffect, useState } from 'react'

// styles
import Styles from './walletHistoryTab.module.css'

// icons
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/imported/down-arrow.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/imported/calendar.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/imported/filter.svg'
import { FiFilter, FiCalendar } from 'react-icons/fi'
import { BsChevronDown } from 'react-icons/bs'
import { IoMdArrowDropup } from 'react-icons/io'
import deposited from '../../../assets/icons/deposited.svg'
import withdrawn from '../../../assets/icons/withdrawn.svg'

// components
import Search from '../../../components/Search/Search'
import NavLink from '../../../components/Header/NavLink'
import CoinTag from '../../../components/CoinTag/CoinTag'

import { _getCurrencies } from '../../exchange/redux/actions'

function HistoryTab() {

    const [testCurrencies, setTestCurrencies] = useState([])

    useEffect(() => {
        _getCurrencies(
            (data) => {
                setTestCurrencies(data)
            }
        )
    }, [])

  return (
    <div className={Styles.tabContainer}>
        
        {/* tab header */}
        <div className={Styles.tabHeader}>
            <h4>Wallet History</h4>
            <Search
                placeholder='Search by date'
            />
        </div>

        {/* filter row */}
        <div className={Styles.filterRow}>
            <button>
                <CalendarIcon width={14}/>
                Date
                <DownArrowIcon width={9}/>
            </button>

            <button>
                <FilterIcon width={14}/>
                Filter
                <DownArrowIcon width={9}/>
            </button>
        </div>

        {/* sorting row */}
        <div className={Styles.sortingRow}>
            <span>Coin <IoMdArrowDropup /></span>
            <span>Action <IoMdArrowDropup /></span>
            <span>IP Address <IoMdArrowDropup /></span>
            <span>Amount <IoMdArrowDropup /></span>
            <span>Qty.Executed <IoMdArrowDropup /></span>
            <span>Date/Time <IoMdArrowDropup /></span>
            <span>Status <IoMdArrowDropup /></span>
        </div>

            {/* table content */}
            <div className={Styles.tableContent}>
                {
                    testCurrencies.slice(0, 7).map(coin => (
                        <div key={coin.id}>
                            <div>
                                <img src={coin.icon_url}/>
                                <span>{coin.name}</span>
                                <CoinTag tag={coin.id} size={12}/>
                            </div>
                            <div>
                                <img src={deposited}/>
                                Deposited
                            </div>
                            <div>192.168.1.100</div>
                            <div>$10,000</div>
                            <div>0.001242 BTC</div>
                            <div>10/02/ 09:10 PM</div>
                            <div>
                                <CoinTag tag={'Successful'} size={12} style={{color: 'var(--positive-color)', textAlign: 'center'}}/>
                            </div>
                        </div>
                    ))
                }
            </div>

            {/* table footer */}
            <div className={Styles.tableFooter}>
                <span>52 assets</span>

                {/* aginations button here */}
            </div>

    </div>
  )
}

export default HistoryTab