import { nationalitiesNames } from "./nationalities"
export const en = {
    //Footer
    "page.footer.exchange": "Exchange",
    "page.footer.convert": "Convert",
    "page.footer.launchpad": "Launchpad",
    "page.footer.wallet": "Wallet",
    "page.footer.support": "Support",
    "page.footer.requestForm": "Request Form",
    "page.footer.contactSupport": "Contact Support",
    "page.footer.resources": "Resources",
    "page.footer.downloads": "Downloads",
    "page.footer.mobileApplication": "Mobile Application",
    "page.footer.buyCrypto": "Buy Crypto",
    "page.footer.referral": "Referral",
    "page.footer.listingTranding": "Listing Tranding",
    "page.footer.learn": "Learn",
    "page.footer.whatsTrending": "What's Trending",
    "page.footer.productNews": "Product News",
    "page.footer.events": "Events",
    "page.footer.university": "University",
    "page.footer.research": "Research",
    "page.footer.marketUpdate": "Market Update",
    "page.footer.company": "Company",
    "page.footer.aboutUs": "About Us",
    "page.footer.careers": "Careers",
    "page.footer.news": "News",
    "page.footer.security": "Security",
    "page.footer.community": "Community",
    "page.footer.announcements": "Announcements",
    "page.footer.allRightsReserved": "All Rights Reserved",
    "page.footer.privacy": "Privacy",
    "page.footer.terms": "Terms",
    "page.footer.siteMap": "Sitemap",
    "page.footer.iosDownload": "Download on the",
    "page.footer.androidDownload": "Get it on",

    // Added :
    "page.header.navbar.blogs": "Blogs",
    "page.header.navbar.launchpad": "Launchpad",
    "page.header.navbar.exchange": "Exchange",
    "page.header.navbar.markets": "Markets",
    // 14 FEB
    "page.header.signInToWenbit": "Sign In To Wenbit",

    "page.markets.trade.marketCoins": "Market Coins",

    "page.markets.trade.buy": "Buy",
    "page.markets.trade.sell": "Sell",
    "page.markets.trade.limit": "Limit",
    "page.markets.trade.market": "Market",
    "page.markets.trade.quantity": "Quantity",
    "page.markets.trade.buyFor": "Buy For",
    "page.markets.trade.sellFor": "Sell For",
    "page.markets.trade.total": "Total",
    "page.markets.trade.fee": "Fee",
    "page.markets.trade.quickBuy": "Quick Buy",
    "page.markets.trade.quickSell": "Quick Sell",

    "page.markets.rows.name": "Coin Name",
    "page.markets.rows.price": "Coin Price",
    "page.markets.rows.24": "24%",
    "page.markets.rows.24High": "24h Hight",
    "page.markets.rows.24Low": "24h Low",
    "page.markets.rows.chart": "Chart",


    "page.header.navbar.signIn": "Sign In",
    "page.header.navbar.signUp": "Register",
    "page.header.navbar.trade": "Trade",
    "page.header.navbar.mining": "Mining",
    "page.header.navbar.wallets": "Wallets",
    "page.header.navbar.convert": "Convert",
    "page.header.navbar.openOrders": "Orders",
    "page.header.navbar.history": "History",
    "page.header.navbar.profile": "Profile",
    "page.header.navbar.logout": "Logout",
    "page.body.copy.success": "Text copied",
    "page.body.trade.header.markets": "Markets",
    "page.body.trade.header.markets.content.market": "Market",
    "page.body.trade.header.markets.content.pair": "Pair",
    "page.body.trade.header.markets.content.coin": "Coin",
    "page.body.trade.header.markets.content.price": "Price",
    "page.body.trade.header.markets.content.last_price": "Last Price",
    "page.body.trade.header.markets.content.change": "Change",
    "page.body.trade.header.markets.content.search": "Search",
    "page.body.trade.header.markets.content.volume": "Volume",
    "page.body.trade.header.newOrder": "Order Form",
    "page.body.trade.header.newOrder.content.tabs.buy": "BUY",
    "page.body.trade.header.newOrder.content.tabs.sell": "SELL",
    "page.body.trade.header.newOrder.content.orderType": "Order",
    "page.body.trade.header.newOrder.content.orderType.limit": "Limit",
    "page.body.trade.header.newOrder.content.orderType.market": "Market",
    "page.body.trade.header.newOrder.content.price": "Price",
    "page.body.trade.header.newOrder.content.amount": "Amount",
    "page.body.trade.header.newOrder.content.total": "Total",
    "page.body.trade.header.newOrder.content.available": "Available",
    "page.body.trade.header.newOrder.content.estimatedFee": "Fee",
    "page.body.trade.header.marketDepths": "Market Depth",
    "page.body.trade.header.marketDepths.content.price": "Price:",
    "page.body.trade.header.marketDepths.content.volume": "Amount:",
    "page.body.trade.header.marketDepths.content.cumulativeVolume": "Total:",
    "page.body.trade.header.marketDepths.content.cumulativeValue":
        "Estimated Value:",
    "page.body.trade.header.asks": "Asks",
    "page.body.trade.header.bids": "Bids",
    "page.body.trade.orderbook": "Order Book",
    "page.body.trade.orderbook.header.price": "Price",
    "page.body.trade.orderbook.header.amount": "Amount",
    "page.body.trade.orderbook.header.volume": "Total",
    "page.body.trade.header.recentTrades": "Recent Trades",
    "page.body.trade.header.recentTrades.content.time": "Time",
    "page.body.trade.header.recentTrades.content.price": "Price",
    "page.body.trade.header.recentTrades.content.amount": "Amount",
    "page.body.trade.header.openOrders": "Open Orders",
    "page.body.trade.header.openOrders.content.date": "Date",
    "page.body.trade.header.openOrders.content.state": "State",
    "page.body.trade.header.openOrders.content.price": "Price",
    "page.body.trade.header.openOrders.content.amount": "Amount",
    "page.body.trade.header.openOrders.content.total": "Total",
    "page.body.trade.header.openOrders.content.filled": "Filled",
    "page.body.wallets.estimated_value": "Estimated Value",
    "page.body.wallets.locked": "Locked",
    "page.body.wallets.balance": "Balance",
    "page.body.wallets.tabs.deposit": "Deposit",
    "page.body.wallets.tabs.deposit.ccy.message.submit":
        "Submit a deposit using the following address or QR code. Your deposit will be reflected in your account after 6 confirmations",
    "page.body.wallets.tabs.deposit.ccy.message.address": "Deposit Address",
    "page.body.wallets.tabs.deposit.ccy.message.button": "COPY",
    "page.body.wallets.tabs.deposit.ccy.message.success": "Address copied",
    "page.body.wallets.tabs.deposit.ccy.message.error":
        "Generating deposit address, Please reopen if taking a while",
    "page.body.wallets.tabs.deposit.fiat.message1":
        "Deposit using bank transfer",
    "page.body.wallets.tabs.deposit.fiat.message2":
        "Please use the following credentials to initiate your bank transfer. Your deposit will be reflected in your account within 2 business days.",


    'page.body.wallets.tabs.deposit.info.minDepositAmount': 'Minimum Deposit Amount',
    'page.body.wallets.tabs.deposit.info.depositFee': 'Deposit Fee',
    'page.body.wallets.tabs.deposit.info.depositEnabled': 'Deposit Enabled',
    'page.body.status.disabled' : 'Disabled',
    'page.body.status.enabled' : 'Enabled',

    "page.body.wallets.tabs.deposit.fiat.bankName": "Bank Name",
    "page.body.wallets.tabs.deposit.fiat.accountNumber": "Account number",
    "page.body.wallets.tabs.deposit.fiat.accountName": "Account name",
    "page.body.wallets.tabs.deposit.fiat.branchNumber": "Branch Number",
    "page.body.wallets.tabs.deposit.fiat.branchAddress": "Branch Address",
    "page.body.wallets.tabs.deposit.fiat.swiftCode": "Swift Code",
    "page.body.wallets.tabs.deposit.fiat.phoneNumber": "Phone Number",
    "page.body.wallets.tabs.deposit.fiat.referenceCode": "Your reference code",
    "page.body.wallets.tabs.deposit.fiat.etrans_message1":
        "Deposit using email transfer",
    "page.body.wallets.tabs.deposit.fiat.etrans_message2":
        "Please use the following email to initiate your email transfer. Your deposit will be reflected in your account within 2 business days.",
    "page.body.wallets.tabs.deposit.fiat.etrans_email": "email",
    "page.body.wallets.table.pending": "Pending",
    "page.body.wallets.tabs.deposit.fiat.admin":
        " To initiate a fiat withdrawal, please contact customer service.",
    "page.body.wallets.tabs.deposit.fiat.email": " email",
    "page.body.wallets.tabs.withdraw": "Withdraw",
    "page.body.wallets.tabs.withdraw.content.address": "Withdrawal Address",
    "page.body.wallets.tabs.withdraw.content.amount": "Withdrawal Amount",
    "page.body.wallets.tabs.withdraw.content.balance": "Available Balance",
    "page.body.wallets.tabs.withdraw.content.code2fa": "2FA code",
    "page.body.wallets.tabs.withdraw.content.fee": "Fee",
    "page.body.wallets.tabs.withdraw.content.total": "Total Withdrawal Amount",
    "page.body.wallets.tabs.withdraw.content.button": "WITHDRAW",
    "page.body.wallets.tabs.withdraw.content.addressPlaceholder":
        "Withdrawal Address",
    "page.body.wallets.tabs.withdraw.modal.confirmation": "Confirmation",
    "page.body.wallets.tabs.withdraw.modal.message1": "You will receive ",
    "page.body.wallets.tabs.withdraw.modal.button.cancel": "Cancel",
    "page.body.wallets.tabs.withdraw.modal.button.withdraw": "Withdraw",
    "page.body.wallets.tabs.withdraw.content.enable2fa":
        "To withdraw you have to enable 2FA",
    "page.body.wallets.tabs.withdraw.content.enable2faButton": "Enable 2FA",
    "page.body.openOrders.tab.all": "Order",
    "page.body.openOrders.tab.open": "Open",
    "page.body.openOrders.header.orderType": "Order",
    "page.body.openOrders.header.orderType.buy.market": "Buy market",
    "page.body.openOrders.header.orderType.buy.limit": "Buy limit",
    "page.body.openOrders.header.orderType.sell.market": "Sell market",
    "page.body.openOrders.header.orderType.sell.limit": "Sell limit",
    "page.body.openOrders.header.pair": "Pair",
    "page.body.openOrders.header.amount": "Amount",
    "page.body.openOrders.header.price": "Price",
    "page.body.openOrders.header.executed": "Executed",
    "page.body.openOrders.header.remaining": "Remaining",
    "page.body.openOrders.header.costRemaining": "Cost Remaining",
    "page.body.openOrders.header.status": "Status",
    "page.body.openOrders.content.status.done": "Executed",
    "page.body.openOrders.content.status.approve": "Pending",
    "page.body.openOrders.content.status.reject": "Rejected",
    "page.body.openOrders.content.status.wait": "Open",
    "page.body.openOrders.content.status.cancel": "Stopped",
    "page.body.openOrders.header.button.cancelAll": "Stop all",
    "page.body.history.deposit": "Deposit",
    "page.body.history.deposit.header.txid": "txID",
    "page.body.history.deposit.header.date": "Date",
    "page.body.history.deposit.header.currency": "Currency",
    "page.body.history.deposit.header.amount": "Amount",
    "page.body.history.deposit.header.status": "Status",
    "page.body.history.deposit.content.status.accepted": "Accepted",
    "page.body.history.deposit.content.status.collected": "Collected",
    "page.body.history.deposit.content.status.submitted": "Submitted",
    "page.body.history.deposit.content.status.canceled": "Canceled",
    "page.body.history.deposit.content.status.rejected": "Rejected",
    "page.body.history.deposit.content.status.skipped": "Skipped",
    "page.body.history.withdraw": "Withdrawal",
    "page.body.history.withdraw.header.id": "ID",
    "page.body.history.withdraw.header.date": "Date",
    "page.body.history.withdraw.header.currency": "Currency",
    "page.body.history.withdraw.header.address": "Address",
    "page.body.history.withdraw.header.amount": "Amount",
    "page.body.history.withdraw.header.fee": "Fee",
    "page.body.history.withdraw.header.status": "Status",
    "page.body.history.withdraw.content.status.prepared": "Prepared",
    "page.body.history.withdraw.content.status.submitted": "Submitted",
    "page.body.history.withdraw.content.status.canceled": "Canceled",
    "page.body.history.withdraw.content.status.accepted": "Accepted",
    "page.body.history.withdraw.content.status.suspected": "Suspected",
    "page.body.history.withdraw.content.status.rejected": "Rejected",
    "page.body.history.withdraw.content.status.processing": "Processing",
    "page.body.history.withdraw.content.status.succeed": "Succeed",
    "page.body.history.withdraw.content.status.failed": "Failed",
    "page.body.history.withdraw.content.status.confirming": "Confirming",
    "page.body.history.withdraw.content.status.errored": "Error",
    "page.body.history.trade": "Trade",
    "page.body.history.trade.header.id": "ID",
    "page.body.history.trade.header.date": "Date",
    "page.body.history.trade.header.side": "Side",
    "page.body.history.trade.content.side.buy": "Buy",
    "page.body.history.trade.content.side.sell": "Sell",
    "page.body.history.trade.header.market": "Market",
    "page.body.history.trade.header.price": "Price",
    "page.body.history.trade.header.funds": "Funds",
    "page.body.history.trade.header.amount": "Amount",
    "page.body.history.trade.header.balance": "Balance",
    "page.body.history.conversion": "Conversion",
    "page.body.history.conversion.header.id": "ID",
    "page.body.history.conversion.header.date": "Date",
    "page.body.history.conversion.header.side": "Side",
    "page.body.history.conversion.content.side.buy": "Buy",
    "page.body.history.conversion.content.side.sell": "Sell",
    "page.body.history.conversion.header.market": "Market",
    "page.body.history.conversion.header.price": "Price",
    "page.body.history.conversion.header.funds": "Funds",
    "page.body.history.conversion.header.amount": "Amount",
    "page.body.history.conversion.header.balance": "Balance",
    "page.body.profile.kyc.content":
        "To comply with regulation, participant will have to go through indentity verification.",
    "page.body.profile.kyc.content.notice":
        "* KYC verification required for withdraw",
    "page.body.profile.kyc.button.success": "KYC Verified",
    "page.body.profile.kyc.button.pending": "KYC Pending",
    "page.body.profile.kyc.button.registration_pending": "Registration Details Pending",
    "page.body.profile.kyc.button.fail": "KYC Start",
    "page.body.profile.header.account": "Profile",
    "page.body.profile.header.account.content.password": "Password",
    "page.body.profile.header.account.content.password.button.change": "Change",
    "page.body.profile.header.account.content.password.old": "Old password",
    "page.body.profile.header.account.content.password.new": "New password",
    "page.body.profile.header.account.content.password.button.save": "Save",
    "page.body.profile.header.account.content.password.button.cancel": "Cancel",
    "page.body.profile.header.account.content.password.conf":
        "Password Confirmation",
    "page.body.profile.header.account.content.password.dont.match":
        "Passwords don't match",
    "page.body.profile.header.account.content.password.change.success":
        "Success!",
    "page.body.profile.header.account.content.password.change":
        "Change password",
    "page.body.profile.header.account.content.twoFactorAuthentication": "2FA",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.enable":
        "Enabled",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.disable":
        "Disabled",
    "page.body.profile.header.account.content.twoFactorAuthentication.header":
        "Google Authenticator",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.1":
        "Download and install Google Authenticator application from ",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.or":
        "or ",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.2":
        "Scan QR code or use secret code:",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.3":
        "* Save this secret in a secure location. This code can used to gain 2FA access from a different device.",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.mfa":
        "Secret code",
    "page.body.profile.header.account.content.twoFactorAuthentication.message.4":
        "Enter 2fa code from the app",
    "page.body.profile.header.account.content.twoFactorAuthentication.subHeader":
        "2FA code",
    "page.body.profile.header.account.content.twoFactorAuthentication.enable":
        "ENABLE",
    "page.body.profile.header.account.content.twoFactorAuthentication.disable":
        "DISABLE 2FA",
    "page.body.profile.header.account.content.twoFactorAuthentication.modalBody":
        "Please сontact administrator to disable",
    "page.body.profile.header.account.content.twoFactorAuthentication.modalHeader":
        "Two-factor authentication is enabled",
    "page.body.profile.header.account.profile": "Profile Verification",
    "page.body.profile.header.account.profile.email.title": "Email verified",
    "page.body.profile.header.account.profile.email.message":
        "Deposit/Withdraw enabled",
    "page.body.profile.header.account.profile.phone.unverified.title":
        "Phone verification",
    "page.body.profile.header.account.profile.phone.title": "Phone verified",
    "page.body.profile.header.account.profile.phone.message":
        "Deposit/Trade enabled",
    "page.body.profile.header.account.profile.identity.unverified.title":
        "Identity verification",
    "page.body.profile.header.account.profile.identity.title":
        "Identity verified",
    "page.body.profile.header.account.profile.identity.message":
        "Withdraw enabled",
    "page.body.profile.header.referralProgram": "Referral Link",
    "page.body.profile.content.copyLink": "Copy",
    "page.body.profile.apiKeys.header": "My API Keys",
    "page.body.profile.apiKeys.header.create": "Create new",
    "page.body.profile.apiKeys.noOtp":
        "Please enable Two-factor authentication",
    "page.body.profile.apiKeys.show": "Show",
    "page.body.profile.apiKeys.noKeys": "You have no API keys",
    "page.body.profile.apiKeys.modal.btn.show": "Show",
    "page.body.profile.apiKeys.modal.btn.create": "Confirm",
    "page.body.profile.apiKeys.modal.btn.copy": "Copy",
    "page.body.profile.apiKeys.modal.btn.activate": "Activate",
    "page.body.profile.apiKeys.modal.btn.disabled": "Disable",
    "page.body.profile.apiKeys.modal.btn.delete": "Delete",
    "page.body.profile.apiKeys.modal.header": "2FA Verification",
    "page.body.profile.apiKeys.modal.created_header": "Created",
    "page.body.profile.apiKeys.modal.access_key": "Access Key",
    "page.body.profile.apiKeys.modal.secret_key": "Secret Key",
    "page.body.profile.apiKeys.modal.secret_key_info":
        "This information will be shown only once and cannot be retrieved once lost.",
    "page.body.profile.apiKeys.modal.secret_key_store":
        "Please store it properly.",
    "page.body.profile.apiKeys.modal.note": "Note",
    "page.body.profile.apiKeys.modal.note_content": `To avoid asset loss, please do not tell your Secret Key and Private Key to others.\
 If you forget you Secret Key, please delete it and apply for a new Secret Key pair.`,
    "page.body.profile.apiKeys.modal.title": "Enter 2fa code from the app",
    "page.body.profile.apiKeys.modal.label":
        "6-digit Google Authenticator code",
    "page.body.profile.apiKeys.modal.placeholder": "Enter Code",
    "page.body.profile.apiKeys.table.header.kid": "Kid",
    "page.body.profile.apiKeys.table.header.algorithm": "Algorithm",
    "page.body.profile.apiKeys.table.header.state": "State",
    "page.body.profile.apiKeys.table.header.created": "Created",
    "page.body.profile.apiKeys.table.header.updated": "Updated",
    "success.api_keys.fetched": "Successfully fetched API keys",
    "success.api_keys.created": "API key was created",
    "success.api_keys.copied.access": "Access key was copied",
    "success.api_keys.copied.secret": "Secret key was copied",
    "success.api_keys.updated": "API key was updated",
    "success.api_keys.deleted": "API key was deleted",
    "page.body.profile.header.accountActivity": "Account Activity",
    "page.body.profile.header.accountActivity.content.date": "Date",
    "page.body.profile.header.accountActivity.content.addressip": "Address",
    "page.body.profile.header.accountActivity.content.action": "Action",
    "page.body.profile.header.accountActivity.content.result": "Result",
    "page.body.profile.header.accountActivity.content.userAgent": "User Agent",
    "page.body.profile.content.action.login": "Login",
    "page.body.profile.content.action.logout": "Logout",
    "page.body.profile.content.action.request2fa": "Request QR code for 2FA",
    "page.body.profile.content.action.enable2fa": "Enable 2FA",
    "page.body.profile.content.action.login.2fa": "Login with 2FA",
    "page.body.profile.content.action.requestPasswordReset":
        "Request password reset",
    "page.body.profile.content.action.passwordReset": "Password reset",
    "page.body.profile.content.result.succeed": "Succeed",
    "page.body.profile.content.result.failed": "Failed",
    "page.body.kyc.phone.head": "Verify Phone",
    "page.body.kyc.phone.enterPhone": "Enter phone number",
    "page.body.kyc.phone.phoneNumber": "Phone Number",
    "page.body.kyc.phone.enterCode": "Enter confirmation code",
    "page.body.kyc.phone.code": "SMS Code",
    "page.body.kyc.phone.send": "SEND CODE",
    "page.body.kyc.phone.resend": "RESEND CODE",
    "page.body.kyc.identity.head": "Identity Verification",
    "page.body.kyc.document.head": "Document Verification",

    "page.body.kyc.registration.nationality": "Nationality",
    "page.body.kyc.registration.employment": "Employment Status",
    "page.body.kyc.registration.industry": "Industry you work in",
    "page.body.kyc.registration.address": "Residential Address",
    "page.body.kyc.registration.apartment": "Apartment number",
    "page.body.kyc.registration.block": "Block Name / Number",
    "page.body.kyc.registration.street": "Street name",
    "page.body.kyc.registration.city": "City / Town",
    "page.body.kyc.registration.country": "Country",
    "page.body.kyc.registration.postal_code": "Postal code",
    "page.body.kyc.registration.purpose": "Purpose of opening account",
    "page.body.kyc.registration.volume": "Expected monthly account volume",
    "page.body.kyc.registration.source": "What are your source of funds?",

    "page.body.kyc.identity.firstName": "First Name",
    "page.body.kyc.identity.lastName": "Last Name",
    "page.body.kyc.identity.dateOfBirth": "Date of Birth DD/MM/YYYY",
    "page.body.kyc.identity.residentialAddress": "Residential Address",
    "page.body.kyc.identity.city": "City",
    "page.body.kyc.identity.postcode": "Postcode",
    "page.body.kyc.identity.nationality": "Citizenship",
    "page.body.kyc.identity.CoR": "Country of Residence",
    "page.body.kyc.documents.expiryDate": "Expiration Date DD/MM/YYYY",
    "page.body.kyc.documents.drag": "Drag and drop or browse files",
    "page.body.kyc.documents.maxFile": "Maximum file size is 10MB",
    "page.body.kyc.documents.maxNum": "Maximum number of files is 5",
    "page.body.kyc.documents.files": "Allowed file types: jpg, jpeg, png",
    "page.body.kyc.documents.upload": "Upload your Photo ID",
    "page.body.kyc.documents.select.passport": "Passport",
    "page.body.kyc.documents.select.identityCard": "Identity Card",
    "page.body.kyc.documents.select.driverLicense": "Driver License",
    "page.body.kyc.documents.select.utilityBill": "Utility Bill",
    "page.body.kyc.documents.number": ": Number",
    "page.body.kyc.documentsType": "Document type",
    "page.body.kyc.next": "Next",
    "page.body.kyc.submit": "Submit",
    "page.body.kyc.head.phone": "Phone Verification",
    "page.body.kyc.head.identity": "Identity Verification",
    "page.body.kyc.head.document": "Document Verification",
    "page.body.kyc.error": "please verify your KYC",
    "page.footer.legalDocuments": "Legal documents",
    "page.footer.faq": "FAQ",
    "page.header.signIn": "Sign In",
    "page.header.signIn.email": "Email",
    "page.header.signIn.password": "Password",
    "page.header.signIn.password.message.error": "Password is invalid",
    "page.header.signIn.receiveConfirmation":
        "Didn't receive your confirmation email?",
    "page.header.signIn.forgotPassword": "Forgot your password?",
    "page.header.signIn.resetPassword.title": "Reset password",
    "page.header.signIn.resetPassword.form": "Reset password",
    "page.header.signIn.resetPassword.button": "Reset",
    "page.header.signIn.resetPassword.oldPassword": "Old password",
    "page.header.signIn.resetPassword.newPassword": "New password",
    "page.header.signIn.resetPassword.repeatPassword": "Repeat password",
    "page.header.signIn.resetPassword.change.button": "Change",
    "page.header.signIn.resetPassword.error":
        "Fields are empty or don`t matches",
    "page.header.signUp": "Create Account",
    "page.header.signUp.email": "Email *",
    "page.header.signUp.email.message.error": "Email is invalid",
    "page.header.signUp.usernameLength.message.error":
        "Username must be minimum 4 and maximum 20 characters",
    "page.header.signUp.password": "Password *",
    "page.header.signUp.password.message.error":
        "Password must contain at least 8 characters, at least one capital letter and one digit",
    "page.header.signUp.confirmPassword": "Confirm Password *",
    "page.header.signUp.confirmPassword.message.error": "Passwords don't match",
    "page.header.signUp.referalCode": "Referral Code",
    "page.header.signUp.terms": "I have read and agree to the",
    "page.header.signUp.modal.header": "VERIFY YOUR EMAIL ADDRESS",
    "page.header.signUp.modal.body":
        "To complete registration, check for an " +
        "email in your inbox with further " +
        "instruction. If you cannot find the email, " +
        "please check your spam folder",
    "page.resendConfirmation": "Resend Confirmation",
    "page.forgotPassword": "Forgot Password",
    "page.forgotPassword.message": "Enter email to reset password",
    "page.password2fa": "2FA verification",
    "page.password2fa.message": "Enter 2fa code from the app",
    "page.forgotPassword.email": "Email",
    "page.forgotPassword.send": "Send",
    "page.noDataToShow": "There is no data to show",
    "page.modal.withdraw.success": "Success!",
    "page.modal.withdraw.success.message.content":
        "Your withdrawal request has been received",
    "page.modal.withdraw.success.button": "OK",
    // success messages
    "success.logout": "Auto logout success!",
    "success.documents.accepted": "Documents upload was successful",
    "success.documents.fetched": "Documents fetch successful",
    "success.withdraw.action": "Withdrawal request was received",
    "success.otp.enabled": "2FA was enabled",
    "success.password.changed": "Password was changed",
    "success.payable_currency.updated": "Currency was updated",
    "error.currency_already_assigned": "Currency already assigned",
    "success.password.forgot":
        "Password reset link has been sent to your email",
    "success.password.changed.successfuly": "Password was changed",
    "success.order.canceled": "Order will be cancelled shortly",
    "success.order.canceled.all": "All orders will be cancelled shortly",
    "success.order.created": "Order will be created shortly",
    "success.phone.verification.send":
        "Verification code was sent to your phone",
    "success.phone.confirmed": "Your phone was confirmed",
    "success.message.sent": "Message was sent",
    "success.email.confirmed":
        "Your email address has been successfully confirmed",
    // error messages
    // barong
    "resource.labels.private": "Can't update label",
    "resource.user.no_activity": "No activity recorded or wrong topic",
    "resource.profile.not_exist": "User has no profile",
    "resource.profile.exist": "Profile already exists",
    "resource.api_key.2fa_disabled": "Only accounts with enabled 2FA alowed",
    "resource.api_key.missing_otp":
        "Theaccount has enabled 2FA but OTP code is missing",
    "resource.api_key.invalid_otp": "OTP code is invalid",
    "resource.phone.twillio": "Something wrong with Twilio Client",
    "resource.phone.invalid_num": "Phone number is invalid",
    "resource.phone.exists": "Phone number already exists",
    "resource.phone.number_exist": "Phone number is already used by someone else",
    "resource.registration_details.exists": "Registration Details are already submitted",
    "resource.phone.verification_invalid":
        "Phone is not found or verification code is invalid",
    "resource.documents.limit_reached":
        "Maximum number of documents already reached",
    "resource.documents.limit_will_be_reached":
        "Documents amount will reach limit by this upload",
    "resource.otp.already_enabled":
        "2FA has been already enabled for this account",
    "resource.otp.invalid": "OTP code is invalid",
    "resource.password.doesnt_match": "New passwords don't match",
    "resource.password.prev_pass_not_correct":
        "Previous password is not correct",
    "resource.password.no_change_provided":
        "New password cant be the same, as old one",
    "resource.document.empty_doc_expire": "Expiration Date is invalid",
    "password.requirements": "Password does not meet the minimum requirements",
    "password.password.password_strength":
        "Password is too weak, please increase the password length or complexity",
    "email.taken": "Email already in use",
    "identity.user.invalid_referral_format": "Invalid referral uid format",
    "identity.user.referral_doesnt_exist": "Referral doesn't exist",
    "identity.user.active_or_doesnt_exist":
        "User doesn't exist or has already been activated",
    "identity.password.user_doesnt_exist": "User doesn't exist",
    "identity.user.passwords_doesnt_match": "Passwords don't match",
    "identity.user.utilized_token": "JWT has already been used",
    "identity.session.invalid_login_params": "Invalid Email or Password",
    "identity.session.invalid": "Invalid Session",
    "identity.captcha.required": "captcha_response is required",
    "identity.captcha.mandatory_fields": "Mandatory fields must be filled in",
    "identity.session.not_active": "Your account is not active",
    "identity.session.banned": "Your account is banned",
    "identity.session.invalid_params": "Invalid Email or Password",
    "identity.session.missing_otp":
        "The account has enabled 2FA but OTP code is missing",
    "identity.session.invalid_otp": "OTP code is invalid",
    "first_name.invalid": "First name is invalid",
    "last_name.invalid": "Last name is invalid",
    "city.invalid": "City is invalid",
    "postcode.invalid": "Postcode is invalid",
    "address.invalid": "Address is invalid",
    "first_name.blank": "First name is missing or empty",
    "last_name.blank": "Last name is missing or empty",
    "dob.blank": "Date of birth is invalid",
    "address.blank": "Address is missing or empty",
    "city.blank": "City is missing or empty",
    "country.blank": "Country is missing or empty",
    "postcode.blank": "Postcode.blank is missing or empty",
    "country.must have alpha2 or alpha3 format":
        "Country must have alpha2 or alpha3 format",
    "totp.error": "OTP code is invalid",
    "record.not_found": "Record is not found",
    "jwt.decode_and_verify": "Failed to decode and verify JWT",
    "authz.invalid_session": "Failed to decode cookies",
    "authz.user_not_active": "User is not active",
    "authz.invalid_signature": "API Key header 'signature' is invalid",
    "authz.apikey_not_active": "API Key state is 'inactive",
    "authz.disabled_2fa": "API Key owner has disabled 2FA",
    "authz.invalid_api_key_headers": "Blank or missing API Key headers",
    "authz.permission_denied": "Path is blacklisted",
    "authz.unexistent_apikey": "X-Auth-Apikey header is invalid",
    // validation errors
    // identity module
    "identity.user.missing_email": "Email is missing",
    "identity.user.empty_email": "Email is missing or empty",
    "identity.user.missing_password": "Password is missing",
    "identity.user.empty_password": "Password is missing or empty",
    "identity.user.missing_token": "Token is missing",
    "identity.user.empty_token": "Token is missing or empty",
    "identity.user.missing_reset_password_token":
        "Reset password token is missing",
    "identity.user.empty_reset_password_token":
        "Reset password token is missing or empty",
    "identity.user.missing_confirm_password": "Confirm password is missing",
    "identity.user.empty_confirm_password":
        "Confirm password is missing or empty",
    "identity.session.missing_emai": "Email is missing",
    "identity.session.missing_password": "Password is missing",
    "identity.session.invalid_captcha_format": "Invalid captcha format",
    // resource module
    "resource.otp.missing_code": "OTP code is missing",
    "resource.otp.empty_code": "OTP code is missing or empty",
    "resource.labels.missing_key": "Key is missing",
    "resource.labels.empty_key": "Key is missing or empty",
    "resource.labels.missing_value": "Value is missing",
    "resource.labels.empty_value": "Value is missing or empty",
    "resource.documents.missing_doc_expire":
        "Documents expiration date is missing",
    "resource.documents.empty_doc_expire":
        "Documents expiration date is missing or empty",
    "resource.documents.missing_doc_type": "Documents type is missing",
    "resource.documents.empty_doc_type": "Documents type is missing or empty",
    "resource.documents.missing_doc_number": "Documents number is missing",
    "resource.documents.empty_doc_number":
        "Documents number is missing or empty",
    "resource.documents.missing_upload": "Attachment is missing",
    "resource.user.missing_topic": "Topic is missing",
    "resource.user.empty_topic": "Topic is missing or empty",
    "resource.user.missing_old_password": "Old password is missing",
    "resource.user.empty_old_password": "Old password is missing or empty",
    "resource.user.missing_new_password": "New password is missing",
    "resource.user.empty_new_password": "New password is missing or empty",
    "resource.user.missing_confirm_password": "Confirm password is missing",
    "resource.user.empty_confirm_password":
        "Confirm password is missing or empty",
    "resource.profile.missing_first_name": "First name is missing",
    "resource.profile.missing_last_name": "Last name is missing",
    "resource.profile.missing_dob": "Date of birth is missing",
    "resource.profile.missing_address": "Address is missing",
    "resource.profile.missing_postcode": "Postcode is missing",
    "resource.profile.missing_city": "City is missing",
    "resource.profile.missing_country": "Country is missing",
    "resource.api_key.missing_algorithm": "Algorithm is missing",
    "resource.api_key.empty_algorithm": "Algorithm is missing or empty",
    "resource.api_key.empty_kid": "KID is missing or empty",
    "resource.api_key.empty_scope": "Scope is missing or empty",
    "resource.api_key.missing_totp": "TOTP code is missing",
    "resource.api_key.empty_totp": "TOTP code is missing or empty",
    "resource.api_key.missing_kid": "KID is missing",
    "resource.api_key.empty_state": "State is missing or empty",
    "resource.phone.missing_phone_number": "Phone number is missing",
    "resource.phone.empty_phone_number": "Phone number is missing or empty",
    "resource.phone.missing_verification_code": "Verification code is missing",
    "resource.phone.empty_verification_code":
        "Verification code is missing or empty",
    // peatio
    "account.currency.doesnt_exist": "Currency doesn't exist",
    "account.deposit.invalid_state": "Deposit invalid state",
    "account.deposit.non_integer_limit":
        "Value you send could not be parsed into Integer type",
    "account.deposit.invalid_limit": "Invalid limit",
    "account.deposit.non_positive_page": "Page value must be positive",
    "account.deposit.empty_txid": "Txid is missing, txid is empty",
    "account.deposit_address.invalid_address_format":
        "Invalid deposit address format",
    "account.deposit_address.doesnt_support_cash_address_format":
        "Currency doesn't support cash address format",
    "account.withdraw.non_integer_limit":
        "Limit Value you send could not be parsed into Integer type",
    "account.withdraw.invalid_limit": "Invalid limit",
    "account.withdraw.non_positive_page": "Page value must be positive",
    "account.withdraw.non_integer_otp":
        "Otp value could not be parsed into Integer type",
    "account.withdraw.empty_otp": "Otp is missing, otp is empty",
    "account.withdraw.empty_rid": "Rid is missing, rid is empty",
    "account.withdraw.non_decimal_amount":
        "Amount value you send could not be parsed into Decimal type",
    "account.withdraw.non_positive_amount": "Amount value must be positive",
    "account.deposit.not_permitted": "Deposits allows after phone verification",
    "account.withdraw.not_permitted":
        "Please, pass the corresponding verification steps to withdraw funds",
    "account.withdraw.insufficient_balance": "Account balance is insufficient",
    "account.withdraw.invalid_amount": "Invalid withdraw amount",
    "account.withdraw.create_error": "Failed to create withdraw",
    "account.withdraw.invalid_otp": "Invalid otp",
    "account.withdraw.disabled_api": "Withdrawal API is disabled",
    "market.market.doesnt_exist_or_not_enabled": "Market doesn't exist",
    "market.market.doesnt_exist": "Market doesn't exist",
    "market.conversion.insufficient_balance": "Insufficient Balance",
    "market.conversion.success": "Funds Converted Successfully",
    "market.conversion.expired": "Conversion Quote Expired",
    "market.conversion.confirm_error": "Something Went Wrong",
    "market.conversion.insufficient_market_liquidity": "Insufficient Market Liquidity",
    "market.order.invalid_state": "Invalid deposit state",
    "market.order.invalid_limit": "Invalid limit",
    "market.order.non_integer_limit":
        "Limit value you send could not be parsed into Integer type",
    "market.trade.empty_page": "Page is missing or empty",
    "market.order.invalid_order_by": "Invalid order_by",
    "market.order.invalid_side": "Invalid order side",
    "market.order.non_decimal_volume":
        "Volume value you send could not be parsed into Decimal type",
    "market.order.non_positive_volume": "Volume value must be positive",
    "market.order.invalid_type": "Invalid Order",
    "market.order.non_decimal_price":
        "Volume value you send could not be parsed into Decimal type",
    "market.order.non_positive_price": "Price value must be positive",
    "market.order.non_integer_id":
        "Id value  you send could not be parsed into Integer type",
    "market.order.empty_id": "Id is missing or empty",
    "market.trade.non_integer_limit":
        "Limit value you send could not be parsed into Integer type",
    "market.trade.invalid_limit": "Invalid limit",
    "market.trade.non_integer_timestamp":
        "Timestamp value you send could not be parsed into Integer type",
    "market.trade.empty_timestamp": "Timestamp is missing or empty",
    "market.trade.invalid_order_by": "Invalid order_by",
    "market.order.insufficient_market_liquidity":
        "Isufficient market liquidity",
    "market.order.invalid_volume_or_price": "Invalid volume or price",
    "market.order.create_error": "Failed to create error",
    "market.order.cancel_error": "Failed to cancel error",
    "market.order.market_order_price": "Market order doesn't have price",
    "market.trade.not_permitted":
        "Please, pass the corresponding verification steps to enable trading",
    "market.account.insufficient_balance": "Account balance is insufficient",
    "public.currency.doesnt_exist": "Currency doesn't exist",
    "public.currency.invalid_type": "Invalid currency type",
    "public.market.doesnt_exist": "Market doesn't exist",
    "public.order_book.non_integer_ask_limit":
        "Ask limit value you send could not be parsed into Integer type",
    "public.order_book.invalid_ask_limit": "Invlalid ask limit",
    "public.order_book.non_integer_bid_limit":
        "Bid limir value you send could not be parsed into Integer type",
    "public.order_book.invalid_bid_limit": "Invalid bid limit",
    "public.trade.non_integer_limit":
        "Limit value you send could not be parsed into Integer type",
    "public.trade.invalid_limit": "Invalid limit",
    "public.trade.non_positive_page": "Page value must be positive",
    "public.trade.non_integer_timestamp":
        "Timestamp value you send could not be parsed into Integer type",
    "public.trade.invalid_order_by": "Invalid order by",
    "public.market_depth.non_integer_limit":
        "Limit value you send could not be parsed into Integer type",
    "public.market_depth.invalid_limit": "Invalid limit",
    "public.k_line.non_integer_period":
        "Limit value you send could not be parsed into Integer type",
    "public.k_line.invalid_period": "Invalid period",
    "public.k_line.non_integer_time_from":
        "Limit value you send could not be parsed into Integer type",
    "public.k_line.empty_time_from": "Time_from param is missing or empty",
    "public.k_line.non_integer_time_to":
        "Limit value you send could not be parsed into Integer type",
    "public.k_line.empty_time_to": "Time_to param is missing or empty",
    "public.k_line.non_integer_limit":
        "Limit value you send could not be parsed into Integer type",
    "public.k_line.invalid_limit": "Invalid limit",
    "server.internal_error": "Internal Server Error",
    "public.trade.tradingChart-label": "Trading Chart",
    "public.trade.marketDepth-label": "Market Depth",
    "public.trade.Chart-label-parent": "Charts",

    //NEW TRANSLATIONS START HERE
    "page.header.navbar.header": "\n",
    "page.signIn.description": "Welcome to {exchange_name}", // Do not change exchange_name
    "page.signIn.2faRequired": "Please enter 2fa code",
    "page.signIn.signup": "Register Now",
    "page.signIn.signupExists": "Don't have an account? ",
    "page.forgotPassword.description":
        "If you forgot your password, well, then we’ll email you instructions to reset your password.",
    "page.forgotPassword.back": "Return to Login",
    "page.forgotPassword.signUp": "Register Now",
    "page.forgotPassword.signUpExists": "Don't have an account? ",
    "page.body.forget_password": "Forgot Password",
    "page.signUp.description": "Welcome to {exchange_name}", // Do not change exchange_name
    "page.signUp.signin": "Login Here",
    "page.signUp.signinExists": "Have an account? ",
    "page.profile": "Profile",
    "page.profile.profileDetailsTitle": "Profile Details",
    "page.profile.referralTitle": "Earn with Referral",
    "page.profile.payableCurrencyPlaceholder": "Select your payable currency",
    "page.profile.referralDescription":
        "Invite your friends & family and receive a bonus - 15% of the value of contribution.",
    "page.profile.2faTitle": "Two-Factor Authentication",
    "page.profile.2faDescription":
        "Two-factor authentication is a method for protection your web account. When it is activated you need to enter not only your password, but also a special code. You can receive this code by in mobile app. Even if third person will find your password, then can't access with that code.",
    "page.profile.currentStatus": "Current Status:",
    "page.profile.kycTitle": "Identity Verification - KYC",
    "page.emailAddress": "Email Address",
    "page.uid": "User ID",
    "page.show": "Show",
    "page.hide": "Hide",
    "page.payableCurrency": "Payable Currency",
    "page.body.wallet.currencyTitle": "Currency",
    "page.body.wallet.currencyName": "Name",
    "page.body.wallet.availableBalance": "Available",
    "page.body.wallet.lockedBalance": "Locked",
    "page.body.wallet.actionTitle": "Action",
    "page.body.wallet.action.deposit": "Deposit",
    "page.body.wallet.action.withdraw": "Withdraw",
    "page.body.wallet.action.convert": "Convert",
    "page.header.navbar.2fa": "Two factor authentication",
    "page.header.navbar.forgetPassword": "Two factor authentication",
    "page.header.navbar.emailVerification": "Email verification",
    "page.body.confirm.title": "Begin your ID-Verification",
    "page.body.confirm.subTitle": "Verify your identity.",
    "success.identity.submitted": "Profile Submitted.",
    "success.registration.submitted": "Registration Details Submitted.",
    "page.body.profile.content.action.signup": "Signup",

    "page.body.profile.beneficiaries.header": "My Beneficiaries",
    "page.body.profile.beneficiaries.header.create": "Create new",
    "page.body.profile.beneficiaries.noBeneficiaries":
        "You have no Beneficiaries",
    "page.body.profile.beneficiaries.modal.form.selectCurrency":
        "Select Currency",
    "page.body.profile.beneficiaries.modal.form.name":
        "Beneficiary Reference Name",
    "page.body.profile.beneficiaries.modal.form.description":
        "Beneficiary description",
    "page.body.profile.beneficiaries.modal.form.address": "Beneficiary Address",
    "page.body.profile.beneficiaries.modal.form.accountNumber":
        "Beneficiary Bank Account Number",
    "page.body.profile.beneficiaries.modal.form.swift":
        "Beneficiary Bank SWIFT Code",
    "page.body.profile.beneficiaries.modal.form.fullName":
        "Beneficiary Full Name",
    "page.body.profile.beneficiaries.modal.form.submitFailed":
        "Please check if all the required fields are filled.",
    "page.body.profile.beneficiaries.modal.form.alreadyActive":
        "Beneficiary is already active you may delete it.",
    "page.body.profile.beneficiaries.modal.form.activationCode":
        "Beneficiary Activation Code",
    "page.body.profile.beneficiaries.modal.form.deleteConfirm":
        "Do you really want to delete this beneficiary?",
    "page.body.profile.beneficiaries.modal.btn.show": "Show",
    "page.body.profile.beneficiaries.modal.btn.close": "Close",
    "page.body.profile.beneficiaries.modal.btn.create": "Confirm",
    "page.body.profile.beneficiaries.modal.btn.activate": "Activate",
    "page.body.profile.beneficiaries.modal.btn.disabled": "Disable",
    "page.body.profile.beneficiaries.modal.btn.delete": "Delete",
    "page.body.profile.beneficiaries.modal.header": "Manage Beneficiaries",
    "page.body.profile.beneficiaries.modal.created_header": "Created",
    "page.body.profile.beneficiaries.table.header.currency": "Currency",
    "page.body.profile.beneficiaries.table.header.description": "Description",
    "page.body.profile.beneficiaries.table.header.name": "Name",
    "page.body.profile.beneficiaries.table.header.address": "Crypto Address",
    "page.body.profile.beneficiaries.table.header.swift": "SWIFT",
    "page.body.profile.beneficiaries.table.header.accountNumber":
        "Account Number",
    "page.body.profile.beneficiaries.table.header.fullName": "Full Name",
    "page.body.profile.beneficiaries.table.header.state": "State",
    "success.beneficiaries.fetched": "Successfully fetched Beneficiaries",
    "success.beneficiaries.created": "Beneficiary was created",
    "success.beneficiaries.updated": "Beneficiary was updated",
    "success.beneficiaries.deleted": "Beneficiary was deleted",
    "account.beneficiary.non_integer_id": "Beneficiary ID not found",
    "account.beneficiary.invalid_pin": "Invalid Beneficiary Pin",
    "account.beneficiary.invalid_state_for_withdrawal": "Invalid Beneficiary",
    "page.body.wallets.tabs.withdraw.modal.message2": " on Beneficiary",
    "page.body.wallets.tabs.withdraw.form.toggleLabel": "Use Beneficiary",
    "page.body.wallets.tabs.withdraw.form.beneficiaryPlaceHolder":
        "Select beneficiary",
    "page.body.wallets.tabs.withdraw.form.addNewBeneficiary":
        "Add new beneficiary",
    "currency.currency_doesnt_exist_or_not_payable":
        "Currency doesn't exist or isn't payable",

    "resource.documents.already_expired":
        "Documents you trying to upload are already expired!",
    "resource.documents.invalid_format":
        "Documents must be valid and not Expired!",
    "success.beneficiaries.pinResend": "Pin Resent to your registered email.",
    "account.beneficiary.cant_resend_within_1_minute":
        "Pin already sent. Please wait 1 minute to resend the pin.",

    "page.header.navbar.otc": "OTC",

    "page.trading.toolbar.progressLabel.lowest24": "Low",
    "page.trading.toolbar.progressLabel.lastPrice": "Last Price",
    "page.trading.toolbar.progressLabel.highest24": "High",
    "page.trading.toolbar.progressLabel.volume24": "24h Volume",
    "page.trading.toolbar.progressLabel.change": "Change",

    "page.profile.section.profile.title": "My Profile",
    "page.profile.section.referral.title": "Referral",
    "page.profile.section.accountActivity.title": "Activity",
    "page.profile.section.api.title": "API",
    "page.profile.section.beneficiary.title": "Beneficiaries",

    "page.signUp.PEP.text": "PEP",
    "page.signUp.termsAndService.text": " Terms and Services",
    "page.signUp.PrivacyPolicy.text": "Privacy Policy",
    "page.signUp.risks.text": "Risks Related to Cryptocurrency",

    "page.header.signUp.username": "Username *",
    "page.sigin.welcomeText": "Let’s start with Login!",
    "page.signUp.button": "SignUp",
    "page.wallets.portfolio.text": "Total Portfolio Value",
    "page.wallets.label.hideEmptyWallets": "Hide empty wallets",

    "page.history.withdraw.details": "Withdraw Details",
    "page.history.deposit.details": "Deposit Details",
    "page.markets.search.text": "Search Coins",
    "page.productMenu.home": "Home",
    "page.productMenu.p2p": "P2P",
    "page.auth.welcome.message": "Let's start with Login!",
    "page.body.wallets.min_deposit_warning":
        "The minimum deposit is currently: {min_amount} {currency}",
    "page.body.wallets.mining_deposit_warning":
        "Please do not mine directly to your deposit address, as it can change unannounced. Lost deposits will not be credited.",
    "page.body.header.markets": "Markets",
    "page.body.header.charts": "Charts",
    "page.body.header.myOrders": "My Orders",
    "page.body.header.trades": "Trades",
    "page.body.header.orders": "Orderbook",
    "page.body.header.buySell": "Buy/Sell",

    "page.body.wallets.noWallets": "No Wallets Found",
    "page.body.dark.version": "Dark Version",

    "page.productMenu.market": "Market",
    "page.productMenu.exchange": "Exchange",
    "page.productMenu.app": "App",
    "page.productMenu.cards": "Cards",
    "page.productMenu.mining": "Mining",
    "page.productMenu.staking": "Staking",
    "page.productMenu.defi": "DeFi",
    "page.productMenu.convert": "Convert",

    ...nationalitiesNames
}
