import React from 'react'

// components
import CoinTag from '../CoinTag/CoinTag'

// styles
import Styles from './marketCoinCard.module.css'

// images/icons imports
import UpArrow from './up-arrow.svg'
import DownArrow from './down-arrow.svg'
import Graph from './green-graph.svg'

function MarketCoinCard({
    name,
    tag,
    icon,
    price,
    priceChangePercentage,
    style
}) {
  return (
    <div className={Styles.card} style={style}>

        <div className={Styles.cardHeader}>
            <img src={icon}/>
            {name}
            <CoinTag tag={tag} size={12}/>
        </div>

        <div className={Styles.cardInfo}>
            <span>{price}</span>
                {
                    priceChangePercentage > 0 ? (
                        <span style={{color: 'var(--positive-color)'}}>
                            {`+${priceChangePercentage}%`}
                            <img src={UpArrow}/>
                        </span>
                    ) : (
                        <span style={{color: 'var(--negative-color)'}}>
                            {`-${priceChangePercentage}%`}
                            <img src={DownArrow}/>
                        </span>
                    )
                }
        </div>
        
        <img
            src={Graph}
            style={{width:'100%', margin:10}}
        />

    </div>
  )
}

export default MarketCoinCard