import React from 'react'

// styles
import Styles from './notificationsTab.module.css'

// icons
import DeviceLogin from '../../assets/notifications/device-login-alerts.svg'
import EmailMarketingNotification from '../../assets/notifications/email-marketing-notification.svg'
import EmailMarketing from '../../assets/notifications/email-marketing.svg'
import PriceAlerts from '../../assets/notifications/price-alerts.svg'

// mui imports
import { Switch } from '@mui/material'
import { styled } from '@mui/system'

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: 'var(--primary-color)',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: 'gray'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity:  0.7
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1
    }
  }))


function NotificationsTab() {


  return (
    <div className={Styles.tabContainer}>
        <h5>Notification Preferences</h5>

        <div className={Styles.notificationRow}>
          <div className={Styles.notificationRowLeft}>
            <img src={PriceAlerts}/>
            <div>
              <span>Price Alerts</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>

            <div>
                <IOSSwitch />
            </div>
        </div>

        <div className={Styles.notificationRow}>
          <div className={Styles.notificationRowLeft}>
            <img src={EmailMarketing}/>
            <div>
              <span>Email Marketing</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>

            <div>
                <IOSSwitch />
            </div>
        </div>

        <div className={Styles.notificationRow}>
          <div className={Styles.notificationRowLeft}>
            <img src={DeviceLogin}/>
            <div>
              <span>Device Login Alerts</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>

            <div>
                <IOSSwitch />
            </div>
        </div>

        <div className={Styles.notificationRow}>
          <div className={Styles.notificationRowLeft}>
            <img src={EmailMarketingNotification}/>
            <div>
              <span>Email Marketing Notification</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>

            <div>
                <IOSSwitch />
            </div>
        </div>
    </div>
  )
}

export default NotificationsTab