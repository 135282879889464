export const nationalitiesNamesZH = {
    "page.body.kyc.nationalities.afghan": "阿富汗",
    "page.body.kyc.nationalities.albanian": "阿尔巴尼亚语",
    "page.body.kyc.nationalities.algerian": "阿尔及利亚",
    "page.body.kyc.nationalities.american": "美国",
    "page.body.kyc.nationalities.andorran": "安道尔",
    "page.body.kyc.nationalities.angolan": "安哥拉",
    "page.body.kyc.nationalities.antiguans": "安提瓜",
    "page.body.kyc.nationalities.argentinean": "阿根廷",
    "page.body.kyc.nationalities.armenian": "亚美尼亚",
    "page.body.kyc.nationalities.australian": "澳洲",
    "page.body.kyc.nationalities.austrian": "奥地利",
    "page.body.kyc.nationalities.azerbaijani": "阿塞拜疆",
    "page.body.kyc.nationalities.bahamian": "巴哈马",
    "page.body.kyc.nationalities.bahraini": "巴林",
    "page.body.kyc.nationalities.bangladeshi": "孟加拉",
    "page.body.kyc.nationalities.barbadian": "巴巴多斯",
    "page.body.kyc.nationalities.barbudans": "巴布达",
    "page.body.kyc.nationalities.batswana": "巴茨瓦纳",
    "page.body.kyc.nationalities.belarusian": "白俄罗斯",
    "page.body.kyc.nationalities.belgian": "比利时",
    "page.body.kyc.nationalities.belizean": "伯利兹",
    "page.body.kyc.nationalities.beninese": "贝宁斯",
    "page.body.kyc.nationalities.bhutanese": "不丹",
    "page.body.kyc.nationalities.bolivian": "玻利维亚",
    "page.body.kyc.nationalities.bosnian": "波斯尼亚",
    "page.body.kyc.nationalities.brazilian": "巴西",
    "page.body.kyc.nationalities.british": "英国",
    "page.body.kyc.nationalities.bruneian": "布鲁尼",
    "page.body.kyc.nationalities.bulgarian": "保加利亚",
    "page.body.kyc.nationalities.burkinabe": "布基纳比",
    "page.body.kyc.nationalities.burmese": "缅甸",
    "page.body.kyc.nationalities.burundian": "布隆迪",
    "page.body.kyc.nationalities.cambodian": "柬埔寨",
    "page.body.kyc.nationalities.cameroonian": "喀麦隆",
    "page.body.kyc.nationalities.canadian": "加拿大",
    "page.body.kyc.nationalities.cape.verdean": "佛得角",
    "page.body.kyc.nationalities.central.african": "中非",
    "page.body.kyc.nationalities.chadian": "乍得",
    "page.body.kyc.nationalities.chilean": "智利",
    "page.body.kyc.nationalities.chinese": "中国",
    "page.body.kyc.nationalities.colombian": "哥伦比亚",
    "page.body.kyc.nationalities.comoran": "科摩兰",
    "page.body.kyc.nationalities.congolese": "刚果",
    "page.body.kyc.nationalities.costa.rican": "哥斯达黎加",
    "page.body.kyc.nationalities.croatian": "克罗地亚",
    "page.body.kyc.nationalities.cuban": "古巴",
    "page.body.kyc.nationalities.cypriot": "塞族",
    "page.body.kyc.nationalities.czech": "捷克",
    "page.body.kyc.nationalities.danish": "丹麦",
    "page.body.kyc.nationalities.djibouti": "吉布地",
    "page.body.kyc.nationalities.dominican": "多米尼加",
    "page.body.kyc.nationalities.dutch": "荷兰",
    "page.body.kyc.nationalities.east.timorese": "东帝汶",
    "page.body.kyc.nationalities.ecuadorean": "厄瓜多尔",
    "page.body.kyc.nationalities.egyptian": "埃及",
    "page.body.kyc.nationalities.emirian": "埃米尔",
    "page.body.kyc.nationalities.equatorial.guinean": "赤道几内亚",
    "page.body.kyc.nationalities.eritrean": "厄立特里亚",
    "page.body.kyc.nationalities.estonian": "爱沙尼亚",
    "page.body.kyc.nationalities.ethiopian": "埃塞俄比亚",
    "page.body.kyc.nationalities.fijian": "斐济",
    "page.body.kyc.nationalities.filipino": "菲律宾",
    "page.body.kyc.nationalities.finnish": "芬兰",
    "page.body.kyc.nationalities.french": "法国",
    "page.body.kyc.nationalities.gabonese": "加蓬",
    "page.body.kyc.nationalities.gambian": "冈比亚",
    "page.body.kyc.nationalities.georgian": "格鲁吉亚",
    "page.body.kyc.nationalities.german": "德国",
    "page.body.kyc.nationalities.ghanaian": "加纳",
    "page.body.kyc.nationalities.greek": "希腊",
    "page.body.kyc.nationalities.grenadian": "格林纳丁",
    "page.body.kyc.nationalities.guatemalan": "危地马拉",
    "page.body.kyc.nationalities.guinea.bissauan": "几内亚比绍安",
    "page.body.kyc.nationalities.guinean": "几内亚",
    "page.body.kyc.nationalities.guyanese": "圭亚那",
    "page.body.kyc.nationalities.haitian": "海地",
    "page.body.kyc.nationalities.herzegovinian": "黑塞哥维那",
    "page.body.kyc.nationalities.honduran": "洪都拉斯",
    "page.body.kyc.nationalities.hungarian": "匈牙利",
    "page.body.kyc.nationalities.i.kiribati": "基里巴斯",
    "page.body.kyc.nationalities.icelander": "冰岛",
    "page.body.kyc.nationalities.indian": "印度",
    "page.body.kyc.nationalities.indonesian": "印度尼西亚",
    "page.body.kyc.nationalities.iranian": "伊朗",
    "page.body.kyc.nationalities.iraqi": "伊拉克",
    "page.body.kyc.nationalities.irish": "爱尔兰",
    "page.body.kyc.nationalities.israeli": "以色列",
    "page.body.kyc.nationalities.italian": "意大利",
    "page.body.kyc.nationalities.ivorian": "科特迪瓦",
    "page.body.kyc.nationalities.jamaican": "牙买加",
    "page.body.kyc.nationalities.japanese": "日本",
    "page.body.kyc.nationalities.jordanian": "约旦",
    "page.body.kyc.nationalities.kazakhstani": "哈萨克斯坦",
    "page.body.kyc.nationalities.kenyan": "肯尼亚",
    "page.body.kyc.nationalities.kittian.nevisian": "基蒂安和尼维斯",
    "page.body.kyc.nationalities.kuwaiti": "科威特",
    "page.body.kyc.nationalities.kyrgyz": "吉尔吉斯",
    "page.body.kyc.nationalities.laotian": "老田",
    "page.body.kyc.nationalities.latvian": "拉脱维亚",
    "page.body.kyc.nationalities.lebanese": "黎巴嫩",
    "page.body.kyc.nationalities.liberian": "利比里亚",
    "page.body.kyc.nationalities.libyan": "利比亚",
    "page.body.kyc.nationalities.liechtensteiner": "列支敦士登",
    "page.body.kyc.nationalities.lithuanian": "立陶宛",
    "page.body.kyc.nationalities.luxembourger": "卢森堡",
    "page.body.kyc.nationalities.macedonian": "马其顿",
    "page.body.kyc.nationalities.malagasy": "马尔加",
    "page.body.kyc.nationalities.malawian": "马拉维",
    "page.body.kyc.nationalities.malaysian": "马来西亚",
    "page.body.kyc.nationalities.maldivan": "马尔迪万",
    "page.body.kyc.nationalities.malian": "马里",
    "page.body.kyc.nationalities.maltese": "马耳他",
    "page.body.kyc.nationalities.marshallese": "马绍尔",
    "page.body.kyc.nationalities.mauritanian": "毛里塔尼亚",
    "page.body.kyc.nationalities.mauritian": "毛里求斯",
    "page.body.kyc.nationalities.mexican": "墨西哥",
    "page.body.kyc.nationalities.micronesian": "密克罗尼西亚",
    "page.body.kyc.nationalities.moldovan": "摩尔多瓦",
    "page.body.kyc.nationalities.monacan": "摩纳哥",
    "page.body.kyc.nationalities.mongolian": "蒙古",
    "page.body.kyc.nationalities.moroccan": "摩洛哥",
    "page.body.kyc.nationalities.mosotho": "莫索托",
    "page.body.kyc.nationalities.motswana": "莫茨瓦纳",
    "page.body.kyc.nationalities.mozambican": "莫桑比克",
    "page.body.kyc.nationalities.namibian": "纳米比亚",
    "page.body.kyc.nationalities.nauruan": "瑙鲁",
    "page.body.kyc.nationalities.nepalese": "尼泊尔",
    "page.body.kyc.nationalities.new.zealander": "新西兰",
    "page.body.kyc.nationalities.nicaraguan": "尼加拉瓜",
    "page.body.kyc.nationalities.nigerian": "尼日利亚",
    "page.body.kyc.nationalities.nigerien": "尼日尔",
    "page.body.kyc.nationalities.north.korean": "北朝鲜",
    "page.body.kyc.nationalities.northern.irish": "北爱尔兰",
    "page.body.kyc.nationalities.norwegian": "挪威",
    "page.body.kyc.nationalities.omani": "阿曼尼",
    "page.body.kyc.nationalities.pakistani": "巴基斯坦",
    "page.body.kyc.nationalities.palauan": "帕劳共和国",
    "page.body.kyc.nationalities.panamanian": "巴拿马",
    "page.body.kyc.nationalities.papua.new.guinean": "巴布亚新几内亚",
    "page.body.kyc.nationalities.paraguayan": "巴拉圭",
    "page.body.kyc.nationalities.peruvian": "秘鲁",
    "page.body.kyc.nationalities.polish": "抛光",
    "page.body.kyc.nationalities.portuguese": "葡萄牙",
    "page.body.kyc.nationalities.qatari": "卡塔尔",
    "page.body.kyc.nationalities.romanian": "罗马尼亚",
    "page.body.kyc.nationalities.russian": "俄罗斯",
    "page.body.kyc.nationalities.rwandan": "卢旺丹",
    "page.body.kyc.nationalities.saint.lucian": "圣卢西安",
    "page.body.kyc.nationalities.salvadoran": "萨尔瓦多",
    "page.body.kyc.nationalities.samoan": "萨摩亚",
    "page.body.kyc.nationalities.san.marinese": "圣马力诺",
    "page.body.kyc.nationalities.sao.tomean": "圣多美",
    "page.body.kyc.nationalities.saudi": "沙特",
    "page.body.kyc.nationalities.scottish": "苏格兰",
    "page.body.kyc.nationalities.senegalese": "塞内加尔",
    "page.body.kyc.nationalities.serbian": "塞尔维亚",
    "page.body.kyc.nationalities.seychellois": "塞舌尔",
    "page.body.kyc.nationalities.sierra.leonean": "塞拉利昂",
    "page.body.kyc.nationalities.singaporean": "新加坡",
    "page.body.kyc.nationalities.slovakian": "斯洛伐克",
    "page.body.kyc.nationalities.slovenian": "斯洛文尼亚",
    "page.body.kyc.nationalities.solomon.islander": "所罗门群岛",
    "page.body.kyc.nationalities.somali": "索马里",
    "page.body.kyc.nationalities.south.african": "南非",
    "page.body.kyc.nationalities.south.korean": "韩国",
    "page.body.kyc.nationalities.spanish": "西班牙",
    "page.body.kyc.nationalities.sri.lankan": "斯里兰卡",
    "page.body.kyc.nationalities.sudanese": "苏丹",
    "page.body.kyc.nationalities.surinamer": "苏里纳默",
    "page.body.kyc.nationalities.swazi": "斯威士",
    "page.body.kyc.nationalities.swedish": "瑞典",
    "page.body.kyc.nationalities.swiss": "瑞士",
    "page.body.kyc.nationalities.syrian": "叙利亚",
    "page.body.kyc.nationalities.taiwanese": "台湾",
    "page.body.kyc.nationalities.tajik": "塔吉克",
    "page.body.kyc.nationalities.tanzanian": "坦桑尼亚",
    "page.body.kyc.nationalities.thai": "泰国",
    "page.body.kyc.nationalities.togolese": "多哥",
    "page.body.kyc.nationalities.tongan": "同安",
    "page.body.kyc.nationalities.trinidadian.tobagonian": "特立尼达",
    "page.body.kyc.nationalities.tunisian": "突尼斯",
    "page.body.kyc.nationalities.turkish": "土耳其",
    "page.body.kyc.nationalities.tuvaluan": "图瓦卢",
    "page.body.kyc.nationalities.ugandan": "乌干达",
    "page.body.kyc.nationalities.ukrainian": "乌克兰",
    "page.body.kyc.nationalities.uruguayan": "乌拉圭",
    "page.body.kyc.nationalities.uzbekistani": "乌兹别克斯坦",
    "page.body.kyc.nationalities.venezuelan": "委内瑞拉",
    "page.body.kyc.nationalities.vietnamese": "越南",
    "page.body.kyc.nationalities.welsh": "威尔士",
    "page.body.kyc.nationalities.yemenite": "也门",
    "page.body.kyc.nationalities.zambian": "赞比亚",
    "page.body.kyc.nationalities.zimbabwean": "津巴布韦"
}
export const nationalitiesZh = Object.keys(nationalitiesNamesZH)
