import { API } from "../../../../utility/API"

//************************************//
export const _getActivity = (page = 1, callback, callbackErr) => {
    // persistStore().purge()
    API.get(`authenticate/resource/users/activity/all?limit=7&page=${page}`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
