import React, { useRef, useEffect } from 'react'

// redux
import { useDispatch } from 'react-redux'

// styles
import Styles from './marketRow.module.css'

// components
import CoinTag from '../../../../components/CoinTag/CoinTag'

// icons
import { IoMdArrowDropup } from 'react-icons/io'
import { ReactComponent as SellArrowIcon } from '../../../../assets/icons/imported/sell-arrow.svg'
import { ReactComponent as BuyArrowIcon } from '../../../../assets/icons/imported/buy-arrow.svg'

function MarketRow({
    iconURL,
    isSelected = false,
    onClick = () => {},
    pair
}) {

    const dispatch = useDispatch()
    const rowRef = useRef(null)

    useEffect(() => {
        if (isSelected) {
            const { top } = rowRef.current.getBoundingClientRect()
            // rowRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
            const container = rowRef.current.parentElement
            container.scrollTop = top + container.scrollTop - container.offsetTop
        }
    }, [])

  return (
    <div
        onClick={onClick}
        ref={rowRef}
        className={`${Styles.rowContainer} ${isSelected && Styles.isSelected}`}
    >
        {/* left section */}
        <div className={Styles.leftSectionRow}>
            {/* logo */}
            <img
                src={iconURL}
            />

            {/* pair name */}
            <div>
                <span>{`${pair.name.split('/')[0]} / ${pair.name.split('/')[1]}`}</span>
                <CoinTag
                    tag={pair.coin_name}
                    size={8}
                    style={{width: 'fit-content'}}
                />
            </div>
        </div>

        {/* right section */}
        <div className={Styles.rightSectionRow}>
            {/* {console.log(pair)} */}
            <span>{pair.last}</span>
            <span style={{color: pair.price_change_percent_num >= 0 ? 'var(--positive-color)' : 'var(--negative-color)'}}>
                {
                    pair.price_change_percent_num === 0 ? '' : pair.price_change_percent_num < 0 ? '' : '+'
                }
                {pair.price_change_percent_num}%
                {/* <IoMdArrowDropup
                    style={{
                        transform: pair.price_change_percent_num < 0 && 'rotateX(180deg)'
                    }}
                /> */}
                {
                    pair.price_change_percent_num >= 0 ? (
                        <BuyArrowIcon style={{
                            width: 11,
                            marginLeft: 2
                        }}/>
                    ) : (
                        <SellArrowIcon style={{
                            width: 11,
                            marginLeft: 2
                        }}/>
                    )
                }
            </span>
        </div>

    </div>
  )
}

export default MarketRow