import React, { useMemo } from 'react'
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from './orderbook.module.css'

// icons
import { IoMdArrowDropup } from 'react-icons/io'
import UpWhiteArrow from '../../../../assets/icons/up-white-arrow.svg'

// components
import OrderbookRow from './OrderbookRow'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

//redux
import { useDispatch, useSelector } from 'react-redux'

// utility/helper
import { accumulateVolume } from './helpers/accumulateVolume'
import { calcMaxVolume } from './helpers/calcMaxVolume'
import { sortBids, sortAsks } from './helpers/sortByPrice'

function Orderbook() {
  const lastTrade = useSelector(state => state.trades.value.slice(-1).reverse()[0])
  const orderBooks = useSelector(state => state.orderBook.value)
  const memoizedOrderBooks = useMemo(() => orderBooks, [orderBooks])
  const memoizedLastTrade = useMemo(() => lastTrade, [lastTrade])
  //const memoizedOrderBooks = allMemoizedOrderBooks.reverse()
  const marketsList = useSelector(state => state.marketList.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)

  const randomWidth = () => Math.floor(Math.random() * 100) + 1
  return (
    <div className={Styles.card}>

      {
        lastTrade && (
          <>
            {/* title */}
            <span className={Styles.cardTitle}><FormattedMessage id={"page.body.trade.orderbook"} /></span>

            {/* sorting row */}
            <div className={Styles.sortingContainer}>
              <span>Price ({marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})<IoMdArrowDropup /></span>
              <span>Amount ({marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()})<IoMdArrowDropup /></span>
              <span><FormattedMessage id={"page.body.trade.orderbook.header.volume"} /> <IoMdArrowDropup /></span>
            </div>
          </>
        )
      }

      {/* asks */}
      <div>
        {/* {memoizedOrderBooks.asks ? (
          memoizedOrderBooks.asks.slice(0, 15).map(order => (
            <OrderbookRow
              isAsk={true}
              //width='20%'
              order={order}
              total={randomWidth()}
            />
          ))
        ) : (
          new Array(10).fill(0).map(() => (
            <ShimmerLine width='100%' height='24px' />
          ))
        )} */}

        {
          new Array(15).fill(0).map(() => (
            <OrderbookRow
              isAsk={true}
              //width='20%'
              order={['12', '62.31112']}
              total={randomWidth()}
            />
          ))
        }

      </div>

      {/* last trade */}
      {
        memoizedLastTrade ? (
          <div
            className={Styles.lastTrade}
            style={{
              backgroundColor: memoizedLastTrade.taker_type === 'buy' ? 'var(--positive-color)' : 'var(--negative-color)'
            }}
          >
            $ {memoizedLastTrade?.price}
            <img
              src={UpWhiteArrow}
              style={{
                transform: memoizedLastTrade.taker_type === 'sell' && 'rotateY(180deg) rotateZ(180deg)'
              }}
            />
          </div>
        ) : (
          <ShimmerLine
            style={{
              margin: '10px'
            }}
            width='100%'
            height='45px'
          />
        )
      }

      {/* bids */}
      <div>
        {/* {memoizedOrderBooks.bids ?
          memoizedOrderBooks.bids.slice(0, 15).map(order => (
            <OrderbookRow
              isAsk={false}
              //width='10%'
              order={order}
              total={randomWidth()}
            />
          ))
          : (
            new Array(10).fill(0).map(() => (
              <ShimmerLine width='100%' height='24px' />
            ))
          )
        } */}

        {
          new Array(15).fill(0).map(() => (
            <OrderbookRow
              isAsk={false}
              //width='20%'
              order={[12, 62.31112]}
              total={randomWidth()}
            />
          ))
        } 

      </div>

    </div>
  )
}

export default injectIntl(Orderbook)