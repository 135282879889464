import React, { useRef } from 'react'

// styles
import Styles from './checkboxInput.module.css'

// icons
import { ReactComponent as CheckboxIcon } from '../../assets/icons/imported/checkmark.svg'

function CheckboxInput({
  checkboxName,
  checkboxId,
  label,
  labelFor,
  checked,
  setChecked,
  labelStyle,
  checkboxStyle,
  containerStyle,
  ...rest
}) {
  const inputRef = useRef(null)

  const handleCheckboxClick = () => {
    const newChecked = !inputRef.current.checked
    setChecked(newChecked)
  }

  return (
    <div className={Styles.container} style={{...containerStyle}} onClick={handleCheckboxClick}>
      <input
        id={checkboxId}
        name={checkboxName}
        type="checkbox"
        className={Styles.input}
        checked={checked}
        onChange={() => {}}
        ref={inputRef}
        {...rest}
      />
      <div
        className={Styles.checkbox}
        style={{ ...checkboxStyle }}
      >
        {checked && <CheckboxIcon width={13} color='white'/>}
      </div>
      <label
        htmlFor={labelFor}
        className={Styles.label}
        style={{ ...labelStyle }}
      >
        {label}
      </label>
    </div>
  )
}

export default CheckboxInput