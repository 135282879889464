import React from 'react'

// redux
import { useSelector } from 'react-redux'

// styles
import Styles from './currencyInput.module.css'

// icons
import { BiChevronDown } from "react-icons/bi"

// packages
import { FormattedMessage } from 'react-intl'

function CurrencyInput({
    title,
    secondaryTitle,
    coinName,
    currencyIcon = '$',
    onChange = () => {},
    currencyImg,
    containerStyle,
    showDropdownArrow = false,
    onDropdownClick,
    ...rest
}) {
  
  return (
    <div className={Styles.buySellInputContainer} style={{...containerStyle}}>
    {/* left section */}
    <div className={Styles.buySellLeftSection}>
      {/* input field title */}
      <div className={Styles.buySellInputTitle}>
        {title}
      </div>

      {/* input group container */}
      <div className={Styles.buySellInputGroupContainer}>
        <span>{currencyIcon}</span>
        <input defaultValue={0} onChange={onChange} {...rest}/>
      </div>
    </div>

    {/* right section */}
    <div className={Styles.buySellRightSection}>
      {
        secondaryTitle && (
          <div className={Styles.secondaryTitle}>
            {secondaryTitle}
          </div>
        )
      }
      <div onClick={onDropdownClick}>
        {currencyImg && <img src={currencyImg} />}
        {coinName && <span>{coinName}</span>}
        {showDropdownArrow && <BiChevronDown color="#9295A6" />}
      </div>
    </div>
  </div>
  )
}

export default CurrencyInput