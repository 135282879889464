import React, { useEffect, useState } from 'react'

// react router
import { Link, useHistory } from 'react-router-dom'

// styles
import Styles from './landingPage.module.css'

// svgs/photos
import heroSVG from '../assets/hero.svg'
import hero2SVG from '../assets/hero2/hero2.svg'
import hero2FrameSVG from '../assets/hero2/frame-hero2.svg'

// icons
// import { HiUserAdd } from 'react-icons/hi'
// import { RiSecurePaymentFill } from 'react-icons/ri'
import AddUser from '../assets/get-started-icons/add-user.svg'
import CreditCard from '../assets/get-started-icons/credit-card.svg'
import Wallet from '../assets/get-started-icons/wallet.svg'
import Diagram from '../assets/get-started-icons/diagram.svg'

import Safety from '../assets/services-icons/Icon.svg'
import EasyDeposit from '../assets/services-icons/Icon-1.svg'
import LowCharges from '../assets/services-icons/Icon-2.svg'
import BonusRefferals from '../assets/services-icons/Icon-3.svg'
import FastTransaction from '../assets/services-icons/Icon-4.svg'
import DeepEncryption from '../assets/services-icons/Icon-5.svg'
import FastKYC from '../assets/services-icons/Icon-6.svg'
import Support_24_7 from '../assets/services-icons/Icon-7.svg'

// components
import Button from '../../../components/Button/Button'
import MarketCoinCard from '../../../components/MarketCoinCard/MarketCoinCard'

// apis
import { _getCurrencies } from '../../exchange/redux/actions'
import ServicesCard from '../components/ServicesCard/ServicesCard'

function LandingPage() {

    const [coins, setCoins] = useState([])

    useEffect(() => {
        _getCurrencies(
            (data) => {
                setCoins(data.splice(8, 8))
            },
            (err) => {

            }
        )
    }, [])

    const history = useHistory()

  return (
    <div className={Styles.pageContainer}>
        
        {/* Hero section */}
        <div className={`${Styles.card} ${Styles.heroContainer}`}>

            <div className={Styles.heroTextContainer}>
                <div className={Styles.heroTextHeaderContainer}>
                    <div>Buy & Sell</div>
                    <div>Crypto in Minutes</div>
                </div>

                <div className={Styles.heroTextBodyContainer}>
                    <p>
                        Bit, as in Bitcoin, is the base of cryptocurrencies
                        <Link to='/signup'>Sign up</Link> today and trade the top cryptos
                        Use our Academy and latest info free
                    </p>

                    <span>Now is the time for action</span>
                </div>

                <Button
                    text='Start Trading'
                    style={{width: 'fit-content'}}
                    onClick={() => history.replace('/trade')}
                />

            </div>
            <img
                src={heroSVG}
                className={Styles.heroSVG}
            />
        </div>


        {/* Top Markets section */}
        <div className={Styles.topMarketContainer}>
            <div>Top Market</div>
            <div>Get Various Crypto Coin</div>

            {/* market cards container */}
            <div className={Styles.gridContainer}>
                {
                    coins.map((coin) => (
                        <MarketCoinCard
                            key={coin.id}
                            icon={coin.icon_url}
                            name={coin.name}
                            tag={coin.id.toUpperCase()}
                            price={'$34,744.21'}
                            priceChangePercentage={2}
                            style={{width: '100%'}}
                        />
                    ))
                }
            </div>
        </div>

    
        {/* Get Started Section */}
        <div className={Styles.getStartedContainer}>

            {/* left section */}
            <div className={Styles.getStartedLeftSection}>
                <span>Create Profile</span>
                <h2>Easy Wat to Get Started</h2>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                </p>

                <div className={Styles.startTradingContainer}>
                    <Link to='/trade'>Start Trading</Link>
                    <div className={Styles.whiteCircle}>
                        {'>'}
                    </div>
                </div>
            </div>

            {/* left section */}
            <div className={Styles.getStartedRightSection}>

                {/* row */}
                <div className={Styles.getStartedStepsRow}>
                    
                    <div className={Styles.getStartedStepCard}>
                        <div className={`${Styles.whiteCircle} ${Styles.getStartedIndicator}`}>
                            1
                        </div>
                        <img src={AddUser}/>
                        <div>Create an Account</div>
                        <p>Sign up with your email and mobile in just 5 minutes</p>
                    </div>

                    <div className={Styles.getStartedStepCard}>
                        <div className={`${Styles.whiteCircle} ${Styles.getStartedIndicator}`}>
                            2
                        </div>
                        <img src={CreditCard}/>
                        <div>Verify your KYC</div>
                        <p>Verify your Account in Easy Way</p>
                    </div>

                </div>

                {/* row */}
                <div className={Styles.getStartedStepsRow}>
                    <div className={Styles.getStartedStepCard}>
                        <div className={`${Styles.whiteCircle} ${Styles.getStartedIndicator}`}>
                            3
                        </div>
                        <img src={Wallet}/>
                        <div>Add Funds to Wallet</div>
                        <p>Quickly add money to your investment secured wallet</p>
                    </div>

                    <div className={Styles.getStartedStepCard}>
                        <div className={`${Styles.whiteCircle} ${Styles.getStartedIndicator}`}>
                            4
                        </div>
                        <img src={Diagram}/>
                        <div>Start Trading</div>
                        <p>Buy & Sell a variety of top coins at the best prices</p>
                    </div>
                </div>

            </div>

        </div>


        {/* trade and register */}
        <div className={Styles.tradeAndRegisterContainer}>

            {/* left section */}
            <div className={Styles.tradeAndRegisterLeftSection}>
                <img src={hero2SVG} className={Styles.hero2SVG}/>
                {/* <img src={hero2FrameSVG} className={Styles.hero2SVGFrame}/> */}
            </div>

            {/* right section */}
            <div className={Styles.tradeAndRegisterRightSection}>
                <span>Download the app</span>
                <h2>
                    Trade & Be Part <br />
                    of our Community
                </h2>
                <p>
                    Fast Trading anywhere! <br />
                    Access anytime. <br />
                    Live without restrictions.
                </p>

                <div>
                    <Button
                        theme='dark'
                        text='Sign In'
                        onClick={() => history.replace('/login')}
                    />
                    <Button
                        text='Register'
                        onClick={() => history.replace('/signup')}
                    />
                </div>
            </div>

        </div>

        {/* Our Best Services */}
        <div className={Styles.topMarketContainer}>
            <div>Benefits</div>
            <div>Our Best Services</div>
            <div style={{
                textAlign:'center',
                color:'#9295A6',
                fontSize: 13,
                width: '50%',
                minWidth: 180,
                margin: '0 auto'
            }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
            </div>

            {/* market cards container */}
            <div className={Styles.gridContainer}>
                <ServicesCard
                    icon={Safety}
                    title='Safety Comes First'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={EasyDeposit}
                    title='Easy Deposit & Withdrawls'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={LowCharges}
                    title='Low Charges'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={BonusRefferals}
                    title='Bonus & Refferal'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={FastTransaction}
                    title='Fast Transactions'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={DeepEncryption}
                    title='Deep Encryption'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={FastKYC}
                    title='Fast KYC'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
                <ServicesCard
                    icon={Support_24_7}
                    title='24/7 Support'
                    text='Lorem ipsum dolor sit amet, dui  consectetur adipiscing elit. Nibh aenean dui aliquet amet.'
                />
            </div>
        </div>


        {/* news letter container */}
        <div className={Styles.newsLetterContainer}>

            <div>
                <h2>Join our newsletter</h2>
                <p>Join now with Wenbit to get the latest news and start trading now</p>
            </div>

            <div>
                <input
                    placeholder='Enter your email'
                    type='email'
                />
                <Button
                    text='Subscribe'
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 32,
                        width: 'fit-content',
                        padding: '7px 15px'
                    }}
                />
            </div>

        </div>


    </div>
  )
}

export default LandingPage