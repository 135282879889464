import React from 'react'

// styles
import Styles from './footer.module.css'
import { FormattedMessage, injectIntl } from "react-intl"

// components
import FooterLink from './FooterLink'
import FooterSection from './FooterSection'

// icons
import { ReactComponent as UpArrowIcon } from '../../assets/icons/imported/up-arrow.svg'
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import { SiAppstore } from 'react-icons/si'
import { IoLogoGooglePlaystore } from 'react-icons/io5'

function Footer() {

    const sections = [
        {
            title: <FormattedMessage id='page.footer.exchange' />,
            links: [
                {text: <FormattedMessage id='page.footer.exchange' />, url: '/trade'},
                {text: <FormattedMessage id='page.footer.convert' />, url: '/convert'},
                {text: <FormattedMessage id='page.footer.launchpad' />, url: '/launchpad'},
                {text: <FormattedMessage id='page.footer.wallet' />, url: '/wallet'}
            ]
        },
        {
            title: <FormattedMessage id='page.footer.support' />,
            links: [
                {text: <FormattedMessage id='page.footer.requestForm' />, url: '/'},
                {text: <FormattedMessage id='page.footer.contactSupport' />, url: '/'},
                {text: <FormattedMessage id='page.footer.faq' />, url: '/'},
                {text: <FormattedMessage id='page.footer.security' />, url: '/'}
            ]
        },
        {
            title: <FormattedMessage id='page.footer.resources' />,
            links: [
                {text: <FormattedMessage id='page.footer.downloads' />, url: '/'},
                {text: <FormattedMessage id='page.footer.mobileApplication' />, url: '/'},
                {text: <FormattedMessage id='page.footer.buyCrypto' />, url: '/'},
                {text: <FormattedMessage id='page.footer.referral' />, url: '/'},
                {text: <FormattedMessage id='page.footer.listingTranding' />, url: '/'}
            ]
        },
        {
            title: <FormattedMessage id='page.footer.learn' />,
            links: [
                {text: <FormattedMessage id='page.footer.whatsTrending' />, url: '/'},
                {text: <FormattedMessage id='page.footer.productNews' />, url: '/'},
                {text: <FormattedMessage id='page.footer.events' />, url: '/'},
                {text: <FormattedMessage id='page.footer.university' />, url: '/'},
                {text: <FormattedMessage id='page.footer.research' />, url: '/'},
                {text: <FormattedMessage id='page.footer.marketUpdate' />, url: '/'}
            ]
        },
        {
            title: <FormattedMessage id='page.footer.company' />,
            links: [
                {text: <FormattedMessage id='page.footer.aboutUs' />, url: '/'},
                {text: <FormattedMessage id='page.footer.careers' />, url: '/'},
                {text: <FormattedMessage id='page.footer.news' />, url: '/'},
                {text: <FormattedMessage id='page.footer.security' />, url: '/'},
                {text: <FormattedMessage id='page.footer.community' />, url: '/'},
                {text: <FormattedMessage id='page.footer.announcements' />, url: '/'}
            ]
        }
    ]

  return (
    // <div>

    <div className={Styles.mainContainer}>
        
        {/* wenbit logo, moto, media links */}
        <div className={Styles.mediaSection}>

            {/* logo */}
            <img src='https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg'/>

            {/* moto */}
            <p>Lorem ipsum dolor sit amet,consec egestas nisl condimentum.adipiscing elit. Nibh aenean aliquet amet, neque sit maecenas. Lorem sed egestas nisl condimentum.</p>

            {/* media links container */}
            <div className={Styles.mediaLinksContainer}>
                <FooterLink
                    to='www.facebook.com'
                    key='www.facebook.com'
                    text={<FaFacebookF size={18}/>}
                    animationDirection='up'
                />
                <FooterLink
                    to='www.instagram.com'
                    key='www.instagram.com'
                    text={<FaInstagram size={18}/>}
                    animationDirection='up'
                    />
                <FooterLink
                    to='www.youtube.com'
                    key='www.youtube.com'
                    text={<FaYoutube size={18}/>}
                    animationDirection='up'
                />
                <FooterLink
                    to='www.twitter.com'
                    key='www.twitter.com'
                    text={<FaTwitter size={18}/>}
                    animationDirection='up'
                    />
                <FooterLink
                    to='www.linkedin.com'
                    key='www.linkedin.com'
                    text={<FaLinkedin size={18}/>}
                    animationDirection='up'
                />
            </div>
        </div>

        {
            sections.map((section, index) => (
                <FooterSection
                    title={section.title}
                    links={section.links}
                    key={index}
                />
                ))
        }

        <div className={Styles.bottomContainer}>
            
            {/* scroll to top button */}
            <div className={Styles.srollTopButtonContainer} onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }}>
                <UpArrowIcon width={12}/>
            </div>

            {/* left container */}
            <div className={Styles.bottomLeftContainer}>
                © 2023 Wenbit. <FormattedMessage id='page.footer.allRightsReserved' />
            </div>

            {/* mid container */}
            <div className={Styles.bottomMidContainer}>
                <FooterLink
                    text= {<FormattedMessage id='page.footer.privacy' />}
                    key={'privacy'}
                />
                
                <FooterLink
                    text= {<FormattedMessage id='page.footer.terms' />}
                    key={'terms'}
                />            
    
                <FooterLink
                    text= {<FormattedMessage id='page.footer.siteMap' />}
                    key={'siteMap'}
                />
            </div>

            {/* right container */}
            <div className={Styles.bottomRightContainer}>
                
                {/* apple store */}
                <div
                    className={Styles.appContainer}
                    onClick={() => {
                        window.open(URL, 'https://www.apple.com/app-store/')
                    }}
                >
                    <SiAppstore className={Styles.appleStoreIcon} size={23}/>
                    <div className={Styles.downloadContainer}>
                        <span><FormattedMessage id='page.footer.iosDownload' /></span>
                        <span><strong>App Store</strong></span>
                    </div>
                </div>

                {/* play store */}
                <div
                    className={Styles.appContainer}
                    onClick={() => {
                        window.open(URL, 'https://play.google.com/')
                    }}
                >
                    <IoLogoGooglePlaystore size={23}/>
                    <div className={Styles.downloadContainer}>
                        <span><FormattedMessage id='page.footer.androidDownload' /></span>
                        <span><strong>Google Play</strong></span>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
  )
}

export default injectIntl(Footer)