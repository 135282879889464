import React, { useState, useContext } from 'react'

// react router
import { Link, useHistory } from 'react-router-dom'
// import { Form, Input, Label, FormFeedback, Button } from 'reactstrap'

// form validation
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Input from '../../../../components/Input/Input'
import { _register } from '../../redux/actions'
// import classnames from 'classnames'
// import FormGroup from '@mui/material/FormGroup'

// utilities
import _, { isEmpty } from 'lodash'
import { FormattedMessage, injectIntl } from "react-intl"

// theme
import { ThemeContext } from '../../../../context/ThemeContext'

// redux
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../redux/slices/userDataSlice"

// components
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form } from 'reactstrap'
import Button from '../../../../components/Button/Button'

// icons
import { FiEye, FiEyeOff } from 'react-icons/fi'

// styles
import Styles from './signup.module.css'

// photos
import illustrationPhoto from '../../assets/images/login-signup-illustration.png'

// register schema
const registerSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  termsAndConditions: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required()
})

const register = () => {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [noMatch, setNoMatch] = useState(false)
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(registerSchema),
    mode: 'onSubmit'
  })
  const theme = useContext(ThemeContext).data.theme
  const dispatch = useDispatch()
  const history = useHistory()
  document.title = "Wenbit: Register"


  // const userData = useSelector((state) => state.userData.value)

  const onSubmit = (data) => {
    // console.log("HERE")
    // console.log(data)
    if (isEmpty(errors)) {
      if (confirmPassword === password) {
        _register(
          {
            username: data.username,
            email: data.email,
            password: data.password
          },
          (data) => {
            dispatch(setUser(data))
            history.push('/trade')
          },
          (error) => {
            console.log(error.errors)
            toast.error(
              <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
            )
          }
        )
      } else {
        setNoMatch(true)
      }
    }
  }

  return (
    <div className={Styles.pageContainer}>

      {/* illustration container */}
      <div className={Styles.illustrationContainer}>
        <img
          src={illustrationPhoto}
        />
      </div>

      {/* form container */}
      <div className={Styles.formContainer}>

        {/* logo */}
        <img
          src='https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg'
          onClick={() => history.replace('/trade')}
          style={{ cursor: 'pointer' }}
        />

        {/* form */}
        <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>

          {/* title */}
          <span className={Styles.title}><FormattedMessage id={"page.header.signUp"} /></span>

          {/* username input */}
          <Input
            id='username'
            placeholder='Enter Username'
            error={errors.username}
            {...register('username')}
          />

          {/* mail input */}
          <Input
            id='email'
            placeholder='Enter Email or Phone Number'
            error={errors.email}
            {...register('email')}
          />

          {/* password input */}
          <Input
            type='password'
            {...register('password')}
            id='password'
            placeholder='Password'
            error={errors.password}
          />

          {/* confirm password input */}
          <Input
            type='password'
            {...register('confirmPassword')}
            id='confirmPassword'
            placeholder='Confirm Password'
            error={errors.confirmPassword}
          />
 
          {/* Accept condition checkbox */}
          <div className={`custom-control custom-checkbox ${Styles.checkBoxConditionsContainer}`}>
            <input
              type="checkbox"
              className="custom-control-input"
              id="termsAndConditions"
              color='red'
              {...register('termsAndConditions')}
            />
            <label className="custom-control-label" htmlFor="termsAndConditions">
              <FormattedMessage id='page.header.signUp.terms' />
              <Link to="/terms-and-conditions"><FormattedMessage id='page.signUp.termsAndService.text' /></Link>
            </label>

          </div>
          <span style={{
            fontSize:12,
            color: 'var(--negative-color)'
          }}>{errors.termsAndConditions?.message}</span>

          {/* register button */}
          <Button
            text={<FormattedMessage id='page.signIn.signup' />}
            type='submit'
            style={{ marginTop: 30 }}
          />

          {/* login link */}
          <span className={Styles.loginSpan}><FormattedMessage id='page.signUp.signinExists' /><Link to='/login'><FormattedMessage id='page.signUp.signin' /></Link></span>
        </Form>
      </div>

    </div>
  )
}
export default injectIntl(register)