import React from 'react'

// components imports
import Select, { components } from 'react-select'

// styles imports
import Styles from './selectInput.module.css'

// icons imports
import { BiChevronDown } from 'react-icons/bi'


function SelectInput({
    options,
    defaultValue,
    isSearchable = false,
    style,
    showArrow = true,
    onChange = () => {},
    value,
    controlStyle,
    singleValueStyle,
    containerStyle
}) {

    // select sub-component
    const { DropdownIndicator, IndicatorSeparator } = components


    // custom select styles
    const customStyles = {
        control: (provided, state) => ({
          ...provided,
        background: 'none',
        paddingRight: 0,
        border: 'none',
        ...controlStyle,
        boxShadow: state.isFocused ? 'none' : provided.boxShadow
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
            width: 'auto',
            overflow: 'visible',
            transition: 'color 0.4s',
            '&:hover': {
                color: 'var(--primary-color)',
                curson: 'pointer'
            },
            ...singleValueStyle
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        menu: (provided, state) => ({
            ...provided,
            width: 'fit-content',
            margin: 0,
            borderRadius: 5,
            overflow: 'hidden',
            marginTop: '1px',
            boxShadow: '0 0 20px #000000',
            border: '.7px gray solid',
            background: 'black',
            zIndex: 1
          }),
          option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? 'var(--secondary-color)' : 'none',
            color: 'var(--primary-color)',
            fontWeight: state.isSelected ? 'bold' : null,
            cursor: 'pointer',
            padding: '10px 20px',
            margin: 0
          }),
          menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0
          })
    }

  return (
    <div className={Styles.mainContainer} style={{containerStyle}}>  
        <Select
            styles={{
                ...customStyles,
                ...style
            }}
            options={options}
            defaultValue={defaultValue}
            isSearchable={isSearchable}
            components={{ DropdownIndicator, IndicatorSeparator }}
            onChange={onChange}
            value={value && true}
        />

        {
            showArrow && (
                <span className={Styles.icon}>
                    <BiChevronDown />
                </span>
            )
        }
    </div>
  )
}

export default SelectInput