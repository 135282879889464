import { API } from "../../utility/API"

//************************************//
export const _logout = (callback, callbackErr) => {
    // persistStore().purge()
    API.delete(`authenticate/identity/sessions`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getUserInfo = (callback, callbackErr) => {
    // persistStore().purge()
    API.get(`authenticate/resource/users/me`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
