import React from 'react'

// styles
import Styles from './orders.module.css'

// icons
import { ReactComponent as LinkIcon } from '../../../../assets/icons/imported/link.svg'
import { ReactComponent as SellArrowSquare } from '../../../../assets/icons/imported/sell-arrow-square.svg'
import { ReactComponent as BuyArrowSquare } from '../../../../assets/icons/imported/buy-arrow-square.svg'

// redux
import { useSelector } from 'react-redux'

// components
import NavLink from '../../../../components/Header/NavLink'
import TableButton from '../../../../components/TableButton/TableButton'
import DatatableHeader from '../../../../components/Datatable/DatatableHeader'
import CoinTag from '../../../../components/CoinTag/CoinTag'
import { Link } from 'react-router-dom'

function Orders() {

    const userData = useSelector((state) => state.userData?.value)

  return (
    <div className={Styles.card}>
        
        {/* links container */}
        <div className={Styles.linksContainer}>
            <NavLink
                text='Open Order'
            />
            <NavLink
                text='Open History'
            />
            <TableButton
                buttonText='Order History'
                buttonIcon={<LinkIcon width={11}/>}
                style={{
                    marginLeft: 'auto',
                    marginBottom: 'auto'
                }}
            />
        </div>

        {/* table header */}
        <DatatableHeader
            headerColumnArray={[
                {title: 'Date', flex: .2},
                {title: 'Side', flex: .15},
                {title: 'Pair', flex:.2},
                {title: 'Price', flex: .2},
                {title: 'Amount', flex: .15},
                {title: 'Status', flex: .1}
            ]}
        />

        {/* order rows container */}
        <div className={Styles.orderRowsContainer}>

            {
                userData.email ? (
                    new Array(5).fill(0).map(() => (
                        <div>
                            <span>10/02/23 09:10 PM</span>
                            <span>
                                <SellArrowSquare height={24}/>
                                <div>
                                    <span>Sell</span>
                                    <span>18 Feb, 2022</span>
                                </div>
                            </span>
                            <span>
                                <CoinTag
                                    tag='BTC/USDT'
                                    style={{width: 'fit-content'}}
                                    size={11}
                                />
                            </span>
                            <span>$47,975.72</span>
                            <span>$10,000</span>
                            <span>Pending</span>
                        </div>
                    ))
                ) : (
                    <div className={Styles.loggedOutContainer}>
                        <div>
                            <Link to='/login'>Sign In</Link> or <Link to='/signup'>Register Now</Link> to trade
                        </div>
                    </div>
                )
            }

        </div>

    </div>
  )
}

export default Orders