import React, { useState } from 'react'

// react router
import { useHistory, useLocation } from 'react-router-dom'

// styles
import Styles from './wallet.module.css'

// icons
import { ReactComponent as WalletIcon } from '../../../assets/icons/imported/wallet.svg'
import { ReactComponent as MarketIcon } from '../../../assets/icons/imported/market.svg'
import { ReactComponent as MenuIcon } from '../../../assets/icons/imported/menu.svg'
import { ReactComponent as ConvertIcon } from '../../../assets/icons/imported/convert.svg'
import { ReactComponent as LaunchpadIcon } from '../../../assets/icons/imported/launchpad.svg'
import { BiWallet, BiListUl } from 'react-icons/bi'
import { FiClock } from 'react-icons/fi'
import QR from '../../../assets/images/QR.svg'

// components
import SideNavButton from '../../../components/SideNavButton/SideNavButton'
import Button from '../../../components/Button/Button'
import SpotWalletTab from './SpotWalletTab'
import OrderHistoryTab from './orderHistoryTab'
import ConversionHistoryTab from './ConversionHistoryTab'
import WalletHistoryTab from './WalletHistoryTab'
import LaunchpadHistoryTab from './LaunchpadHistoryTab'
import SelectInput from '../../../components/SelectInput/SelectInput'

// MUI imports
import WenbitModal from '../../../components/Modal/Modal'


function Wallet() {

    const location = useLocation()
    const history = useHistory()

    // modal state
    const [walletDepositModalOpen, setWalletDepositModalOpen] = useState(false)
    const [networkModalOpen, setNetworkModalOpen] = useState(false)

  return (
    <div className={Styles.pageContainer}>

        {/* side nav container */}
        <div className={`${Styles.sideNavContainer} ${Styles.card}`}>
            <SideNavButton
                text='Spot Wallet'
                icon={<WalletIcon width={18}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/wallet/spot'}
                onClick={() => history.replace('/account/wallet/spot')}
            />
            <SideNavButton
                text='Wallet History'
                icon={<WalletIcon width={18}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/wallet/history'}
                onClick={() => history.replace('/account/wallet/history')}
            />
            <SideNavButton
                text='Order History'
                icon={<MenuIcon width={20}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname.includes('/account/orders')}
                onClick={() => history.replace('/account/orders/open')}
            />
            <SideNavButton
                text='Trade History'
                icon={<MarketIcon width={17}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/trade/history'}
                onClick={() => history.replace('/account/trade/history')}
            />
            <SideNavButton
                text='Conversion History'
                icon={<ConvertIcon width={17}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/convert/history'}
                onClick={() => history.replace('/account/convert/history')}
            />
            <SideNavButton
                text='Launchpad History'
                icon={<LaunchpadIcon width={17}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/launchpad'}
                onClick={() => history.replace('/account/launchpad')}
            />
        </div>

        {/* main container */}
        <div className={Styles.mainContainer}>
            {
                location.pathname === '/account/wallet/spot' && (
                    <SpotWalletTab />
                )
            }

            {
                location.pathname.includes('/account/orders') && (
                    <OrderHistoryTab />
                )
            }

            {
                location.pathname === '/account/wallet/history' && (
                    <WalletHistoryTab />
                )
            }
            {
                location.pathname === '/account/convert/history' && (
                    <ConversionHistoryTab />
                )
            }
            {
                location.pathname === '/account/launchpad' && (
                    <LaunchpadHistoryTab />
                )
            }
        </div>


        {/* --------------- MODALS --------------- */}
        
        {/* Wallet Deposit Modal */}
        <WenbitModal
            open={walletDepositModalOpen}
            onClose={() => setWalletDepositModalOpen(false)}
            title='Bitcoin Deposit Address'
            render={() => (
                <>
                <div className={Styles.modalBody}>
                    <span>Scan QR code to Deposit</span>
                    <img src={QR}/>
                </div>

                <div className={Styles.destinationContainer}>
                    <span>Destination</span>
                    <div>
                        <span>bt05hhjkndguj84hkilh3awsx</span>
                        <Button
                            text='Copy'
                            style={{
                                transform: 'scale(.8)',
                                borderRadius: 5,
                                width: 'fit-content'
                            }}
                        />
                    </div>
                </div>

                {/* disclaimer container */}
                <div className={Styles.disclaimerContainer}>
                    <span>Disclaimer</span>
                    <div></div>
                    <span>Please deposit only BTC to this address. If you deposit any other coins, it will be lost forever.</span>
                </div>
                </>
            )}
        />


        {/* Select network modal */}
        <WenbitModal
            title='Select Network'
            showCloseButton={true}
            onClose={() => setNetworkModalOpen(false)}
            open={networkModalOpen}
            render={() => (
                <div>
                    <span
                        style={{
                            fontSize: 11.5
                        }}
                    >Please note that only supported networks on Wenbit platform are shown, if you deposit via another network your assets may be lost.</span>
                    <SelectInput
                        isSearchable={true}
                        options={[
                            {label: 'BTC', value: 'btc'},
                            {label: 'USD', value: 'btc'},
                            {label: 'EUR', value: 'btc'},
                            {label: 'GBP', value: 'btc'}
                        ]}
                    />
                </div>
            )}
        />
    </div>
  )
}

export default Wallet