import React, { useEffect, useState } from 'react'

// styles
import Styles from './datatableHeader.module.css'

// icons
import { IoMdArrowDropup } from 'react-icons/io'

function DatatableHeader({
    headerColumnArray = []
}) {
  return (
        <div className={Styles.sortingRow}>
            {
                headerColumnArray.map(column => (
                    <span key={column.title} style={{flex: column.flex}}>{column.title} <IoMdArrowDropup /></span>
                ))
            }
        </div>
  )
}

export default DatatableHeader