import React, { useEffect, useState } from "react"

// react router
import { useHistory } from "react-router-dom"

// styles imports
import Styles from "./markets.module.css"

// icons imports
import { ReactComponent as LinkIcon } from '../../../assets/icons/imported/link.svg'
import { HiOutlineSearch } from "react-icons/hi"
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import UpArrow from '../../../assets/icons/up-arrow.svg'

// APIs imports
import { _addOrder, _getCurrencies } from "../redux/actions"

// delete imports
import coinIcon from "../../../components/MarketGraphCard/Coin.svg"
import RadioButton from "../../../components/RadioButton/RadioButton"
import Button from "../../../components/Button/Button"
import selectedMarketSlice from "../../exchange/redux/slices/selectedMarketSlice"

// redux
import { useSelector } from 'react-redux'

// component imports
import MarketCarousel from "../../../components/MarketCarousel"
import MarketsList from "../../../components/MarketsList"
import Slider from "react-slick"
import MarketGraphCard from "../../../components/MarketGraphCard/MarketGraphCard"
import MarketRow from "../components/MarketRow"
import { FormattedMessage, injectIntl } from "react-intl"
import { toast } from 'react-toastify'
import CurrencyInput from "../../../components/CurrencyInput/CurrencyInput"
import DatatableHeader from "../../../components/Datatable/DatatableHeader"
import TableButton from '../../../components/TableButton/TableButton'
import CoinTag from "../../../components/CoinTag/CoinTag"
import Convert from "../components/Convert"
import QuickBuySell from "../components/QuickBuySell"

const markets = () => {

  const history = useHistory()

  const [apiCurrencies, setApiCurrencies] = useState('')

  const CarouselSettings = {
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const tableColumns = [
    // {title: '#', value: 'id', flex: .1},
    {title: 'Coin Name', value: 'name', flex: .3},
    {title: 'Coin Price', value: 'price', flex: .15},
    {title: '24%', value: 'percentage', flex: .1},
    {title: '24h High', value: '24_high', flex: .15},
    {title: '24h Low', value: '24_low', flex: .15},
    {title: 'Action', value: 'action', flex: .15}
  ]

  useEffect(() => {
    _getCurrencies(
      (data) => {
        console.log(data)
        setApiCurrencies(data)
      },
      (error) => {
        toast.error(
          <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
        )
      }
    )
  }, [])

  const redirectToConvertHistory = () => {
    history.replace('/account/convert/history')
  }
  const redirectToTradeHistory = () => {
    history.replace('/account/trade/history')
  }
  document.title = "Wenbit: Markets"

  return (
    <div className={Styles.pageContainer}>

      {/* left section container */}
      <div className={Styles.leftSectionContainer}>
        {/* market coins card */}
        <div className={Styles.card}>
          {/* card header */}
          <div className={Styles.cardHeader}>
            {/* left section */}
            <div className={Styles.cardHeaderLeft}>
              <div className={Styles.cardTitle}><FormattedMessage id='page.markets.trade.marketCoins' /></div>
              <div className={Styles.cardSubTitle}>
                This is the market coins subtitle
              </div>
            </div>

            {/* right section */}
            <div className={Styles.cardHeaderRight}>
              <div className={Styles.headerSearchContainer}>
                <HiOutlineSearch color="#9295A6" size={20} />
                <input placeholder="Search Coin Name" />
              </div>
            </div>
          </div>

          {/* card graph marquees container */}
          <div className={Styles.graphMarqueesContainer}>
            <Slider {...CarouselSettings} arrows={false}>
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={+2}
              />
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={-2}
              />
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={+2}
              />
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={+2}
              />
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={-2}
              />
              <MarketGraphCard
                icon={coinIcon}
                coinName="Bitcoin"
                price="$38,447.54"
                priceChangePercentage={-2}
              />
            </Slider>
          </div>
        </div>

        {/* markets list card */}
        <div className={Styles.card}>

          {/* table filter header here */}
          <DatatableHeader
            headerColumnArray={tableColumns}
          />

          {/* row container */}
          <div className={Styles.rowContainer}>
            {
              apiCurrencies.length !== 0 ? (

                apiCurrencies.map((coin, index) => (
                  <div>
                  {/* <span><AiOutlineStar /> {index + 1}</span> */}
                  <span>
                    <img src={coin.icon_url}/>
                    {coin.name}
                    <CoinTag tag={coin.id.toUpperCase()} size={11}/>
                  </span>
                  <span>$392.32</span>
                  <span style={{color: 'var(--positive-color)'}}>
                    +0.60%
                    <img src={UpArrow} style={{width: 12}}/>
                  </span>
                  <span>$44,727.80</span>
                  <span>$43,318.64</span>
                  <span>
                    <TableButton
                      buttonText='Trade'
                      buttonIcon={<LinkIcon width={11}/>}
                      onClick={redirectToTradeHistory}
                    />
                    <TableButton
                      buttonText='Convert'
                      buttonIcon={<LinkIcon width={11}/>}
                      onClick={redirectToConvertHistory}
                    />
                  </span>
                </div>
                )
              )) : (
                // loading rows here
                null
              )
            }
          </div>

        </div>

      </div>

      {/* right section container */}
      <div className={Styles.rightSectionContainer}>
        <QuickBuySell />
        <Convert />
      </div>

    </div>
  )
}
export default injectIntl(markets)