import React, { useState, useContext } from 'react'

// react router
import { useHistory } from 'react-router-dom'

// utilities
import _, { isEmpty } from 'lodash'

// context
import { ThemeContext } from '../../../../context/ThemeContext'

// translations
import { FormattedMessage, injectIntl } from "react-intl"

// redux
import { setUser } from "../../redux/slices/userDataSlice"
import { _login } from '../../redux/actions'
import { useDispatch } from "react-redux"

// styles
import Styles from './passwordRecovery.module.css'

// photos
import illustrationPhoto from '../../assets/images/login-signup-illustration.png'

// components
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form } from 'reactstrap'
import Button from '../../../../components/Button/Button'

// validation
import { useForm } from 'react-hook-form'


const PasswordRecovery = () => {
  const [email, setEmail] = useState('')
  const { errors, handleSubmit, control } = useForm()
  const theme = useContext(ThemeContext).data.theme
  // console.log(theme)
  const dispatch = useDispatch()
  const history = useHistory()

  document.title = "Wenbit: Recover Password"
  const _loginSuccessCallback = (res) => {
    // console.log(res)

    if (res?.email) {
      dispatch(setUser(res))
      history.push('/trade')
      toast(
        <FormattedMessage id={"success.password.forgot"} />
      )
      // const {data} = res
      // // _setAPIToken(data.token)
      // sessionStorage.setItem("AUTH_TOKEN", JSON.stringify({email, token: data.token, user: data.user}))
      // if (rememberMe) {
      //   localStorage.setItem("AUTH_TOKEN", JSON.stringify({email, token: data.token}))
      // }
      // if (data.user.abilities) {
      //   // ability.update(data.user.abilities)
      // }
      // history.push('/')
    }
    // else if (res.next_step_code === 'USR_CHANGE_PASSWORD') {
    //   history.push(`/reset-password/${email}`)
    // } else if (res.next_step_code === 'USR_2FA') {
    //   // setTwoFactorModal({twoFactorModalShow: true, twoFactorModalData: res.data})
    // }
  }
  // const userData = useSelector((state) => state.userData?.value)

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      _login(
        {
          email
        },
        (data) => {
          _loginSuccessCallback(data)
        },
        (error) => {
          console.log(error.errors)
          toast.error(
            <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
          )
        }
      )
    }
  }

  return (
    <div className={Styles.pageContainer}>

      {/* illustration container */}
      <div className={Styles.illustrationContainer}>
        <img
          src={illustrationPhoto}
        />
      </div>

      {/* form container */}
      <div className={Styles.formContainer}>

        {/* logo */}
        <img
          src='https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg'
          onClick={() => history.replace('/trade')}
          style={{ cursor: 'pointer' }}
        />

        {/* form */}
        <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>

          {/* title */}
          <span className={Styles.title}>Password Recovery</span>

          {/* Details */}
          <span className={Styles.detailsSpan}>Enter your email to recover your password</span>
          {/* mail input */}
          <input
            className={Styles.mailInput}
            placeholder='example@mail.com'
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          {/* sign in button */}
          <Button
            text='Reset Password'
            type='submit'
            style={{ marginTop: 30 }}
          />
        </Form>
      </div>

    </div>
    // <>
    //   <div className="vh-100 d-flex justify-content-center">
    //     <div className="form-access my-auto">
    //       <Form action='/' onSubmit={handleSubmit(onSubmit)}>
    //         <span><FormattedMessage id={"page.header.navbar.signIn"} /></span>
    //         <FormGroup className="form-group">
    //           <Input
    //             autoFocus
    //             style={theme === 'dark' ? { color: "white" } : { color: "black" }}
    //             type='email'
    //             id='email'
    //             name='email'
    //             required
    //             placeholder='Email Address'
    //             onChange={e => {
    //               setEmail(e.target.value)
    //             }}
    //           // className={classnames('input-group-merge', { 'is-invalid': errors['email'] })}

    //           />
    //         </FormGroup>

    //         {/* <FormGroup className="form-group">
    //           <Input id='email' className="form-control" type='email' placeholder='Email Address' required />
    //         </FormGroup> */}

    //         <FormGroup className="form-group">
    //           <Input
    //             style={theme === 'dark' ? { color: "white" } : { color: "black" }}
    //             id='password'
    //             placeholder='Password'
    //             type='password'
    //             name='password'
    //             required
    //             onChange={e => {
    //               setPassword(e.target.value)
    //             }}
    //           // className={classnames('input-group-merge', { 'is-invalid': errors['password'] })}
    //           />
    //         </FormGroup>
    //         {/* <FormGroup className="form-group">
    //           <Input id='password' className="form-control" type='password' placeholder='Password' required />
    //         </FormGroup> */}

    //         <div className="text-right">
    //           <Link to="/reset"><FormattedMessage id={"page.header.signIn.forgotPassword"} /></Link>
    //         </div>
    //         <div className="custom-control custom-checkbox">
    //           <input
    //             type="checkbox"
    //             className="custom-control-input"
    //             id="form-checkbox"
    //             onChange={e => setRememberMe(e.target.checked)}
    //           />
    //           <label className="custom-control-label" htmlFor="form-checkbox">
    //             Remember me
    //           </label>
    //         </div>
    //         <Button color='primary' type="submit" className="btn btn-primary">
    //         <FormattedMessage id={"page.header.navbar.signIn"} />
    //         </Button>
    //       </Form>
    //       <h2>
    //       <FormattedMessage id={"page.signIn.signupExists"} /> <Link to="/signup"><FormattedMessage id={"page.signIn.signup"} /></Link>
    //       </h2>
    //     </div>
    //   </div>
    // </>
  )
}
export default injectIntl(PasswordRecovery)