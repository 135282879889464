import React, { useEffect, useState, useContext } from "react"

// react router
import { useHistory, useParams } from "react-router-dom"

// styles
import Styles from "./marketsList.module.css"

// icons
import { FiSearch } from "react-icons/fi"
import { ReactComponent as InfoIcon } from '../../../../assets/icons/imported/info.svg'

// theme
import { ThemeContext } from "../../../../context/ThemeContext"

// redux
import { useDispatch, useSelector } from "react-redux"
import {
  _getCurrencies,
  _getMarketsList,
  _getMarketTickers
} from "../../redux/actions"
import { setSelectedMarket } from "../../redux/slices/selectedMarketSlice"
import { setMarketTickers } from "../../redux/slices/marketTickersSlice"
import { setMarketsList } from "../../redux/slices/marketListSlice"
import { clearOrderBook } from "../../redux/slices/orderBookSlice"
import { clearTrades } from "../../redux/slices/tradesSlice"
import { setPreviousMarket } from "../../redux/slices/previousMarketSlice"
import { setCurrencies } from "../../redux/slices/currenciesSlice"
import { FormattedMessage, injectIntl } from "react-intl"

// components
import MarketRow from "./MarketRow"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import ShimmerCirlce from "../../../../components/shimmer-components/ShimmerCircle"

function MarketsList() {
  const theme = useContext(ThemeContext).data

  //console.log(theme)
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const marketList = useSelector((state) => state.marketList.value)
  const tickers = useSelector((state) => state.marketTickers.value)
  const currencies = useSelector((state) => state.currencies.value)
  const [filteredMarketList, setFilteredMarketList] = useState([])
  // const marketTickers = useSelector((state) => state.marketTickers.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  // const [currencies, setCurrencies] = useState([])
  //const theme = 'dark'
  const defaultTicker = {
    last: 0,
    vol: 0,
    price_change_percent: "+0.00%"
  }
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const { marketName } = useParams()

  const handleSearchChange = (event) => {
    const searchInput = event.target.value
    setFilteredMarketList(
      marketList.filter(
        (pair) =>
          pair.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          pair.id?.toLowerCase().includes(searchInput.toLowerCase()) || 
          pair.coin_name.toLowerCase().includes(searchInput.toLowerCase())
      )
    )
  }

  const changeCurrency = (currency) => {
    dispatch(clearOrderBook())
    dispatch(clearTrades())
    setSelectedMarket(currency)
    dispatch(setSelectedMarket(currency))

    history.replace(currency.id)
  }

  useEffect(() => {
    _getMarketsList((markets) => {
      //set selected market as first result and push to path
      if (
        marketName === "" ||
        marketName === null ||
        marketName === undefined
      ) {
        setSelectedMarket({
          id: markets[0]?.id,
          name: markets[0]?.name
        })
        dispatch(
          setSelectedMarket({
            id: markets[0]?.id,
            name: markets[0]?.name
          })
        )
        history.push(`/trade/${markets[0]?.id}`)
      } else {
        setSelectedMarket({
          id: marketName,
          name: markets.find((market) => market.id === marketName).name
        })
        dispatch(
          setSelectedMarket({
            id: marketName,
            name: markets.find((market) => market.id === marketName).name
          })
        )
        history.push(`/trade/${marketName}`)
      }
      _getMarketTickers((tickers) => {
        let marketsMapped = markets?.map((market) => {
          return {
            ...market,
            last: (tickers[market.id] || defaultTicker).ticker.last,
            price_change_percent: (tickers[market.id] || defaultTicker).ticker
              .price_change_percent,
            price_change_percent_num: Number.parseFloat(
              (tickers[market.id] || defaultTicker).ticker.price_change_percent
            ),
            vol: (tickers[market.id] || defaultTicker).ticker.volume
          }
        })
        _getCurrencies((currenciesArray) => {
          setCurrencies(currenciesArray)
          marketsMapped = marketsMapped.map((market) => {
            const targetedMarket = currenciesArray.find(
              (el) => market.base_unit?.toLowerCase() === el.id?.toLowerCase()
            )
            return {
              ...market,
              icon_url: targetedMarket.icon_url,
              coin_name: targetedMarket.name
            }
          })
          dispatch(setMarketsList(marketsMapped))
          setLoading(false)
          setFilteredMarketList(marketsMapped)
        })
      })
    })
  }, [])

  useEffect(() => {
    setFilteredMarketList(
      filteredMarketList?.map((market) => {
        return {
          ...market,
          last: (tickers[market.id] || defaultTicker).last,
          price_change_percent: (tickers[market.id] || defaultTicker)
            .price_change_percent,
          price_change_percent_num: Number.parseFloat(
            (tickers[market.id] || defaultTicker).price_change_percent
          ),
          vol: (tickers[market.id] || defaultTicker).volume
        }
      })
    )
  }, [tickers])

  return (
    <div className={Styles.card}>
      {/* Search Coin Pair */}
      <div className={Styles.searchMarketPairContainer}>
        <FiSearch className={Styles.searchMarketPairIcon} size={18}/>
        <input placeholder="Search Coin Pair" onChange={handleSearchChange} />
      </div>

      {/* Table Header */}
      <div className={Styles.marketPairTableHeader}>
      <span>
          <FormattedMessage id={"page.body.trade.header.markets.content.market"} />
        </span>        
        <span>
          <FormattedMessage id={"page.body.trade.header.markets.content.last_price"} />
        </span>
      </div>

      {/* Table Rows Container */}
      <div
        className={Styles.rowsContainer}
        style={{ overflowY: !filteredMarketList?.length && "hidden" }}
      >
        {!loading && tickers
          ? filteredMarketList?.length !== 0 ?
            filteredMarketList?.map((pair) => {
              return (
                <MarketRow
                  key={pair.name}
                  iconURL={pair.icon_url}
                  pair={pair}
                  isSelected={pair.id === marketName}
                  onClick={() => {
                    dispatch(setPreviousMarket(selectedMarket.id))
                    changeCurrency({
                      id: pair.id,
                      name: pair.name
                    })
                  }}
                />
              )
            }) : (
              <div className={Styles.NotFoundContainer}>
                <InfoIcon />
                Pair Not Found
              </div>
            )
          : items.map((number) => (
              <div className={Styles.loadingRowContainer} key={number}>
                <div>
                  <ShimmerCirlce />
                  <ShimmerLine width="30px" height="15px" />
                </div>

                <div>
                  <ShimmerLine width="70px" />
                  <ShimmerLine width="32px" />
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}

export default injectIntl(MarketsList)
