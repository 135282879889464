import React from 'react'

// MUI
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'


// styles
import Styles from './selectDropdown.module.css'


const listStyles = {
  formControl: {
    minWidth: 120,
    '& .MuiSelect-select': {
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '10px',
        padding: '8px',
        fontSize: '12px'
    },
    '& .MuiSelect-select:focus': {
        // border: '1.45px var(--primary-color) solid',
        // border: 'none',
        outline: 'none'
    },
    '& .MuiSelect-icon': {
      color: '#333'
    }
  }
}


function SelectDropdown() {

    const [age, setAge] = React.useState('')

    const handleChange = (event) => {
        setAge(event.target.value)
    }

  return (
    <div>
      <FormControl sx={listStyles.formControl}>
        <Select
          value={age}
          onChange={handleChange}
          autoWidth
          MenuProps={{
            PaperProps: {
                style: {
                    backgroundColor: 'black',
                    color: 'var(--gray-color)',
                    maxHeight: '200px'
                }
            }
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Twenty</MenuItem>
          <MenuItem value={21}>Twenty one</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
          <MenuItem>Twenty one and a half</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectDropdown