import React from 'react'
import { useSelector } from 'react-redux'
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import OrdersHistory from './OrdersHistory'
import DepositHistory from './DepositHistory'
import WithdrawalHistory from './WithdrawalHistory'
import TradesHistory from './TradesHistory'
import ConversionHistory from './ConversionHistory'
import { FormattedMessage, injectIntl } from "react-intl"

const profile = () => {
  document.title = "Wenbit: Profile"
  const data = useSelector(state => state.userData?.value)

  return (
    <>
      <div className="settings mtb15">
        <div className="container-fluid">
          <Tab.Container defaultActiveKey="orders">
            <Row>
              <Col lg={3}>
                <Nav variant="pills" className="settings-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="orders"><FormattedMessage id='page.header.navbar.openOrders' /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="deposit"><FormattedMessage id='page.body.history.deposit' /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="withdraw"><FormattedMessage id='page.body.history.withdraw' /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="trade"><FormattedMessage id='page.body.history.trade' /></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="conversion"><FormattedMessage id='page.body.history.conversion' /></Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="orders">
                    <OrdersHistory />
                  </Tab.Pane>
                  <Tab.Pane eventKey="deposit">
                    <DepositHistory />
                  </Tab.Pane>
                  <Tab.Pane eventKey="withdraw">
                    <WithdrawalHistory />
                  </Tab.Pane>
                  <Tab.Pane eventKey="trade">
                    <TradesHistory />
                  </Tab.Pane>
                  <Tab.Pane eventKey="conversion">
                    <ConversionHistory />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  )
}
export default injectIntl(profile)