import React from 'react'

// styles
import Styles from './SideNavButton.module.css'

function SideNavButton({
    text,
    icon,
    onClick = () => {},
    isSelected = false,
    style,
    ...rest
}) {
  return (
    <button onClick={onClick} className={`${Styles.sideNavButton} ${isSelected ? Styles.selected : ''}`} {...rest} style={style}>
        {icon}
        <span>{text}</span>
    </button>
  )
}

export default SideNavButton