import React from 'react'

// styles
import Styles from './coinTag.module.css'

function CoinTag({
    tag,
    size = 11.5,
    style
}) {
  return (
    <div className={Styles.tag} style={{fontSize: size, ...style}}>{tag}</div>
  )
}

export default CoinTag