import { createSlice } from '@reduxjs/toolkit'

export const selectedMarketSlice = createSlice({
  name: 'selectedMarket',
  initialState: {
    value: ''
  },
  reducers: {
    setSelectedMarket: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSelectedMarket } = selectedMarketSlice.actions

export default selectedMarketSlice.reducer