import React from 'react'

// styles
import Styles from './tableButton.module.css'

function TableButton({
    buttonIcon,
    buttonText,
    style,
    onClick,
    ...rest
}) {
  return (
    <button className={Styles.button} onClick={onClick} style={style} {...rest}>
        {buttonText}
        {buttonIcon}
    </button>
  )
}

export default TableButton