import React from 'react'

// styles
import Styles from './kycStatus.module.css'

// icons
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/imported/back-arrow.svg'
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/imported/checkmark.svg'

// components
import Button from '../../../../components/Button/Button'

function KYCStatus({
    status // verify, verified, pending
}) {
  return (
    <div
        className={Styles.card}
    >
        {
            status === 'verify' && (
                <>
                    <div className={Styles.iconContainer}>

                        {/* icon */}
                        <LeftArrowIcon
                            color='var(--primary-color)'
                            style={{
                                transform: 'rotateZ(180deg)'
                            }}
                        />
                    </div>

                    {/* title */}
                    <span className={Styles.statusTitle} style={{color: 'var(--primary-color)'}}>START YOUR KYC PROCESS</span>

                    {/* veriy button */}
                    <Button
                        text='Verify your KYC'
                        style={{width: 'fit-content'}}
                    />
                </>
            )
        }

        {
            status === 'verified' && (
                <>
                    <div className={Styles.iconContainer}>

                        {/* icon */}
                        <CheckmarkIcon
                            color='var(--primary-color)'
                        />
                    </div>

                    {/* title */}
                    <span className={Styles.statusTitle} style={{color: 'var(--primary-color)'}}>VERIFIED</span>

                    {/* verified info container */}
                    <div className={Styles.verifiedInfoContainer}>
                        
                        <span>
                            <div>
                                <CheckmarkIcon
                                    color='var(--primary-color)'
                                    width={9}
                                />
                            </div>
                            Personal information
                        </span>

                        <span>
                            <div>
                                <CheckmarkIcon
                                    color='var(--primary-color)'
                                    width={9}
                                />
                            </div>
                            Government-issued ID
                        </span>

                        <span>
                            <div>
                                <CheckmarkIcon
                                    color='var(--primary-color)'
                                    width={9}
                                />
                            </div>
                            Facial recognition
                        </span>

                    </div>
                </>
            )
        }

        {
            status === 'pending' && (
                <>
                    <div className={Styles.iconContainer} style={{color: 'orange', fontWeight: 'bolder', fontSize: 30}}>

                        {/* icon */}
                        !
                    </div>

                    {/* title */}
                    <span className={Styles.statusTitle} style={{color: 'orange'}}>PENDING FOR VERIFICATION</span>

                    <span className={Styles.pendingMessage}>
                        Loremum is simply dummy text of the printing & typesetting. 
                    </span>
                </>
            )
        }
    </div>
  )
}

export default KYCStatus