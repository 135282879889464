import { API } from '../../../../utility/API'
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"
// import { persistStore } from 'redux-persist'
//************************************//
export const _login = (data, callback, callbackErr) => {
    API.post(`authenticate/identity/sessions`, data)
        .then(response => {
            // const cookieHeader = response.headers.get('set-cookie')
            // console.log(cookieHeader)
            // if (response && response.data) {
            // const sessionId = response.data.sessionId
            // // Use sessionId to update the expiry date
            // }
            if (response.data) {
                callback(response.data)
            }
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _logout = (callback, callbackErr) => {
    // persistStore().purge()
    API.delete(`authenticate/identity/sessions`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _register = (data, callback, callbackErr) => {
    API.post(`authenticate/identity/users`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//**********************************//
export const _wallets = (callback, callbackErr) => {
    API.get('trading/account/balances')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//********************************//
export const _activities = (callback, callbackErr) => {
    API.get('authenticate/resource/users/activity/all?limit=7&page=1')
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
