import React from 'react'
import BlogCard from '../components/BlogCard'

// styles
import Styles from './blogs.module.css'

function Blogs() {
  return (
    <div className={Styles.pageContainer}>
        
        {/* page header */}
        <div className={Styles.pageHeader}>
            <span>Blog & Articles</span>
            <h1>
                Stay Ahead of the Curve <br />
                with our blog section
            </h1>
        </div>

        <div className={Styles.gridContainer}>
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
        </div>

    </div>
  )
}

export default Blogs