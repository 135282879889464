import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'userData',
  initialState: {
    value: {},
    wallets: [],
    activities: []
  },
  reducers: {
    setUser: (state, payload) => {
      state.value = payload.payload
    },
    logoutUser: (state, payload) => {
      state.value = {},
      state.wallets = {},
      state.activities = {}
    },
    setWallets: (state, payload) => {
      state.wallets = payload.payload
    },
    setActivities: (state, payload) => {
      state.activities = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser, logoutUser, setWallets, setActivities } = userSlice.actions

export default userSlice.reducer
