import React, { useState, useEffect } from 'react'

// react router
import { Link } from 'react-router-dom'

// styles
import Styles from './accountActivities.module.css'

// components
import DatatableHeader from '../../../../components/Datatable/DatatableHeader'
import WenbitModal from '../../../../components/Modal/Modal'

import { _getActivity } from '../../redux/actions'
function AccountActivitesTab() {
    const [data, setData] = useState(null)
    const [userAgent, setUserAgent] = useState(null)
    const [page, setPage] = useState(1)
    const [detailsModalOpen, setDetailsModalOpen] = useState(false)

    const handleViewDetails = () => {
        setDetailsModalOpen(true)
    }

    useEffect(() => {
        _getActivity(page,
            (data) => {
                console.log(data)
                setData(data)
            }
        )
    }, [])

    const dateTime = (isoString) => {
        const dateObj = new Date(isoString)
        const date = dateObj.toISOString().slice(0, 10)
        const time = dateObj.toISOString().slice(11, 19)
        return { date, time }
    }

    return (
        <div className={Styles.tabContainer}>

            {/* VIEW DETAILS MODAL */}
            <WenbitModal
                title='Browser Details'
                showCloseButton={true}
                open={detailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
                render={() => (
                    <div>
                        {/* {userAgent} */}
                        Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36
                    </div>
                )}
            />

            <h5>Account Activites</h5>

            <DatatableHeader
                headerColumnArray={[
                    { title: 'Date', flex: .3 },
                    { title: 'Action', flex: .15 },
                    { title: 'Result', flex: .15 },
                    { title: 'Address', flex: .2 },
                    { title: 'User Agent', flex: .2 }
                ]}
            />

            {/* {
                data?.length > 0 ? (
                    data?.map((activity) => (
                        <div className={Styles.row}>
                            <div>
                                <span>{dateTime(activity.created_at).date}</span>
                                <span>{dateTime(activity.created_at).time}</span>
                            </div>
                            <div>{activity?.action}</div>
                            <div>{activity?.result.toUpperCase()}</div>
                            <div>{activity?.user_ip}</div>
                            <div>
                                <Link onClick={e => {
                                    handleViewDetails()
                                    setUserAgent(activity?.user_agent)
                                }}>
                                    View Details
                                </Link>
                            </div>
                        </div>
                    ))) : null
            } */}

            {
                new Array(7).fill(0).map(() => (
                        <div className={Styles.row}>
                            <div>
                                <span>2023-02-10</span>
                                <span>09:13:58</span>
                            </div>
                            <div>Login</div>
                            <div>Succeed</div>
                            <div>78.108.174.211</div>
                            <div>
                                <Link onClick={e => {
                                    handleViewDetails()
                                    // setUserAgent(activity?.user_agent)
                                }}>
                                    View Details
                                </Link>
                            </div>
                        </div>
                ))
            }
        </div>
    )
}

export default AccountActivitesTab