import * as React from "react"
import BigNumber from "bignumber.js"

/**
 *  App Decimal that formats values
 */

class Decimal extends React.Component {
    constructor(props) {
        super(props)
        /**
         * function that opens dropdown list
         */

        this.highlightNumbers = function (t, e) {
            for (var r = "", n = t.length - 1, a = t.length - e; n >= a; n--)
                "." === t[n] && ((r = "." + r), n--, a--), (r = t[n] + r)
            return (
                <React.Fragment>
                    <span className="base-decimal__opacity">
                        {t.slice(0, t.length - r.length)}
                    </span>
                    <span>{r}</span>
                </React.Fragment>
            )
        }
    }

    static format(t, e) {
        return BigNumber(t).toFixed(e).toString()
        // let r = 0 === t ? "0" : BigNumber(t).toFixed(e).toString() // let r = void 0 === t ? "0" : (Math.floor(+t * Math.pow(10, e)) / Math.pow(10, e)).toString()

        // for (
        //     -1 === r.indexOf(".") && (r += ".");
        //     r.slice(r.indexOf(".")).length <= e;

        // )
        //     r += "0"

        // return r
    }

    static getNumberBeforeDot = function (t, r) {
        const n = Decimal.format(t, r)
        return Math.floor(+n)
    }
    static getNumberAfterDot = function (t, r) {
        if (0 !== r) {
            const n = Decimal.format(t, r)
            return n.slice(n.indexOf("."))
        }
    }

    render() {
        const t = this.props,
            r = t.children,
            n = t.fixed
        const numberAfterDot = this.constructor.getNumberAfterDot(r, n)
        const lastTwo =
            (this.props.fromOrderBooks &&
                (numberAfterDot || []).slice(
                    (numberAfterDot || []).length - 2
                )) ||
            numberAfterDot
        const numberBeforeLastTwo =
            (this.props.fromOrderBooks &&
                (numberAfterDot || []).slice(
                    0,
                    (numberAfterDot || []).length - 2
                )) ||
            null
        const lastTwoNumbersClass = this.props.fromOrderBooks
            ? "base-order-book_last-two-number-before-dot"
            : "base-decimal__opacity"
        const numberBeforeLastTwoClass =
            this.props.fromOrderBooks &&
            (this.props.shouldHighlight ||
                ((numberAfterDot || []).length === 3 && lastTwo === "00"))
                ? "base-order-book_number-all_bold"
                : this.props.fromOrderBooks
                ? "base-order-book_number-low_opacity"
                : ""
        return this.props.highlightedNumbersAmount ? (
            this.highlightNumbers(
                Decimal.format(r, n),
                this.props.highlightedNumbersAmount
            )
        ) : (
            <React.Fragment>
                <span className={numberBeforeLastTwoClass}>
                    {this.constructor.getNumberBeforeDot(r, n)}
                </span>
                <span className={numberBeforeLastTwoClass}>
                    {numberBeforeLastTwo}
                </span>
                <span className={lastTwoNumbersClass}>{lastTwo}</span>
            </React.Fragment>
        ) // React.createElement("span", {className: "base-decimal__opacity"}, this.constructor.getNumberAfterDot(r, n)))
    }
}

export { Decimal }
