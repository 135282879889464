import React from 'react'

// styles
import Styles from './KYCVerificationTab.module.css'

// components
import KYCStatus from './KYCStatus'


function KYCVerificationTab() {
  return (
    <div className={Styles.tabContainer}>
        <h5>KYC Verification</h5>

        {/* tab header */}
        <div className={Styles.tabHeader}>
            <div>
                <span>Full Name</span>
                <span>JAMAL HUSSEIN</span>
            </div>
            <div>
                <span>UID</span>
                <span>UID: ID78D1129765</span>
            </div>
        </div>

        {/* KYC Status container */}
        <div className={Styles.KYCStatusContainer}>
            <KYCStatus status='pending'/>
        </div>

        <div className={Styles.tabFooter}>
            This account was created on February 10, 2023, 02:12 PM
        </div>
    </div>
  )
}

export default KYCVerificationTab