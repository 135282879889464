import React from 'react'

// styles
import Styles from './search.module.css'

// icons
import { FiSearch } from 'react-icons/fi'

function Search({
  placeholder,
  containerStyle,
  ...rest
}) {
  return (
    <div className={Styles.searchMarketPairContainer} style={{...containerStyle}}>
        <FiSearch className={Styles.searchMarketPairIcon}/>
        <input
          placeholder={placeholder}
          // onChange={handleSearchChange}
          {...rest}
        />
    </div>
  )
}

export default Search