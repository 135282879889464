import React from 'react'

// react router
import { useHistory } from 'react-router-dom'

// styles
import Styles from './kycReminderModal.module.css'

// icons
import { ReactComponent as WarningIcon } from '../../../assets/icons/imported/alert.svg'

// components
import WenbitModal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'

function KYCReminderModal({
    open = false,
    handleClose
}) {

    const history = useHistory()

  return (
    <>
        <WenbitModal
            open={open}
            showCloseButton={true}
            onClose={handleClose}
            render={() => (
                <div className={Styles.bodyContainer}>
                    <WarningIcon
                        color='var(--primary-color)'
                        height={55}
                        width={55}
                    />
                    <span className={Styles.title}>Reminder</span>
                    <span className={Styles.description}>Please click here to complete the KYC</span>

                    <Button
                        text='Verify Now'
                        style={{width: 'fit-content'}}
                        onClick={() => {
                            handleClose()
                            history.replace('/kyc')
                        }}
                    />
                </div>
            )}
        />
    </>
  )
}

export default KYCReminderModal