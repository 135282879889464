import React, { useState, useContext, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import { Form, Input, Label, FormFeedback, Button } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { _login } from '../../redux/actions'
// import classnames from 'classnames'
// import FormGroup from '@mui/material/FormGroup'
import _, { isEmpty } from 'lodash'
import { ThemeContext } from '../../../../context/ThemeContext'
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../redux/slices/userDataSlice"
import { FormattedMessage, injectIntl } from "react-intl"
import { toast } from 'react-toastify'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import 'react-toastify/dist/ReactToastify.css'
import { Form } from 'reactstrap'
import Styles from './twoFA.module.css'

import illustrationPhoto from '../../assets/images/login-signup-illustration.png'
import Button from '../../../../components/Button/Button'


const login = () => {
  const [selectedOption, setSelectedOption] = useState('message')
  const [email, setEmail] = useState('')
  const { errors, handleSubmit, control } = useForm()
  const theme = useContext(ThemeContext).data
  // console.log(theme)
  const dispatch = useDispatch()
  const history = useHistory()

  document.title = "Wenbit: Login"
  const _loginSuccessCallback = (res) => {

    if (res?.email) {
      dispatch(setUser(res))
      history.push('/trade')
    }
  }

  const onSubmit = (data) => {
    if (isEmpty(errors)) {
      _login(
        {
          email
        },
        (data) => {
          _loginSuccessCallback(data)
        },
        (error) => {
          console.log(error.errors)
          toast.error(
            <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
          )
        }
      )
    }
  }
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }
  return (
    <div className={Styles.pageContainer}>

      {/* illustration container */}
      <div className={Styles.illustrationContainer}>
        <img
          src={illustrationPhoto}
          />
      </div>

      {/* form container */}
      <div className={Styles.formContainer}>

        {/* logo */}
        <img
          src='https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg'
          onClick={() => history.replace('/trade')}
          style={{ cursor: 'pointer' }}
        />

        {/* form */}
        <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>

          {/* title */}
          <span className={Styles.title}>Let's Confirm it's really you</span>

          {/* mail input */}
          <label>
        <input
          className={Styles.radioInput}
          type="radio"
          value="message"
          checked={selectedOption === 'message'}
          onChange={handleOptionChange}
        />
        <span className={Styles.label}>Send by message</span>
      </label>

      <label>
        <input
          className={Styles.radioInput}
          type="radio"
          value="email"
          checked={selectedOption === 'email'}
          onChange={handleOptionChange}
        />
        <span className={Styles.label}>Send by email</span>
      </label>
      <div>
        {selectedOption === 'message' ? (
          <input type="text" placeholder="Enter phone number" className={Styles.mailInput} />
        ) : (
          <input type="email" placeholder="Enter email address" className={Styles.mailInput} />
        )}
      </div>
          {/* sign in button */}
          <Button
            text='Continue'
            type='submit'
            style={{ marginTop: 30 }}
          />

        </Form>
      </div>

    </div>
  )
}
export default injectIntl(login)