import React from 'react'

// icon
import { BiEditAlt } from 'react-icons/bi'
import { ReactComponent as MailIcon } from '../../../../assets/icons/imported/mail.svg'
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/imported/phone.svg'

// styles
import Styles from './profileTab.module.css'

// components
import Input from '../../../../components/Input/Input'
import SelectInput from '../../../../components/SelectInput/SelectInput'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'

const inputStyle = {
  // backgroundColor: 'black',
  fontSize: 13
}

const inputContainerStyle = {
  margin: '20px 0',
  flex: .5
}

const inputIconContainerStyle = {
  backgroundColor: 'black'
}

function ProfileTab({userData}) {
  return (
    <div className={Styles.tabContainer}>
      
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <h4>My Profile</h4>
        <button>
          <BiEditAlt size={20}/>
          Edit
        </button>
      </div>

      {/* personal information */}
      <div className={Styles.formSection}>
        <p>Personal Information</p>
        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</span>

        {/* first/last name inputs container */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20
        }}>
          <Input
            inputLabel='First Name'
            style={inputStyle}
            containerStyle={inputContainerStyle}
            inputIconContainerStyle={inputIconContainerStyle}
            value='James'
          />
          <Input
            inputLabel='Last Name'
            style={inputStyle}
            containerStyle={inputContainerStyle}
            inputIconContainerStyle={inputIconContainerStyle}
            value='Gordon'
          />
        </div>
      </div>

      {/* contact information */}
      <div className={Styles.formSection}>
        <p>Contact Information</p>
        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</span>

        {/* email/number inputs container */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20
        }}>
          <Input
            inputLabel='Email'
            style={inputStyle}
            containerStyle={inputContainerStyle}
            inputIconContainerStyle={inputIconContainerStyle}
            value={userData?.email}
            icon={<MailIcon width={18}/>}
          />
          <Input
            inputLabel='Phone Number'
            style={inputStyle}
            containerStyle={inputContainerStyle}
            inputIconContainerStyle={inputIconContainerStyle}
            value={userData?.phones?.length && userData?.phones[0]}
            icon={<PhoneIcon width={17}/>}
          />
        </div>

        {/* language/currency inputs container */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20
        }}>
          {/* <Input
            type='select'
            inputLabel='language'
            style={inputStyle}
            containerStyle={inputContainerStyle}
            inputIconContainerStyle={inputIconContainerStyle}
            value={userData?.phones?.length && userData?.phones[0]}
          /> */}
        </div>
      </div>

    </div>
  )
}

export default ProfileTab