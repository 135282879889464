import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './Footer/Footer'
import Header from './Header/Header'

export default function Layout({ children }) {

  const location = useLocation()

  const routesToHideHeader = ['/login', '/signup', '/recover-password']
  const routesToHideFooter = ['/login', '/signup', '/recover-password', '/trade']

  return (
    <>
      {
        (!routesToHideHeader.includes(location.pathname)) && <Header />
      }
      {<div style={{
        paddingTop: !routesToHideHeader.includes(location.pathname) && 95 //header height
      }}>{children}</div>}
      {
        (!routesToHideFooter.includes(location.pathname)) && <Footer />
      }
    </>
  )
}
