import React from 'react'
import FooterLink from './FooterLink'

// styles
import Styles from './footerSection.module.css'

function FooterSection({
    title,
    links
}) {
  return (
    <div className={Styles.footerSectionContainer}>
        <h1>{title}</h1>

        <div className={Styles.linksContainer}>
            {
                links.map((link, index) => (
                    <FooterLink
                        key={index}
                        to={link.url}
                        text={link.text}
                    />
                    ))
            }
            </div>
    </div>
  )
}

export default FooterSection