import React from 'react'

// styles
import Styles from './languageCurrencySelector.module.css'

// icons
import { ReactComponent as SunIcon } from '../../assets/icons/imported/sun.svg'
import { ReactComponent as MoonIcon } from '../../assets/icons/imported/moon.svg'
import { ReactComponent as GlobalIcon } from '../../assets/icons/imported/global.svg'

// components
import Select, { components } from 'react-select'
import SelectInput from '../SelectInput/SelectInput'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from '../../redux/slices/languageSlice'

function LanguageCurrencySelector() {
    const dispatch = useDispatch()

    const handleSelect = (lang) => {
        dispatch(setLanguage(lang))
    }
    // language options
    const languageOptions = [
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Española' },
        { value: 'ar', label: 'عربي' },
        { value: 'ru', label: 'Русский' },
        { value: 'ur', label: 'اردو' },
        { value: 'zh', label: '中国人' }
    ]

    const currencyOptions = [
        { value: 'usd', label: 'USD' },
        { value: 'eur', label: 'EUR' },
        { value: 'gbp', label: 'GBP' }
    ]

  return (
    <div className={Styles.mainContainer}>

        {/* language selector */}
        <div className={Styles.languageSelectorContainer}>
            <GlobalIcon width={13} style={{
                transform: 'translateX(24px)'
            }}/>
            <SelectInput
                options={languageOptions}
                defaultValue={languageOptions[0]}
                isSearchable={false}
                onChange={(data) => handleSelect(data.value)}
                singleValueStyle={{
                    color: '#aeb1bf80',
                    paddingLeft: 16
                }}
                showArrow={false}
            />
        </div>

        <div className={Styles.divider}></div>

        {/* language selector */}
        <div className={Styles.currencySelectorContainer}>
            <SelectInput
                options={currencyOptions}
                defaultValue={currencyOptions[0]}
                isSearchable={false}
                singleValueStyle={{
                    color: '#aeb1bf80'
                }}
                showArrow={false}
            />
        </div>
        <div className={Styles.divider}></div>

        {/* currency selector */}
        <div className={`${Styles.currencySelectorContainer} ${Styles.themeToggleContainer}`}>
            <SunIcon width={17}/>
        </div>
    </div>
  )
}

export default LanguageCurrencySelector