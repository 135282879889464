import React, { useEffect, useState, useMemo } from 'react'

// redux
import { useSelector } from 'react-redux'

// components
import CoinTag from '../../../../components/CoinTag/CoinTag'
import InsightCard from './InsightCard'
import SelectInput from '../../../../components/SelectInput/SelectInput'
import ShimmerCircle from '../../../../components/shimmer-components/ShimmerCircle'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'

// styles
import Styles from './marketInsight.module.css'

// icons
import { TbActivityHeartbeat } from 'react-icons/tb'
import { FaRegClock } from 'react-icons/fa'
import { FiInfo } from 'react-icons/fi'
import { CgArrowsExchangeV } from 'react-icons/cg'
import { ReactComponent as BuyArrowIcon } from '../../../../assets/icons/imported/buy-arrow.svg'
import { ReactComponent as SellArrowIcon } from '../../../../assets/icons/imported/sell-arrow.svg'

function MarketInsight() {

    const selectedMarket = useSelector(state => state.selectedMarket.value)
    const tickers = useSelector((state) => state.marketTickers.value)
    const marketsList = useSelector(state => state.marketList.value)
    const lastTrade = useSelector(state => state.trades.value.slice(-1).reverse()[0])
    const memoizedLastTrade = useMemo(() => lastTrade, [lastTrade])

    const selectedMarketDetails = tickers[selectedMarket.id]
    const [targetedMarket, setTargetedMarket] = useState(marketsList?.find(el => el.id === selectedMarket.id))

    useEffect(() => {
        setTargetedMarket(marketsList?.find(el => el.id === selectedMarket.id))
        console.log(targetedMarket)
    }, [selectedMarket])

    return (
            <div className={Styles.card}>

                {/* left container */}
                <div className={Styles.leftContainer}>

                    <div>
                        {
                            targetedMarket ? (
                                <img
                                    src={targetedMarket?.icon_url}
                                />
                            ) : (
                                <ShimmerCircle
                                    size='50px'
                                />
                            )
                        }

                        {
                            targetedMarket ? (
                                <span>{`${targetedMarket?.coin_name.toUpperCase()}`}</span>
                            ) : (
                                <ShimmerLine
                                    height='18px'
                                    width='50px'
                                />
                            )
                        }

                        {
                            targetedMarket && (
                                <CoinTag
                                    //tag={targetedMarket?.base_unit.toUpperCase()}
                                    tag={targetedMarket?.name.split('/')[0] + '/' + targetedMarket?.name.split('/')[1]}
                                    size={12}
                                />
                            )
                        }
                    </div>

                    <div>
                        <InsightCard
                            titleIcon={<TbActivityHeartbeat color='var(--primary-color)' />}
                            title='24 changes'
                            price='$992,254,233'
                            percentageChange={2.8}
                        />
                        <InsightCard
                            titleIcon={<FaRegClock color='var(--primary-color)' />}
                            title='24 volume (BTC)'
                            price='$826,445,951,378'
                            percentageChange={-12}
                        />
                        <InsightCard
                            titleIcon={<FaRegClock color='var(--primary-color)' />}
                            title='24 changes (USDT)'
                            price='$154,225,445,822'
                            percentageChange={2.8}
                        />
                    </div>

                </div>

                {/* right container */}
                <div className={Styles.rightContainer}>

                    {/* price header */}
                    <div>
                        <div>
                            ${selectedMarketDetails?.last ?? "21.7"}
                            <span style={{ color: selectedMarketDetails?.price_change_percent[0] === '+' ? 'var(--positive-color)' : 'var(--negative-color)' }}>
                                {selectedMarketDetails?.price_change_percent ?? '-0.13%'}
                                { selectedMarketDetails?.price_change_percent[0] === '+' ? <BuyArrowIcon /> : <SellArrowIcon /> }
                            </span>
                        </div>
                        <div>{targetedMarket?.coin_name.charAt(0).toUpperCase() + targetedMarket?.coin_name.slice(1)} Price <FiInfo /></div>
                    </div>

                    {/* time range selector */}
                    <div>
                        <span>
                            <CgArrowsExchangeV size={20} />
                            High/Low Price
                        </span>

                        <div style={{ backgroundColor: 'black', borderRadius: 3.5, width: 'fit-content' }}>
                            <SelectInput
                                options={[
                                    { label: '24h', value: '24h' },
                                    { label: '1h', value: '1h' },
                                    { label: '30min', value: '30min' },
                                    { label: '15min', value: '15min' }
                                ]}
                                defaultValue={{ label: '24h', value: '24h' }}
                            />
                        </div>

                    </div>

                    {/* bar container */}
                    <div>
                        {/* progress bar */}
                        {/* <div style={{ width: (((memoizedLastTrade?.price - selectedMarketDetails?.low) / (selectedMarketDetails?.high - selectedMarketDetails?.low)) * 100) + "%" }}></div> */}
                        <div style={{ width: "73%" }}></div>
                    </div>

                    {/* price bar info */}
                    <div>
                        <span>Low : ${selectedMarketDetails?.low ?? "17.9"}</span>
                        <span>High : ${selectedMarketDetails?.high ?? "22.3"}</span>
                    </div>
                    
                </div>

            </div>
    )
}

export default MarketInsight