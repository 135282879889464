import React, { useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import { logoutUser } from "../../modules/user/redux/slices/userDataSlice"

// components imports
import LanguageCurrencySelector from "./LanguageCurrencySelector"
import NavLink from "./NavLink"
import Button from "../Button/Button"
import TableButton from '../TableButton/TableButton'
import { _logout } from '../../redux/actions'
import { toast } from 'react-toastify'
import { hideEmail } from '../../modules/helpers/HideEmail'

// styles imports
import Styles from "./header.module.css"

// icons imports
import { RxLightningBolt } from "react-icons/rx"
import { BiChevronDown } from "react-icons/bi"
import { FiLogOut, FiSettings, FiUser } from 'react-icons/fi'
import { HiSun, HiMoon } from "react-icons/hi"
import { ReactComponent as CheckboxCircleCheckedIcon } from '../../assets/icons/imported/checkbox-circle-checked.svg'
import { ReactComponent as SettingIcon } from '../../assets/icons/imported/setting.svg'
import { ReactComponent as PortfolioIcon } from '../../assets/icons/imported/portfolio.svg'
import { ReactComponent as LogOutIcon } from '../../assets/icons/imported/logout.svg'
import { ReactComponent as WalletIcon } from '../../assets/icons/imported/wallet.svg'
import { ReactComponent as LinkIcon } from '../../assets/icons/imported/link.svg'
import { ReactComponent as ReturnIcon } from '../../assets/icons/imported/return.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/imported/clock.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/imported/upload.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons/imported/download.svg'

// MUI IMPORTS
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'


function Header() {

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData?.value)
  // profile popup
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // wallet popup
  const [walletPopperOpen, setWalletPopperOpen] = useState(false)
  const [walletAnchorEl, setWalletAnchorEl] = useState(null)
  const handleWalletHover = (event) => {
    setWalletAnchorEl(event.currentTarget)
    setWalletPopperOpen((previousOpen) => !previousOpen)
  }
  const handleWalletLeave = (e) => {
    const { bottom } = e.currentTarget.getBoundingClientRect()
    const { clientX, clientY } = e
    const isOutside = clientY > bottom

    if (isOutside) {
      return
    }
    setWalletAnchorEl(false)
    setWalletPopperOpen(false)
  }
  const canBeOpen = walletPopperOpen && Boolean(walletAnchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  const handleLogout = () => {
    _logout(
      () => {
        dispatch(logoutUser(''))
        setAnchorEl(null)
      },
      (error) => {
        toast.error(
          <FormattedMessage id={error.errors[0]} />
        )
      }
    )
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  function getEmailUserName(email) {
    const emailParts = email.split('@')
    const username = emailParts[0]
    return username
  }

  const exchangeRoutes = ['trade', 'markets', 'launchpad', 'convert', 'history', 'wallet', 'account', 'orders']

  return (
    <header
      className={Styles.headerContainer}
      style={{
        backgroundColor: exchangeRoutes.includes(location.pathname.split('/')[1]) ? 'var(--secondary-color)' : 'black',
        border: exchangeRoutes.includes(location.pathname.split('/')[1]) && 'none'
      }}
    >
      {/* left container */}
      <div className={Styles.leftContainer}>
        {/* <Link to='/trade'> */}
          <img
            className={Styles.headerLogo}
            src="https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg"
            alt="logo"
            onClick={() => history.replace('/')}
          />
        {/* </Link> */}

        {/* divider */}
        <div className={Styles.divider}></div>

        {/* links container */}
        <div className={Styles.linksContainer}>
          <NavLink to="/markets" text={<FormattedMessage id='page.header.navbar.markets' />} isCurrentRoute={location.pathname.startsWith('/markets')} />
          <NavLink to="/trade" text={<FormattedMessage id='page.header.navbar.exchange' />} isCurrentRoute={location.pathname.startsWith('/trade')} />
          <NavLink to="/launchpad" text={<FormattedMessage id='page.header.navbar.launchpad' />} isCurrentRoute={location.pathname.startsWith('/launchpad')}/>
          <NavLink to="/blogs" text={<FormattedMessage id='page.header.navbar.blogs' />} isCurrentRoute={location.pathname.startsWith('/blogs')}/>
          <NavLink to="/convert" text={<FormattedMessage id='page.header.navbar.convert' />} isCurrentRoute={location.pathname.startsWith('/convert')}/>
        </div>
      </div>
      {userData.email ? (

        // right container signed in
        <div className={Styles.rightContainerSignedIn}>

          <LanguageCurrencySelector />

          {/* wallet */}
          <button
            className={`${Styles.headerButtons} ${location.pathname.startsWith("/account/wallet") &&
              Styles.selectedHeaderButton
              }`}
            onMouseEnter={handleWalletHover}
            onMouseLeave={handleWalletLeave}
            // onClick={() => {
            //   history.replace("/account/wallet/spot")
            // }}
          >
            <WalletIcon width={17} style={{ marginRight: 5 }} />
            Wallet
          </button>
          {/* wallet popper */}
          <Popper
            id={id}
            open={canBeOpen}
            anchorEl={walletAnchorEl}
            placement="bottom-start" 
            sx={{
              zIndex: 2
            }}
            onMouseLeave={() => {
              setWalletAnchorEl(false)
              setWalletPopperOpen(false)
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div className={Styles.walletPopperContainer}>

                  {/* header */}
                  <div className={Styles.walletPopperHeader}>
                    <div>
                      <WalletIcon width={17}/>
                      <span>Wallet Balance</span>
                    </div>
                    <TableButton
                      buttonText='View Wallet'
                      buttonIcon={<LinkIcon width={11}/>}
                      onClick={() => history.push('/account/wallet/spot') }
                    />
                  </div>

                  <div className={Styles.walletBalanceContainer}>
                    <span>$32,455.12</span>
                    <span> &#8776; 1.09785432 BTC</span>
                  </div>

                  {/* deposit/withdraw container */}
                  <div className={Styles.depositWithdrawContainer}>
                    <div>
                      <div>
                        <ReturnIcon width={14}/>
                        <span>Total Deposited</span>
                      </div>
                      <div>
                        <DownloadIcon width={11} color='var(--primary-color)'/>
                        <span>$32,455.12</span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <ClockIcon width={14}/>
                        <span>Total withdrawals</span>
                      </div>
                      <div>
                        <UploadIcon width={11} color='var(--primary-color)'/>
                        <span>$2,000.12</span>
                      </div>
                    </div>
                  </div>

                  {/* buttons container */}
                  <div className={Styles.walletPopporButtonsContainer}>
                    <Button
                      text='Deposit'
                    />
                    <Button
                      text='Withdraw'
                      theme='dark'
                    />
                  </div>

                </div>
              </Fade>
            )}
          </Popper>

          {/* user info */}
          <img src={"/img/avatar.svg"} alt="" className={Styles.userPicture}/>
          <span className={Styles.userName} onClick={handleClick}>
            {userData.username ?? getEmailUserName(userData.email)}
            <BiChevronDown />
          </span>

          {/* profile menu */}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'var(--secondary-color)',
              borderRadius: '0 0 5px 5px'
            }
          }}
          MenuListProps={{
            sx: {
              backgroundColor: 'var(--secondary-color)',
              border: '1px (--secondary-color) solid',
              color: '#6C7080',
              zIndex: 1
            }
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              filter: 'drop-shadow(0px 2px 20px rgba(0,0,0,.4))',
              borderRadius: 0,
              mt: 1.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'var(--secondary-color)',
                transform: 'translateY(-50%) rotate(45deg)',
                border: '1px #6C7080 solid',
                zIndex: 1
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <div
            style={{
              fontSize: 11,
              fontFamily: '"Overpass"',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              padding: '6px 36px',
              color: '#AEB1BF'
            }}
          >
            {hideEmail(userData.email)}
            <CheckboxCircleCheckedIcon width={16}/>
            <span style={{
              color: 'var(--primary-color)'
            }}>
              Verified
            </span>
          </div>
          <MenuItem 
            onClick={() => {
              handleClose()
              history.replace('/account/profile')
            }}
            style={{
              fontSize: 13,
              fontFamily: '"Overpass"',
              display: 'flex',
              gap: '6px',
              alignItem: 'center',
              // justifyContent: 'flex-start',
              padding: '8px 36px',
              marginBottom: 2,
              marginTop: 2
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'black',
                borderRight: '2px var(--primary-color) solid',
                color: 'var(--primary-color)'
              }
            }}
          >
            <SettingIcon
              height={17}
              style={{
                marginRight: 10,
                marginLeft: -4
              }}
            />
            <span style={{marginTop: 2}}>My Account</span>
          </MenuItem>

          <MenuItem 
            onClick={() => {
              handleClose()
              history.replace('/account/profile')
            }}
            style={{
              fontSize: 13,
              fontFamily: '"Overpass"',
              display: 'flex',
              gap: '6px',
              alignItem: 'center',
              // justifyContent: 'flex-start',
              padding: '8px 36px',
              marginBottom: 2,
              marginTop: 2
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'black',
                borderRight: '2px var(--primary-color) solid',
                color: 'var(--primary-color)'
              }
            }}
          >
            <PortfolioIcon
              height={17}
              style={{
                marginRight: 10,
                marginLeft: -4
              }}
            />
            <span style={{marginTop: 2}}>Portfolio</span>
          </MenuItem>
          
          <MenuItem 
            onClick={() => {
              handleClose()
              handleLogout()
            }}
            style={{
              fontSize: 13,
              fontFamily: '"Overpass"',
              display: 'flex',
              gap: '6px',
              alignItem: 'center',
              padding: '8px 36px',
              marginBottom: 2,
              marginTop: 2
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'black',
                borderRight: '2px var(--primary-color) solid',
                color: 'var(--primary-color)'
              }
            }}
          >
            <LogOutIcon
              height={17}
              style={{
                marginRight: 10
              }}
            />
            <span style={{marginTop: 2}}>Log out</span>
          </MenuItem>
        </Menu>
        </div>
      ) : (
        // right container logged out
        <div className={Styles.rightContainerLoggedOut}>

            <LanguageCurrencySelector />
            <Button text={<FormattedMessage id='page.header.navbar.signIn' />} theme='dark' onClick={() => { history.replace('/login') }}/>
            <Button text={<FormattedMessage id='page.header.navbar.signUp' />} onClick={() => { history.replace('/signup') }}/>
        </div>
      )}
    </header>
  )
}

export default injectIntl(Header)
