import React from 'react'

// styles
import Styles from './confirmConvertModal.module.css'

// icons
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/imported/back-arrow.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/imported/info.svg'

// components
import WenbitModal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'

function ConfirmConvertModal({
  open,
  handleClose,
  fromCurrency,
  toCurrency
}) {
  return (
    <WenbitModal
        open={open}
        onClose={handleClose}
        title='Confirm'
        showCloseButton={true}
        render={() => (
          <div>

            <div className={Styles.convertRow}>
              <div>
                <img src={fromCurrency?.icon_url}/>
                <span>0.2588103</span>
              </div>
              <span>{fromCurrency?.name}</span>
            </div>

            <BackArrowIcon
              color='var(--primary-color)'
              style={{
                transform: 'rotateZ(-90deg) translateY(7.5px)'
              }}
              height={12.5}
              width={12.5}
            />

            <div className={Styles.convertRow}>
              <div>
                <img src={toCurrency?.icon_url}/>
                <span>0.2588103</span>
              </div>
              <span>{toCurrency?.name}</span>
            </div>

            {/* convert information */}
            <div className={Styles.convertInfoContainer}>
                <div className={Styles.infoRowContainer}>
                  <span>
                    Rate
                    <div className={Styles.infoIconDiv}>
                      i
                    </div>
                  </span>
                  <span>0.257898 BTC = 10,000 USD 10,000</span>
                </div>

                <div className={Styles.infoRowContainer}>
                  <span>Inverse Rate</span>
                  <span>10,000 USD = 0.257898 BTC</span>
                </div>

                <div className={Styles.infoRowContainer}>
                  <span>Payment Method</span>
                  <span>Spot wallet</span>
                </div>

                <div className={Styles.infoRowContainer}>
                  <span>
                    Transaction Fees
                    <div className={Styles.infoIconDiv}>
                      i
                    </div>
                  </span>
                  <span
                    style={{color: 'var(--positive-color)'}}
                  >
                    No Fees
                  </span>
                </div>

                <div className={Styles.infoRowContainer}>
                  <span>You will receive</span>
                  <span>0.257898 BTC</span>
                </div>
            </div>

            <Button
                text='Convert 2s'
            />

          </div>
        )}
    />
  )
}

export default ConfirmConvertModal