import React from 'react'

// MUI imports
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

// styles
import Styles from './modal.module.css'

// icons
import { IoClose } from 'react-icons/io5'
import Handle from 'rc-slider/lib/Handles/Handle'

function WenbitModal({
    open,
    onClose = () => {},
    render = () => {},
    modalWidth = 400,
    showCloseButton = true,
    title
}) {
  return (
    <Modal
        open={open}
        onClose={onClose}
    >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: modalWidth,
                bgcolor: 'var(--secondary-color)',
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
                color: 'white',
                '&:focus': {
                    border: 'none',
                    outline: 'none'
                }
            }}>
                {
                    <div className={Styles.modalHeader}>
                        <h5>{title ? title : ' '}</h5>
                        {
                            showCloseButton && (
                                <IoClose className={Styles.closeButton} onClick={onClose}/>
                            )
                        }
                    </div>   
                }
            
                <div style={{marginTop: title && 20}}>
                    {render()}
                </div>
                
            </Box>
        </Modal>
  )
}

export default WenbitModal