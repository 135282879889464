import React from 'react'

// packages
import Shimmer from "react-shimmer-effect"


function ShimmerCircle({
  size = '25px',
  opacity = '.4',
  style
}) {

  const StyleSheet = {
    circle: {
      height: size,
      width: size,
      borderRadius: "50%"
    }
  }

  return (
    <div style={{
      opacity,
      ...style
    }}>
        <Shimmer>
          <div style={StyleSheet.circle} />
          {/* <div style={StyleSheet.line} /> */}
        </Shimmer>
      </div>
  )
}

export default ShimmerCircle