import React from 'react'

// styles
import Styles from './orderHistoryTab.module.css'

// icons
import { ReactComponent as CalendarIcon } from '../../../assets/icons/imported/calendar.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/imported/filter.svg'
import { ReactComponent as DownArrowIcon } from '../../../assets/icons/imported/down-arrow.svg'
import { ReactComponent as BuyArrowSquare } from '../../../assets/icons/imported/buy-arrow-square.svg'
import { ReactComponent as SellArrowSquare } from '../../../assets/icons/imported/sell-arrow-square.svg'

// components
import NavLink from '../../../components/Header/NavLink'
import Search from '../../../components/Search/Search'
import DatatableHeader from '../../../components/Datatable/DatatableHeader'
import CoinTag from '../../../components/CoinTag/CoinTag'

function OrderHistoryTab() {
  return (
    <div className={Styles.card}>

      {/* tab header */}
      <div className={Styles.tabHeader}>
        <h5>Order History</h5>

        <Search
          placeholder='Search by coin'
        />
      </div>

      {/* tabs container */}
      <div className={Styles.tabsContainer}>
        <NavLink
          text='Open Orders'
          isCurrentRoute={location.pathname === '/account/orders/open'}
          to='/account/orders/open'
        />
        <NavLink
          text='Open History'
          isCurrentRoute={location.pathname === '/account/orders/history'}
          style={{marginRight: 'auto'}}
          to='/account/orders/history'
        />

        <div className={Styles.filterContainer}>
          <CalendarIcon width={13}/>
          Month
          <DownArrowIcon />
        </div>
        <div className={Styles.filterContainer}>
          <FilterIcon width={13}/>
          Filter
          <DownArrowIcon />
        </div>
      </div>

      <DatatableHeader
        headerColumnArray={[
          {title: 'Data', flex: .2},
          {title: 'Side', flex: .15},
          {title: 'Pair', flex: .1},
          {title: 'Price', flex: .15},
          {title: 'Amount', flex: .15},
          {title: 'Status', flex: .15}
        ]}
      />

      {/* rows container */}
      <div>
        {
          new Array(7).fill(0).map(() => (
            <div className={Styles.orderHistoryRow}>
              <span>10/02/23 09:10 PM</span>
              <span>
                <SellArrowSquare width={26}/>
                <div>
                  <span>Sell</span>
                  <span>18 Feb,2022</span>
                </div>
              </span>
              <span>
                <CoinTag
                  tag={'BTC/USDT'}
                  size={10}
                  style={{width: 'fit-content'}}
                />
              </span>
              <span>$43,975.72</span>
              <span>12,000</span>
              <span>Pending</span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default OrderHistoryTab