import React from 'react'

// packages
import { BounceLoader } from 'react-spinners'

// styles
import Styles from './button.module.css'

// MUI
import MUIButton from '@mui/material/Button'

function Button({
  text,
  onClick = () => {},
  type = 'button',
  width = '100%',
  theme = 'light',
  style,
  className,
  icon,
  iconPosition = 'left',
  isLoading = false
}) {
  return (
    <button
      onClick={() => {
        if (isLoading) return
        onClick()
      }}
      className={`${Styles.button} ${theme === 'dark' && Styles.dark} ${theme === 'outlined' && Styles.outlined} ${theme === 'link' && Styles.link} ${isLoading && Styles.loading} ${className}`}
      style={{width, ...style}}
      type={isLoading ? 'button' : type}
    >
      {iconPosition === 'left' && <span className={Styles.icon}>{icon}</span>}

      {text}

      {iconPosition === 'right' && <span style={{transform: 'translateX(6px) translateY(-1px)'}} className={Styles.icon}>{icon}</span>}
      {
        isLoading && (
          <BounceLoader
            className={Styles.loader}
            size={18}
            color={
              theme === 'light' ?
              'var(--secondary-color)' :
              'var(--primary-color)'
            }
          />
        )
      }

      {
        (!isLoading || theme !== 'link' || theme !== 'outlined') && (
          <div className={Styles.bubble}></div>
        )
      }
      
    </button>

    // <MUIButton
    //   variant="contained"
    //   style={{
    //     color: theme === 'dark' ? 'var(--primary-color)' : 'black',
    //     backgroundColor: theme === 'dark' ? 'var(--secondary-color)' : 'var(--primary-color)',
    //     textTransform: 'none',
    //     borderRadius: 10,
    //     fontSize: 12,
    //     width: '100%',
    //     padding: '11px 17px',
    //     ...style
    //   }}
    //   disableElevation
    //   onClick={onClick}
    // >
    //   {text}
    // </MUIButton>
  )
}

export default Button