import React from 'react'
import Layout from '../components/Layout'
import { Switch, Route } from 'react-router-dom'
import Exchange from '../modules/exchange/views/Exchange'
import Markets from '../modules/markets/views/markets'
// import Profile from '../modules/user/views/profile'
import Settings from './settings'
import Login from '../modules/user/views/login/login'
import Reset from './reset'
import OtpVerify from './otp-verify'
import OtpNumber from './otp-number'
import Lock from './lock'
import TermsAndConditions from './terms-and-conditions'
import NewsDetails from './news-details'
import Signup from '../modules/user/views/signup/signup'
import Notfound from '../modules/not-found/notfound'
import Convert from '../modules/convert/views/Convert'
import PasswordRecovery from '../modules/user/views/passwordRecovery/PasswordRecovery'
import History from '../modules/history/views'
import TwoFA from '../modules/user/views/TwoFA'
import CodeConfirmation from '../modules/user/views/CodeConfirmation'
import LandingPage from '../modules/landing-page/views/LandingPage'
import Blogs from '../modules/blogs/views/Blogs'
import Wallet from '../modules/wallet/views/Wallet'
import Profile from '../modules/profile/views/Profile'
import KYCVerification from '../modules/kyc/views/KYCVerification'
// import Wallet from '../modules/user/views/profile/Wallet'

export default function index() {
  return (
    <>
      <Layout>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/trade/:marketName?">
            <Exchange />
          </Route>
          <Route path="/markets">
            <Markets />
          </Route>
          <Route path="/blogs">
            <Blogs />
          </Route>
          <Route path="/account/orders">
            <Wallet />
          </Route>
          <Route path="/account/trade">
            <Wallet />
          </Route>
          <Route path="/account/convert">
            <Wallet />
          </Route>
          <Route path="/account/wallet">
            <Wallet />
          </Route>
          <Route path="/account/launchpad">
            <Wallet />
          </Route>
          <Route path="/account">
            <Profile />
          </Route>
          <Route path="/convert/history">
            <Wallet />
          </Route>
          <Route path="/convert">
            <Convert />
          </Route>
          <Route path="/history">
            <Wallet />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/reset">
            <Reset />
          </Route>
          <Route path="/otp-verify">
            <OtpVerify />
          </Route>
          <Route path="/otp-number">
            <OtpNumber />
          </Route>
          <Route path="/lock">
            <Lock />
          </Route>
          <Route path="/recover-password">
            <PasswordRecovery />
          </Route>
          <Route path="/2fa">
            <TwoFA />
          </Route>
          <Route path="/confirmation-code">
            <CodeConfirmation />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/news-details">
            <NewsDetails />
          </Route>
          <Route path="/kyc">
            <KYCVerification />
          </Route>
          {/* <Route path="/history">
            <History />
          </Route> */}
          <Route >
            <Notfound />
          </Route>
        </Switch>
      </Layout>
    </>
  )
}
