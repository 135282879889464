import { en } from "./en"
import { ru } from "./ru"
import { zh } from "./zh"
import { ar } from "./ar"
import { ur } from "./ur"
import { es } from "./es"

export const languageMap = {
    default: en,
    en,
    ru,
    zh,
    ar,
    ur,
    es
}
