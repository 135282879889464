import React, { useState, useEffect } from 'react'

// styles
import Styles from './convert.module.css'

// icons
import { ReactComponent as ConvertIcon } from '../../../assets/icons/imported/convert.svg'
import { ReactComponent as LinkIcon } from '../../../assets/icons/imported/link.svg'

// redux
import { useSelector } from 'react-redux'

//other
import { estimateUnitValue } from '../../helpers/estimateValueBase'
import { _getCurrencies, _previewConversion } from '../redux/actions'
import { toast } from 'react-toastify'
import { FormattedMessage, injectIntl } from "react-intl"

// components
import TableButton from '../../../components/TableButton/TableButton'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import Button from '../../../components/Button/Button'
import SelectCurrencyModal from '../components/SelectCurrencyModal'
import ConfirmConvertModal from '../components/ConfirmConvertModal'

function Convert() {
  const markets = useSelector(state => state.marketList.value)
  console.log(markets)
  const selectedMarket = useSelector(state => state.selectedMarket.value)
  const [fromCurrency, setFromCurrency] = useState(markets.find(el => el.id === selectedMarket.id)?.quote_unit)
  const [fromValue, setFromValue] = useState('')
  const [toCurrency, setToCurrency] = useState(markets.find(el => el.id === selectedMarket.id)?.base_unit)
  const [toValue, setToValue] = useState('')
  const [amount, setAmount] = useState(1)
  const [convertedAmount, setConvertedAmount] = useState(0)
  const [currencies, setCurrencies] = useState('')
  const marketTickers = useSelector((state) => state.marketTickers.value)

  const [selectCurrencyModalOpen, setSelectCurrencyModalOpen] = useState(false)
  const [confirmConvertModalOpen, setConfirmConvertModalOpen] = useState(false)
  const [currencySide, setCurrencySide] = useState('') // fromCurrency / toCurrency

  useEffect(() => {

    _getCurrencies(
      (data) => {
        setCurrencies(data)
        console.log(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }, [])

  const handleFromValueChange = (event) => {
    setFromValue(event.target.value)
    setToValue('')
  }

  const handleToValueChange = (event) => {
    setToValue(event.target.value)
    setFromValue('')
  }

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const handleConvert = (convertedValue) => {
    // Perform conversion logic here, using `fromCurrency`, `toCurrency`, and `amount`
    _previewConversion(
      {
        from: fromCurrency,
        to: toCurrency,
        volume: fromValue
      },
      () => {

      },
      (error) => {
        toast.error(
          <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
        )
      }
    )
    setConfirmConvertModalOpen(true)
  }
  let convertedPrice = estimateUnitValue(fromValue ? toCurrency : fromCurrency, fromValue ? fromCurrency : toCurrency, parseFloat(fromValue || toValue), currencies, markets, marketTickers)
  useEffect(() => {
    convertedPrice = estimateUnitValue(fromValue ? toCurrency : fromCurrency, fromValue ? fromCurrency : toCurrency, parseFloat(fromValue || toValue), currencies, markets, marketTickers)
  }, [fromValue])
  return (
    <div className={Styles.pageContainer}>
      <div className={Styles.conversionContainer}>
        <h2>WENBIT CONVERT</h2>
        <TableButton
          buttonText='Conversion History'
          style={{
            marginTop: -20
          }}
          buttonIcon={<LinkIcon style={{
            width: 12
          }} />}
        />
        <CurrencyInput
          title='From'
          containerStyle={{
            backgroundColor: 'var(--secondary-color)'
          }}
          coinName={fromCurrency?.toUpperCase()}
          currencyIcon=''
          currencyImg={currencies ? currencies.find(el => el.id === fromCurrency?.toLowerCase())?.icon_url : ''}
          secondaryTitle='Balance: 100.0'
          type='number'
          value={fromValue ? fromValue : convertedPrice}
          placeholder="0.00"
          onChange={handleFromValueChange}
          showDropdownArrow={true}
          onDropdownClick={() => {
            setCurrencySide('fromCurrency')
            setSelectCurrencyModalOpen(true)
          }}        />
        <ConvertIcon
          color='var(--primary-color)'
          onClick={() => {
            setFromCurrency(toCurrency)
            setFromValue(toValue)
            setToCurrency(fromCurrency)
            setToValue(fromValue)
          }}
        />
        <CurrencyInput
          title='To'
          containerStyle={{
            backgroundColor: 'var(--secondary-color)'
          }}
          secondaryTitle='Balance: 100.0'
          currencyIcon=''
          currencyImg={currencies ? currencies.find(el => el.id === toCurrency?.toLowerCase())?.icon_url : ''}
          coinName={toCurrency?.toUpperCase()}
          type='number'
          value={toValue ? toValue : convertedPrice}
          placeholder="0.00"
          onChange={handleToValueChange}
          showDropdownArrow={true}
          onDropdownClick={() => {
            setCurrencySide('toCurrency')
            setSelectCurrencyModalOpen(true)
          }}
        />
        {console.log(convertedPrice)}
        <Button
          text='Preview Conversion'
          onClick={() => {
            handleConvert()
          }}
        />
      </div>

      {/* -------------- MODALS-------------- */}
      <SelectCurrencyModal
        open={selectCurrencyModalOpen}
        handleClose={() => setSelectCurrencyModalOpen(false)}
        currencies={currencies}
        currencySide={currencySide}
        setFromCurrency={setFromCurrency}
        setToCurrency={setToCurrency}
      />

      <ConfirmConvertModal
        open={confirmConvertModalOpen}
        handleClose={() => setConfirmConvertModalOpen(false)}
        fromCurrency={currencies.length && currencies?.find(el => el.id === fromCurrency)}
        toCurrency={currencies.length && currencies?.find(el => el.id === toCurrency)}
      />

    </div>
  )
}

export default injectIntl(Convert)