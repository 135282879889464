import React from 'react'

// styles
import Styles from './OrderbookRow.module.css'

function OrderbookRow({
    isAsk,
    total,
    order,
    width = total + '%'
}) {
  console.log(order)
  return (
    order ?
    <div className={Styles.rowContainer}>
        <div style={{
            color: isAsk ? 'var(--positive-color)' : 'var(--negative-color)'
        }}>{order[0]}</div>
        <div>{order[1]}</div>
        <span className={Styles.rowBackground} style={{
            width,
            backgroundColor: isAsk ? 'rgb(3, 166, 109, 0.2)' : 'rgb(220, 38, 38, 0.2)'
        }}></span>
        <div>{total}</div>
    </div>
    : null
  )
}

export default OrderbookRow