import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import { 
  persistStore, 
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Reducer imports
import marketReducer from '../modules/exchange/redux/slices/marketListSlice'
import marketTickerReducer from '../modules/exchange/redux/slices/marketTickersSlice'
import tradesReducer from '../modules/exchange/redux/slices/tradesSlice'
import orderBookReducer from '../modules/exchange/redux/slices/orderBookSlice'
import selectedMarketReducer from '../modules/exchange/redux/slices/selectedMarketSlice'
import previousMarketReducer from '../modules/exchange/redux/slices/previousMarketSlice'
import currenciesReducer from '../modules/exchange/redux/slices/currenciesSlice'
import userDataReducer from '../modules/user/redux/slices/userDataSlice'
import themeColorReducer from './slices/themeSlice'
import languageReducer from './slices/languageSlice'
import currencyReducer from './slices/currencySlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['themeColor', 'marketList', 'language', 'marketTickers', 'currencies', 'marketsList', 'selectedMarket']
}

const rootReducer = combineReducers({
  marketList: marketReducer,
  marketTickers: marketTickerReducer,
  trades: tradesReducer,
  orderBook: orderBookReducer,
  userData: userDataReducer,
  themeColor: themeColorReducer,
  selectedMarket: selectedMarketReducer,
  currencies: currenciesReducer,
  previousMarket: previousMarketReducer,
  language: languageReducer,
  currency: currencyReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer
})

export const persistor = persistStore(store)