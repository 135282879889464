import React from 'react'
import { Link, useHistory } from 'react-router-dom'

// components imports
import Button from '../../components/Button/Button'

// styles imports
import Styles from './notFound.module.css'

// icons imports
import { AiFillHome } from 'react-icons/ai'

export default function notfound() {

  const history = useHistory()

  return (
    // <>
    //   <div className="error-page vh-100 d-flex justify-content-center text-center">
    //     <div className="my-auto">
    //       <h2>404</h2>
    //       <p>Oops something went wrong</p>
          // <Link to="/trade" className="btn">
          //   Back to Home <i className="icon ion-md-home"></i>
          // </Link>
    //     </div>
    //   </div>
    // </>

    <div className={Styles.pageContainer}>
      <h2>404</h2>
      <p>Oops something went wrong</p>
      {/* <Link to="/trade" className={Styles.button}>
        Back to Home <i className="icon ion-md-home"></i>
      </Link> */}

      <div>
        <Button
          text='Back to Home'
          icon={<AiFillHome size={18}/>}
          onClick={() => { history.replace('/trade') }}
          theme='dark'
        />
      </div>

    </div>
  )
}
