import React from 'react'

// styles imports
import Styles from './MarketGraphCard.module.css'

// images/icons imports
import UpArrow from './up-arrow.svg'
import DownArrow from './down-arrow.svg'

// graph photo import
import graphImage from './Vector 49.png'

function MarketGraphCard({
  icon = null,
  coinName,
  price,
  priceChangePercentage
}) {
  return (
    <div className={Styles.cardContainer}>

      {/* info container */}
      <div className={Styles.infoContainer}>
        
        {/* icon + name container */}
        <div className={Styles.coinNameContainer}>
          <img src={icon}/>
          <span>{coinName}</span>
        </div>

        {/* details container */}
        <div className={Styles.detailsContainer}>
          <span className={Styles.coinPrice}>{price}</span>
          <div className={Styles.changePercentageContainer}>
            <span className={`${priceChangePercentage > 0 ? Styles.positiveChange : Styles.negativeChange}`}>{priceChangePercentage}%</span>
            <img
              src={
                (priceChangePercentage > 0) ? (
                  UpArrow
                ) : (
                  DownArrow
                )
              }
            />
          </div>
        </div>
      </div>

      {/* graph container */}
      <div className={Styles.graphContainer}>
        <img src={graphImage} />
      </div>

    </div>
  )
}

export default MarketGraphCard