import React from 'react'

// styles imports
import Styles from './radioButton.module.css'

// icons imports
import selectedRadio from './icons/radio-selected.svg'
import notSelectedRadio from './icons/radio-not-selected.svg'

function RadioButton({
    icon,
    text,
    isSelected = false,
    onClick = null
}) {
  return (
    <button
        className={`${Styles.radioButtonContainer} ${isSelected ? Styles.selectedButtonContainer : null}`}
        onClick={onClick}
    >
        
        {/* <div className={Styles.radioCircle}>
            { isSelected && <div className={Styles.radioCheckedCircle}></div> }
        </div> */}
        {
            isSelected ? (
                <img
                    className={Styles.radioImage}
                    src={selectedRadio}
                />
            ) : (
                <img
                    className={Styles.radioImage}
                    src={notSelectedRadio}
                />
            )
        }

        { icon && <div className={Styles.iconContainer}>{icon}</div> }

        <span className={Styles.radioText}>{text}</span>

    </button>
  )
}

export default RadioButton