import * as React from "react"

export class Alert extends React.Component {
    render() {
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M12 2l10 20H2L12 2m0-2c-.758 0-1.45.428-1.79 1.106l-10 20c-.31.62-.276 1.356.09 1.946.363.59 1.007.948 1.7.948h20c.693 0 1.337-.36 1.7-.95.365-.59.4-1.325.09-1.945l-10-20C13.45.428 12.756 0 12 0z"/>
                <path fill="currentColor" d="M12 20c-.832 0-1.507-.672-1.507-1.5S11.168 17 12 17s1.507.672 1.507 1.5S12.832 20 12 20zm.985-4.806c-.093.47-.505.806-.985.806s-.892-.337-.985-.806l-.996-5c-.06-.293.017-.598.208-.83.19-.23.476-.364.776-.364h1.99c.302 0 .587.134.777.365.192.23.27.536.21.83l-.995 5z"/>
            </svg>
        )
    }
}
