// import React, { useState, useEffect } from 'react'

// // styles
// import Styles from './spotWalletTab.module.css'

// // icons
// import { BiWallet } from 'react-icons/bi'
// import { FiEyeOff, FiDownload, FiUpload, FiClock } from 'react-icons/fi'
// import { BsCreditCard2Back } from 'react-icons/bs'
// import { IoMdArrowDropup } from 'react-icons/io'
// import { RiShareBoxFill } from 'react-icons/ri'

// // components
// import Search from '../../../components/Search/Search'
// import CoinTag from '../../../components/CoinTag/CoinTag'
// import Button from '../../../components/Button/Button'
// import ShimmerLine from '../../../components/shimmer-components/ShimmerLine'
// import ShimmerCircle from '../../../components/shimmer-components/ShimmerCircle'


// import { _getCurrencies } from '../../exchange/redux/actions'
// import TableButton from '../../../components/TableButton/TableButton'

// function SpotWalletTab() {
    
//     const [testCurrencies, setTestCurrencies] = useState([])

//     useEffect(() => {
//         _getCurrencies(
//             (data) => {
//                 setTestCurrencies(data)
//             }
//         )
//     }, [])

//   return (
//     <>
//         {/* tab header */}
//         <div className={`${Styles.card} ${Styles.tabHeader}`}>
            
//             {/* left section */}
//             <div>
//                 <h3>Wallet</h3>

//                 <div>
//                     <BiWallet size={20}/>
//                     Wallet Balance
//                 </div>

//                 <div>
//                     <span>$32,455.32</span>
//                     <button>Hide Price <FiEyeOff /></button>
//                 </div>

//             </div>

//             {/* right section */}
//             <div>
//                 <div>
//                     <Button
//                         text='Withdraw'
//                         style={{
//                             flex: .45,
//                             minHeight: 33,
//                             height: 33,
//                             borderRadius:5
//                         }}
//                     />
//                     <Button
//                         text='Deposit'
//                         theme='dark'
//                         style={{
//                             flex: .45,
//                             minHeight: 33,
//                             height: 33,
//                             borderRadius:5
//                         }}
//                     />
//                 </div>

//                 <div>
//                     <div>
//                         <span>
//                             <BsCreditCard2Back />
//                             Total Deposited
//                         </span>
//                         <span>
//                             <FiDownload color='var(--primary-color)' />
//                             $32,455.12
//                         </span>
//                     </div>

//                     <div>
//                         <span>
//                             <FiClock />
//                             Total Withdrawals
//                         </span>
//                         <span>
//                             <FiUpload color='var(--primary-color)' />
//                             $32,455.12
//                         </span>
//                     </div>
//                 </div>

//             </div>

//         </div>

//         {/* wallets */}
//         <div className={`${Styles.card} ${Styles.walletsContainer}`}>
            
//             {/* table header */}
//             <div className={Styles.tableHeader}>
//                 <h4>Wallet</h4>
//                 <Search />
//             </div>

//             {/* table sorting row */}
//             <div className={Styles.tableSortingRow}>
//                 <span>Coin <IoMdArrowDropup /></span>
//                 <span>Avbl. Balance <IoMdArrowDropup /></span>
//                 <span>Locked <IoMdArrowDropup /></span>
//                 <span>Amount <IoMdArrowDropup /></span>
//                 <span>Action <IoMdArrowDropup /></span>
//             </div>

//             {/* table content */}
//             {
//                 testCurrencies ? (
//                     <div className={Styles.tableContent}>
//                 {
//                     testCurrencies.slice(0, 7).map(coin => (
//                         <div key={coin.id}>
//                             <div>
//                                 <img src={coin.icon_url}/>
//                                 <span>{coin.name}</span>
//                                 <CoinTag tag={coin.id} size={12}/>
//                             </div>
//                             <div>$43,975.72</div>
//                             <div>0</div>
//                             <div>$44,727.80	</div>
//                             <div>
//                                 <TableButton
//                                     buttonText='Deposit'
//                                     buttonIcon={<RiShareBoxFill />}
//                                 />
//                                 <TableButton
//                                     buttonText='Withdraw'
//                                     buttonIcon={<RiShareBoxFill />}
//                                 />
//                                 <TableButton
//                                     buttonText='Convert'
//                                     buttonIcon={<RiShareBoxFill />}
//                                 />
//                             </div>
//                         </div>
//                     ))
//                 }
//             </div>
//                 ) : (
                        
//                             new Array(7).fill(0).map(() => (
//                                 <div style={{margin: '17px 0'}}>
//                                     <ShimmerLine width='100%' height='19px'/>
//                                 </div>
//                             ))
                        
//                 )
//             }

//             {/* table footer */}
//             <div className={Styles.tableFooter}>
//                 <span>52 assets</span>

//                 {/* aginations button here */}
//             </div>

//         </div>
//     </>
//   )
// }

// export default SpotWalletTab
import React, { useState, useEffect } from 'react'

// styles
import Styles from './spotWalletTab.module.css'

// icons
import { BiWallet } from 'react-icons/bi'
import { FiEyeOff, FiDownload, FiUpload, FiClock } from 'react-icons/fi'
import { BsCreditCard2Back } from 'react-icons/bs'
import { IoMdArrowDropup } from 'react-icons/io'
import { RiShareBoxFill } from 'react-icons/ri'

// components
import Search from '../../../components/Search/Search'
import CoinTag from '../../../components/CoinTag/CoinTag'
import Button from '../../../components/Button/Button'
import ShimmerLine from '../../../components/shimmer-components/ShimmerLine'
import ShimmerCircle from '../../../components/shimmer-components/ShimmerCircle'


import { _getCurrencies, _getBalances } from '../../exchange/redux/actions'
import TableButton from '../../../components/TableButton/TableButton'

function SpotWalletTab() {
    
    const [testCurrencies, setTestCurrencies] = useState([])
    const [balances, setBalances] = useState([])

    useEffect(() => {
        _getCurrencies(
            (data) => {
                setTestCurrencies(data)
            }
        )
        _getBalances(
            (data) => {
                setBalances(data)
            }
        )
    }, [])

  return (
    <>
        {/* tab header */}
        <div className={`${Styles.card} ${Styles.tabHeader}`}>
            
            {/* left section */}
            <div>
                <h3>Wallet</h3>

                <div>
                    <BiWallet size={20}/>
                    Wallet Balance
                </div>

                <div>
                    <span>$32,455.32</span>
                    <button>Hide Price <FiEyeOff /></button>
                </div>

            </div>

            {/* right section */}
            <div>
                <div>
                    <Button
                        text='Withdraw'
                        style={{
                            flex: .45,
                            minHeight: 33,
                            height: 33,
                            borderRadius:5
                        }}
                    />
                    <Button
                        text='Deposit'
                        theme='dark'
                        style={{
                            flex: .45,
                            minHeight: 33,
                            height: 33,
                            borderRadius:5
                        }}
                    />
                </div>

                <div>
                    <div>
                        <span>
                            <BsCreditCard2Back />
                            Total Deposited
                        </span>
                        <span>
                            <FiDownload color='var(--primary-color)' />
                            $32,455.12
                        </span>
                    </div>

                    <div>
                        <span>
                            <FiClock />
                            Total Withdrawals
                        </span>
                        <span>
                            <FiUpload color='var(--primary-color)' />
                            $32,455.12
                        </span>
                    </div>
                </div>

            </div>

        </div>

        {/* wallets */}
        <div className={`${Styles.card} ${Styles.walletsContainer}`}>
            
            {/* table header */}
            <div className={Styles.tableHeader}>
                <h4>Wallet</h4>
                <Search />
            </div>

            {/* table sorting row */}
            <div className={Styles.tableSortingRow}>
                <span>Coin <IoMdArrowDropup /></span>
                <span>Avbl. Balance <IoMdArrowDropup /></span>
                <span>Locked <IoMdArrowDropup /></span>
                <span>Amount <IoMdArrowDropup /></span>
                <span>Action <IoMdArrowDropup /></span>
            </div>

            {/* table content */}
            {
                balances ? (
                    <div className={Styles.tableContent}>
                {
                    balances.map((coin, index) => (
                        <div key={index}>
                            <div>
                                <img src={testCurrencies.find(el => el.id === coin.currency)?.icon_url}/>
                                <span>{testCurrencies.find(el => el.id === coin.currency)?.name}</span>
                                <CoinTag tag={testCurrencies.find(el => el.id === coin.currency)?.id.toUpperCase()} size={12}/>
                            </div>
                            <div>{coin.balance}</div>
                            <div>{coin.locked}</div>
                            <div>{parseFloat(coin.balance) + parseFloat(coin.locked)}</div>
                            <div>
                                <TableButton
                                    buttonText='Deposit'
                                    buttonIcon={<RiShareBoxFill />}
                                />
                                <TableButton
                                    buttonText='Withdraw'
                                    buttonIcon={<RiShareBoxFill />}
                                />
                                <TableButton
                                    buttonText='Convert'
                                    buttonIcon={<RiShareBoxFill />}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
                ) : (
                        
                            new Array(7).fill(0).map(() => (
                                <div style={{margin: '17px 0'}}>
                                    <ShimmerLine width='100%' height='19px'/>
                                </div>
                            ))
                        
                )
            }

            {/* table footer */}
            <div className={Styles.tableFooter}>
                <span>{balances.length} assets</span>

                {/* aginations button here */}
            </div>

        </div>
    </>
  )
}

export default SpotWalletTab