import React from 'react'

// styles
import Styles from './servicesCard.module.css'

function ServicesCard({
    icon,
    title,
    text
}) {
  return (
    <div className={Styles.card}>
        <img src={icon}/>
        <div>{title}</div>
        <p>{text}</p>
    </div>
  )
}

export default ServicesCard