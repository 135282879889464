import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from "react-intl"

const reset = () => {
  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <form>
            <span><FormattedMessage id={"page.header.signIn.resetPassword.form"} /></span>
            <label htmlFor='email'><FormattedMessage id={"page.forgotPassword.message"} /></label>
            <input
              id='email'
              type="email"
              className="form-control"
              placeholder="Email Address"
            />
            <button type="submit" className="btn btn-primary">
            <FormattedMessage id={"page.header.signIn.resetPassword.button"} />
            </button>
            {/* <h2>
              Remember Password?
              <Link to="/login"> Sign in here</Link>
            </h2> */}
          </form>
        </div>
      </div>
    </>
  )
}
export default injectIntl(reset)