import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
  name: 'themeColor',
  initialState: {
    value: []
  },
  reducers: {
    setThemeColor: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setThemeColor } = themeSlice.actions

export default themeSlice.reducer