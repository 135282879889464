import React from 'react'

// styles
import Styles from './launchpadHistoryTab.module.css'

function LaunchpadHistoryTab() {
  return (
    <div>
        
    </div>
  )
}

export default LaunchpadHistoryTab