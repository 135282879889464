import { API } from '../../../../utility/API'
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"
// import { persistStore } from 'redux-persist'
//************************************//
export const _getCurrencies = (callback) => {
    API.get(`/trading/public/currencies`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _addOrder = (data, callback, callbackErr) => {
    API.post(`trading/market/orders`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}