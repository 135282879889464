import axios from 'axios'

const urlHelper = '/api/v2/'

const stageUrl = 'https://exchange-stage.wenbit.com/api/v2/'
const serverUrl = 'https://exchange.wenbit.com/api/v2/'

export const API = axios.create({
   baseURL: process.env.NODE_ENV === 'production' ? stageUrl : urlHelper
})

export const Axios = {API}