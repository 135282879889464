import React, { useEffect, useState } from 'react'

// icons
import { ReactComponent as WalletIcon } from '../../../assets/icons/imported/wallet.svg'
import { ReactComponent as MarketIcon } from '../../../assets/icons/imported/market.svg'
import { ReactComponent as FlagIcon } from '../../../assets/icons/imported/finish.svg'
import { ReactComponent as MarketCapIcon } from '../../../assets/icons/imported/market-cap.svg'
import coinIcon from "../../../components/MarketGraphCard/Coin.svg"
import { toast } from 'react-toastify'
// translations
import { FormattedMessage } from 'react-intl'

// helpers
import { estimateUnitValue } from '../../helpers/estimateValueBase'

// styles
import Styles from './quickBuySell.module.css'

// components
import Button from '../../../components/Button/Button'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import RadioButton from '../../../components/RadioButton/RadioButton'
import { _addOrder, _getCurrencies } from '../redux/actions'
import { useSelector } from 'react-redux'

function QuickBuySell() {

    const [quantity, setQuantity] = useState('')
    const [apiCurrencies, setApiCurrencies] = useState('')
    const [selectedTab, setSelectedTab] = useState('buy')
    const [selectedRadioButton, setSelectedRadioButton] = useState('limit')
    const [price, setPrice] = useState('')
    const selectedMarket = useSelector(state => state.selectedMarket.value)
    const marketsList = useSelector(state => state.marketList.value)
    const marketTickers = useSelector((state) => state.marketTickers.value)
    const currencies = useSelector((state) => state.currencies.value)
    const [toCurrency, setToCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
    const [fromCurrency, setFromCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value)
        setPrice('')
      }
    
      const handlePriceChange = (event) => {
        setPrice(event.target.value)
        setQuantity('')
      }

      useEffect(() => {
        _getCurrencies(
          (data) => {
            console.log(data)
            setApiCurrencies(data)
          },
          (error) => {
            toast.error(
              <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
            )
          }
        )
      }, [])

      let convertedPrice = estimateUnitValue(quantity ? toCurrency : fromCurrency, quantity ? fromCurrency : toCurrency, parseFloat(quantity || price), currencies ?? apiCurrencies, marketsList, marketTickers)
        useEffect(() => {
            convertedPrice = estimateUnitValue(quantity ? toCurrency : fromCurrency, quantity ? fromCurrency : toCurrency, parseFloat(quantity || price), currencies ?? apiCurrencies, marketsList, marketTickers)
            // console.log(currencies)
            // console.log(apiCurrencies)
            // console.log(convertedPrice)
        }, [quantity])

    const handleSubmit = () => {
        _addOrder(
          {
            market: selectedMarket.id,
            side: selectedTab,
            volume: price ? parseFloat(convertedPrice) : parseFloat(quantity),
            price: price ? parseFloat(price) : parseFloat(convertedPrice),
            ord_type: selectedRadioButton
          },
          () => {
    
          },
          (error) => {
            toast.error(
              <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
            )
          }
        )
      }

  return (
    <div className={Styles.card}>

          {/* tabs container */}
          <div className={Styles.buySellTabsContainer}>
            <button
              className={selectedTab === 'buy' ? Styles.selectedTab : undefined}
              onClick={() => {
                if (selectedTab === 'buy') return
                setSelectedTab('buy')
              }}
            >
              <FormattedMessage id='page.markets.trade.buy' /> {marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}
            </button>
            <button
              className={selectedTab === 'sell' ? Styles.selectedTab : undefined}
              onClick={() => {
                if (selectedTab === 'sell') return
                setSelectedTab('sell')
              }}
            >
              <FormattedMessage id='page.markets.trade.sell' /> {marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}
            </button>
          </div>

          {/* tab content */}
          <div className={Styles.tabContentContainer}>

            {/* radio buttons container */}
            <div className={Styles.radioButtonContainer}>
              <RadioButton
                text={<FormattedMessage id='page.markets.trade.limit' />}
                icon={
                //   <img
                //     src={selectedRadioButton === 'limit' ? flagSelected : flag}
                //     style={{ minWidth: 7 }}
                //   />
                  <FlagIcon />
                }
                isSelected={selectedRadioButton === 'limit'}
                onClick={() => {
                  if (selectedRadioButton === 'limit') return
                  setSelectedRadioButton('limit')
                }}
              />
              <RadioButton
                text={<FormattedMessage id='page.markets.trade.market' />}
                icon={
                //   <img
                //     src={selectedRadioButton === 'market' ? boltSelected : bolt}
                //     style={{ minWidth: 7 }}
                //   />
                    <MarketIcon width={12}/>
                }
                isSelected={selectedRadioButton === 'market'}
                onClick={() => {
                  if (selectedRadioButton === 'market') return
                  setSelectedRadioButton('market')
                }}
              />
            </div>

            {/* wallet amount + coin price container */}
            <div className={Styles.walletAmountContainer}>
              {/* wallet amount */}
              <div>
                <WalletIcon color="#9295A6" className={Styles.walletAmountIcon} />
                <span>$38,447.54</span>
              </div>

              {/* coin price */}
              <div>
                <img
                  src={coinIcon}
                  className={Styles.walletAmountIcon}
                />
                <span>$38,447.54</span>
              </div>
            </div>

            <CurrencyInput
              type='number'
              title={`Quantity (${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()})`}
              placeholder="0.00"
              value={quantity ? quantity : convertedPrice}
              onChange={handleQuantityChange}
              containerStyle={{
                marginBottom: 20
              }}
            />

            <CurrencyInput
              type='number'
              title={selectedTab === 'buy' ? (`Buy For (${marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})`) : (`Sell For (${marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})`)}
              placeholder="0.00"
              value={price ? price : convertedPrice}
              onChange={handlePriceChange}
              containerStyle={{
                marginBottom: 20
              }}
            />
          </div>

          {/* fee info */}
          <span className={Styles.feeInfo}>
            {<FormattedMessage id='page.markets.trade.total' />}: (+{<FormattedMessage id='page.markets.trade.fee' />} 0.2) 0.00
          </span>

          {/* buy/sell button */}
          <Button
            text={selectedTab === 'buy' ? (`Quick Buy ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`) : (`Quick Sell ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`)}
            style={{
              marginTop: 30,
              backgroundColor: selectedTab === 'sell' ? 'rgba(240,68,68,255)' : null,
              color: selectedTab === 'sell' ? 'white' : null
            }}
            onClick={handleSubmit}
          />

        </div>
  )
}

export default QuickBuySell