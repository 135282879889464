import { createSlice } from '@reduxjs/toolkit'

export const previousMarketSlice = createSlice({
  name: 'previousMarket',
  initialState: {
    value: ''
  },
  reducers: {
    setPreviousMarket: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setPreviousMarket } = previousMarketSlice.actions

export default previousMarketSlice.reducer