import { createSlice } from '@reduxjs/toolkit'

export const marketTickersSlice = createSlice({
  name: 'marketTickers',
  initialState: {
    value: []
  },
  reducers: {
    setMarketTickers: (state, payload) => {
      state.value = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMarketTickers } = marketTickersSlice.actions

export default marketTickersSlice.reducer