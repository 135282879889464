import React, { useState, useEffect } from "react"

// react router
import { Link } from "react-router-dom"

// packages
import { FormattedMessage, injectIntl } from "react-intl"
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

// components
import RadioButton from "../../../../components/RadioButton/RadioButton"
import Button from "../../../../components/Button/Button"
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput"
import { toast } from 'react-toastify'

// styles
import Styles from "./marketForm.module.css"

// icons
import { ReactComponent as MarketIcon } from '../../../../assets/icons/imported/market.svg'
import { ReactComponent as FlagIcon } from '../../../../assets/icons/imported/finish.svg'
import bolt from "../../../../assets/icons/bolt.svg"
import boltSelected from "../../../../assets/icons/bolt-selected.svg"
import flag from "../../../../assets/icons/flag.svg"
import flagSelected from "../../../../assets/icons/flag-selected.svg"

// redux
import { _addOrder } from "../../redux/actions"
import { useSelector } from "react-redux"

// other
import {estimateUnitValue} from '../../../helpers/estimateValueBase'

const sliderMarks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%'
  }

function MarketForm() {

  //user data
  const userData = useSelector((state) => state.userData?.value)

  const marketsList = useSelector(state => state.marketList.value)
  const selectedMarket = useSelector(state => state.selectedMarket.value)
  const [buyQuantity, setBuyQuantity] = useState('')
  const [sellQuantity, setSellQuantity] = useState('')
  const [sellPrice, setSellPrice] = useState('')
  const [buyPrice, setBuyPrice] = useState('')
  const [toCurrency, setToCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.quote_unit)
  const [fromCurrency, setFromCurrency] = useState(marketsList.find(el => el.id === selectedMarket.id)?.base_unit)
  const [selectedRadioButton, setSelectedRadioButton] = useState("limit")
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const currencies = useSelector((state) => state.currencies.value)
  const [orderType, setOrderType] = useState('buy')

  let buyConvertedPrice = estimateUnitValue(buyQuantity ? toCurrency : fromCurrency, buyQuantity ? fromCurrency : toCurrency, parseFloat(buyQuantity || buyPrice), currencies, marketsList, marketTickers)
  useEffect(() => {
    buyConvertedPrice = estimateUnitValue(buyQuantity ? toCurrency : fromCurrency, buyQuantity ? fromCurrency : toCurrency, parseFloat(buyQuantity || buyPrice), currencies, marketsList, marketTickers)
  }, [buyQuantity])

  let sellConvertedPrice = estimateUnitValue(sellQuantity ? toCurrency : fromCurrency, sellQuantity ? fromCurrency : toCurrency, parseFloat(sellQuantity || sellPrice), currencies, marketsList, marketTickers)
  useEffect(() => {
    sellConvertedPrice = estimateUnitValue(sellQuantity ? toCurrency : fromCurrency, sellQuantity ? fromCurrency : toCurrency, parseFloat(sellQuantity || sellPrice), currencies, marketsList, marketTickers)
  }, [sellQuantity])

  const handleSubmit = () => {
    _addOrder(
      {
        market: selectedMarket.id,
        side: orderType,
        volume: orderType === 'buy' ? buyPrice ? parseFloat(buyConvertedPrice) : parseFloat(buyQuantity) : sellPrice ? parseFloat(sellConvertedPrice) : parseFloat(sellQuantity),
        price: orderType === 'buy' ? buyPrice ? parseFloat(buyPrice) : parseFloat(buyConvertedPrice) : sellPrice ? parseFloat(sellPrice) : parseFloat(sellConvertedPrice),
        ord_type: selectedRadioButton
      },
      () => {

      },
      (error) => {
        toast.error(
          <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
        )
      }
    )
  }
  const handleBuyQuantityChange = (event) => {
    setBuyQuantity(event.target.value)
    setBuyPrice('')
  }

  const handleBuyPriceChange = (event) => {
    setBuyPrice(event.target.value)
    setBuyQuantity('')
  }

  const handleSellQuantityChange = (event) => {
    setSellQuantity(event.target.value)
    setSellPrice('')
  }

  const handleSellPriceChange = (event) => {
    setSellPrice(event.target.value)
    setSellQuantity('')
  }
  return (
    <div className={Styles.card}>
      {/* radio buttons container */}
      <div className={Styles.radioButtonsContainer}>
        <RadioButton
          text={<FormattedMessage id="page.markets.trade.limit" />}
          icon={
            // <img
            //   src={selectedRadioButton === "limit" ? flagSelected : flag}
            //   style={{ minWidth: 7 }}
            // />
            <FlagIcon />
          }
          isSelected={selectedRadioButton === "limit"}
          onClick={() => {
            if (selectedRadioButton === "limit") return
            setSelectedRadioButton("limit")
          }}
        />
        <RadioButton
          text={<FormattedMessage id="page.markets.trade.market" />}
          icon={
            // <img
            //   src={selectedRadioButton === "market" ? boltSelected : bolt}
            //   style={{ minWidth: 7 }}
            // />
            <MarketIcon width={12}/>
          }
          isSelected={selectedRadioButton === "market"}
          onClick={() => {
            if (selectedRadioButton === "market") return
            setSelectedRadioButton("market")
          }}
        />
      </div>

      {/* MAIN Buy/Sell container */}
      <div className={Styles.mainBuySellContainer}>
        {/* Buy Container */}
        <div className={Styles.buyContainer}>
          <CurrencyInput
           title={`Quantity (${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()})`}
           onChange={handleBuyQuantityChange}
           type="number"
           placeholder="0.00"
           value={buyQuantity ? buyQuantity : buyConvertedPrice}
           containerStyle={{
            marginTop: 15
           }}
           coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])}
           currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
          />
          <CurrencyInput
           title={`Buy for (${marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})`}
           onChange={handleBuyPriceChange}
           type="number"
           placeholder="0.00"
           value={buyPrice ? buyPrice : buyConvertedPrice}
           containerStyle={{
            marginTop: 15
           }}
          />

          {/* slider here */}
          <Slider
            marks={sliderMarks}
            renderMark={(props) => {
              const { value, active, dot, ...rest } = props
          
              const passedMarkStyle = {
                backgroundColor: 'var(--primary-color)',
                border: '2px solid white',
                width: 14,
                height: 14,
                borderRadius: '50%'
              }
          
              const currentMarkStyle = {
                backgroundColor: 'white',
                border: `2px solid ${active ? 'var(--primary-color)' : 'gray'}`,
                width: 20,
                height: 20,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }
          
              const currentDotStyle = {
                backgroundColor: 'var(--primary-color)',
                width: 8,
                height: 8,
                borderRadius: '50%'
              }
          
              return (
                <div
                  {...rest}
                  style={{
                    ...(value <= this.state.value ? passedMarkStyle : {}),
                    ...(active ? currentMarkStyle : {})
                  }}
                >
                  {value === this.state.value && (
                    <>
                      <div style={{ ...currentDotStyle }} />
                      <div style={{ position: 'absolute', top: '50%', left: '-50%', transform: 'translateY(-50%)' }}> {/* to center the mark */}
                        {value}
                      </div>
                    </>
                  )}
                </div>
              )
            }}
            trackStyle={[
              {
                backgroundColor: 'var(--primary-color)'
              }
            ]}
            railStyle={{
                backgroundColor: 'var(--gray-color)'
            }}
            handleStyle={{
              height: 12,
              width: 12,
              marginTop: -3.5,
              backgroundColor: 'white',
              border: `2px solid var(--primary-color)`,
              borderRadius: '50%',
              boxShadow: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            style={{margin: '30px auto', width: '90%'}}
          />

          {/* fees info row */}
          <div className={Styles.feesInfoRow}>
            <span>Available:</span>
            <span>0 BTC = 0 USD</span>
          </div>
          {/* <div className={Styles.feesInfoRow}>
            <span>Volume:</span>
            <span>0 BTC = 0 USD</span>
          </div>
          <div className={Styles.feesInfoRow}>
            <span>Margin:</span>
            <span>0 BTC = 0 USD</span>
          </div> */}
          <div className={Styles.feesInfoRow}>
            <span>{<FormattedMessage id='page.markets.trade.fee' />}:</span>
            <span>0 BTC = 0 USD</span>
          </div>

          {/* buy button */}
          {
            userData.email ? (
              <Button
                text={`Buy ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`}
                style={{
                  marginTop:30,
                  backgroundColor: '#10B981',
                  color: 'white'
                }}
                onClick={e => {
                  setOrderType('buy')
                  handleSubmit()
                }}
              />
            ) : (
              <div className={Styles.loggedOutContainer}>
                <div>
                  <Link to='/login'>Sign In</Link> or <Link to='/signup'>Register Now</Link>
                </div>
              </div>
            )
          }

        </div>

        {/* Sell Container */}
        <div className={Styles.sellContainer}>
          <CurrencyInput
           //title={<FormattedMessage id='page.markets.trade.quantity' />}
           title={`Quantity (${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()})`}
           onChange={handleSellQuantityChange}
           type="number"
           placeholder="0.00"
           value={sellQuantity ? sellQuantity : sellConvertedPrice}
           containerStyle={{
            marginTop: 15
           }}
           currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
           coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])}
           
          />
          <CurrencyInput
           title={`Sell For (${marketsList.find(el => el.id === selectedMarket.id)?.quote_unit.toUpperCase()})`}
           onChange={handleSellPriceChange}
           type="number"
           placeholder="0.00"
           value={sellPrice ? sellPrice : sellConvertedPrice}
           containerStyle={{
            marginTop: 15
           }}
          />

          {/* slider here */}
          <Slider
            marks={sliderMarks}
            renderMark={(props) => {
              const { value, active, dot, ...rest } = props
          
              const passedMarkStyle = {
                backgroundColor: 'var(--primary-color)',
                border: '2px solid white',
                width: 14,
                height: 14,
                borderRadius: '50%'
              }
          
              const currentMarkStyle = {
                backgroundColor: 'white',
                border: `2px solid ${active ? 'var(--primary-color)' : 'gray'}`,
                width: 20,
                height: 20,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }
          
              const currentDotStyle = {
                backgroundColor: 'var(--primary-color)',
                width: 8,
                height: 8,
                borderRadius: '50%'
              }
          
              return (
                <div
                  {...rest}
                  style={{
                    ...(value <= this.state.value ? passedMarkStyle : {}),
                    ...(active ? currentMarkStyle : {})
                  }}
                >
                  {value === this.state.value && (
                    <>
                      <div style={{ ...currentDotStyle }} />
                      <div style={{ position: 'absolute', top: '50%', left: '-50%', transform: 'translateY(-50%)' }}> {/* to center the mark */}
                        {value}
                      </div>
                    </>
                  )}
                </div>
              )
            }}
            trackStyle={[
              {
                backgroundColor: 'var(--primary-color)'
              }
            ]}
            railStyle={{
                backgroundColor: 'var(--gray-color)'
            }}
            handleStyle={{
              height: 12,
              width: 12,
              marginTop: -3.5,
              backgroundColor: 'white',
              border: `2px solid var(--primary-color)`,
              borderRadius: '50%',
              boxShadow: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            style={{margin: '30px auto', width: '90%'}}
          />

          {/* fees info row */}
          <div className={Styles.feesInfoRow}>
            <span>Available:</span>
            <span>0 BTC = 0 USD</span>
          </div>
          {/* <div className={Styles.feesInfoRow}>
            <span>Volume:</span>
            <span>0 BTC = 0 USD</span>
          </div>
          <div className={Styles.feesInfoRow}>
            <span>Margin:</span>
            <span>0 BTC = 0 USD</span>
          </div> */}
          <div className={Styles.feesInfoRow}>
            <span>{<FormattedMessage id='page.markets.trade.fee' />}:</span>
            <span>0 BTC = 0 USD</span>
          </div>

          {/* sell button */}
          {
            userData.email ? (
              <Button
                text={`Sell ${marketsList.find(el => el.id === selectedMarket.id)?.base_unit.toUpperCase()}`}
                style={{
                  marginTop:30,
                  backgroundColor: 'rgba(240,68,68,255)',
                  color: 'white'
                }}
                onClick={e => {
                  setOrderType('sell')
                  handleSubmit()
                }}
              />
            ) : (
              <div className={Styles.loggedOutContainer}>
                <div>
                  <Link to='/login'>Sign In</Link> or <Link to='/signup'>Register Now</Link>
                </div>
              </div>
            )
          }
          
        </div>
      </div>
    </div>
  )
}

export default injectIntl(MarketForm)
