import {API} from '../../../../utility/API'
// import {_setAPIToken} from '../../../../utility/Utils'
// import _ from "lodash"

//************************************//
export const _getMarketsList = (callback) => {
    API.get(`trading/public/markets?page=1`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _getMarketTickers = (callback) => {
    API.get(`trading/public/markets/tickers`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _getMarketTrades = (id, callback) => {
    API.get(`trading/public/markets/${id}/trades`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _getCurrencies = (callback) => {
    API.get(`/trading/public/currencies`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _addOrder = (data, callback, callbackErr) => {
    API.post(`trading/market/orders`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getBalances = (callback) => {
    API.get(`trading/account/balances`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}