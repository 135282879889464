import { API } from '../../../../utility/API'

//************************************//
export const _sendCode = (data, callback, callbackErr) => {
    API.post(`authenticate/resource/phones/send_code`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
