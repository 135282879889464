import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// utility
// import { userData } from '../../../utility/Utils'
import KycModal from '../../helpers/KycModal'

// components
import MarketsList from '../components/MarketsList/MarketsList'
import MarketInsight from '../components/MarketInsight/MarketInsight'
import Orderbook from '../components/Orderbook/Orderbook'
import RecentTrades from '../components/RecentTrades/RecentTrades'
import MarketForm from '../components/MarketForm/MarketForm'
import TradingChartDark from '../old-components/TradingChartDark'
// import MarketNews from '../components/MarketNews'
// import HistoryOrder from '../components/HistoryOrder'
// import MarketHistory from '../components/trades/MarketHistory'
// import MarketPairs from '../components/markets/MarketPairs'
// import MarketTrade from '../old-components/market-trade/MarketTrade'
// import OrderBook from '../components/order-books/OrderBook'
// import TradingChart from '../components/TradingChart'
// import MarketHistory from '../old-components/trades/MarketHistory'

// theme
import { ThemeConsumer } from '../../../context/ThemeContext'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { changeOrderBook } from '../redux/slices/orderBookSlice'
import { setMarketTickers } from '../redux/slices/marketTickersSlice'
import { setPreviousMarket } from '../redux/slices/previousMarketSlice'
import { addTrade, changeTrade } from '../redux/slices/tradesSlice'
import { _getMarketTrades } from '../redux/actions'
// packages
import axios from 'axios'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from './Exchange.module.css'
import Orders from '../components/Orders/Orders'


const ws = new ReconnectingWebSocket(`ws://localhost:3000/api/v2/ws/public/?stream=global.tickers`)

function Exchange() {

  const dispatch = useDispatch()

  const userData = useSelector((state) => state.userData?.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const selectedMarketRef = useRef(selectedMarket)
  const previousMarket = useSelector((state) => state.previousMarket.value)
  //console.log(previousMarket)
  const [show, setShow] = useState(false)
  //console.log(selectedMarket.id)
  const { marketName } = useParams()

  document.title = "Wenbit: Trading"
  useEffect(() => {
    selectedMarketRef.current = selectedMarket.id
    ws.onopen = () => {
      console.log('WebSocket connection opened')
    }
    
    ws.onmessage = (event) => {
        const tickers = JSON.parse(event.data)
        //console.log(selectedMarketRef.current)
        const key = Object.keys(tickers)[0]
        if (key === 'global.tickers') {
          dispatch(setMarketTickers(tickers['global.tickers']))
        }
        if (key === `${selectedMarketRef.current}.ob-inc`) {
          dispatch(changeOrderBook(tickers[`${selectedMarketRef.current}.ob-inc`]))
        }
        if (key === `${selectedMarketRef.current}.trades`) {
          dispatch(addTrade(tickers[`${selectedMarketRef.current}.trades`].trades[0]))
        }
        if (key === `global.tickers`) {
          //console.log("added")
          dispatch(setMarketTickers(tickers[`global.tickers`]))
        }
      
    }
    
    ws.onclose = () => {
      console.log('WebSocket connection closed')
    }
    
    ws.onerror = (error) => {
      console.error(error)
    }

    return () => {
      ws.close()
    }
  }, [])

  useEffect(() => {
    if (selectedMarket.id) {
      _getMarketTrades(selectedMarket.id,
        (data) => {
          dispatch(changeTrade(data))
        },
        (error) => {
          toast.error(
            <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
          )
        }
      )
    }

    selectedMarketRef.current = selectedMarket.id
    const unsubscribeMessage = {
      event: 'unsubscribe',
      streams: [`${previousMarket}.trades`, `${previousMarket}.ob-snap`, `${previousMarket}.ob-inc`, `${previousMarket}.kline-15m`]
    }
    const subscribeMessage = {
      event: 'subscribe',
      streams: [`${selectedMarket.id}.trades`, `${selectedMarket.id}.ob-snap`, `${selectedMarket.id}.ob-inc`, `${selectedMarket.id}.kline-15m`, 'global.tickers']
    }
    const switchMarket = () => {
    
      // send unsubscribe message for the current market
      if (previousMarket) {
        ws.send(JSON.stringify(unsubscribeMessage))
      }
    
      // wait for acknowledgement from the server (if required)
      let acknowledged = false
      const handleAckMessage = (event) => {
        const data = JSON.parse(event.data)
        //console.log("EVENT", data.success.message)
        if (data?.success?.message === 'subscribed' && data?.streams?.includes(subscribeMessage.streams[0])) {
          acknowledged = true
          ws.removeEventListener('message', handleAckMessage)
          ws.send(JSON.stringify(subscribeMessage))
        }
      }
      ws.addEventListener('message', handleAckMessage)
    
      // fallback timeout in case the server does not send an acknowledgement message
      setTimeout(() => {
        if (!acknowledged) {
          ws.removeEventListener('message', handleAckMessage)
          ws.send(JSON.stringify(subscribeMessage))
        }
      }, 5000)
    }
    if (ws.readyState === WebSocket.OPEN) {
      // console.log('SWITCH')
      switchMarket()
    } else {
      // console.log('NO-SWITCH')
      ws.addEventListener('open', switchMarket)
    }
  
    return () => {
      // Unsubscribe from the current market before the component is unmounted
      ws.send(JSON.stringify(unsubscribeMessage))
      // we are trying to subscribe and unsubscribe through the same websocket, till now it didnt work,
      // so we added a close method inorder to not keep useless websockets running
      //ws.close()
    }
  }, [selectedMarket])
  

  return (
    // <>
    //   {/* <button onClick={() => {
    //     ws.send(JSON.stringify({
    //       event:'subscribe',
    //       streams: [`${selectedMarket.id}.trades`, `${selectedMarket.id}.ob-snap`, `${selectedMarket.id}.ob-inc`]
    //     }))
    //   }}>CLICK ME</button> */}
    //   {/* <div className="container-fluid mtb15 no-fluid"> */}
    //   <div className="no-fluid">
    //     {/* <div >
    //           <Button onClick={notify}>
    //             Click me
    //           </Button>
    //           <ToastContainer />

    //         </div> */}
    //     {/* {
    //         data === [] ? 
    //         null : <span>{data[0]}</span>
    //       } */}
    //     <div className={`${styles.pageContainer} row sm-gutters`} >
    //       <div className="col-sm-12 col-md-3">
    //         <MarketPairs />
    //       </div>
    //       <div className="col-sm-12 col-md-6">
    //         <ThemeConsumer>
    //           {({ data }) => {
    //             return data === 'light' ? (
    //               <TradingChart selectedMarket={selectedMarket} />
    //             ) : (
    //               <TradingChartDark selectedMarket={selectedMarket} />
    //             )
    //           }}
    //         </ThemeConsumer>
    //         <MarketTrade />
    //       </div>
    //       <div className="col-md-3">
    //         <OrderBook selectedMarket={selectedMarket} />
    //         <MarketHistory selectedMarket={selectedMarket} />
    //       </div>
    //       {/* <div className="col-md-3">
    //           <MarketNews />
    //         </div> */}
    //       <div className="col-md-12">  {/* col-md-9 */}
    //         <HistoryOrder selectedMarket={selectedMarket} />
    //       </div>
    //     </div>
    //   </div>
    //   {show ? <KycModal show={show} setShow={setShow} /> : null}
    // </>
    <div className={`${Styles.pageContainer}`}>

      {/* LEFT CONTAINER */}
      <div className={`${Styles.leftContainer}`}> 
        <MarketsList />
      </div>

      {/* MIDDLE CONTAINER */}
      <div className={Styles.middleContainer}>
        <MarketInsight />
        <TradingChartDark selectedMarket={selectedMarket} style={{marginBottom: 'var(--gap)'}}/>
        <MarketForm />
        <Orders />
      </div>

      {/* RIGHT CONTAINER */}
      <div className={Styles.rightContainer}>
        {/* <MarketHistory selectedMarket={selectedMarket} /> */}
        <Orderbook />
        <RecentTrades />
      </div>

    </div>
  )
}

export default Exchange