export const nationalitiesNames = {
    "page.body.kyc.nationalities.afghan": "Afghan",
    "page.body.kyc.nationalities.albanian": "Albanian",
    "page.body.kyc.nationalities.algerian": "Algerian",
    "page.body.kyc.nationalities.american": "American",
    "page.body.kyc.nationalities.andorran": "Andorran",
    "page.body.kyc.nationalities.angolan": "Angolan",
    "page.body.kyc.nationalities.antiguans": "Antiguans",
    "page.body.kyc.nationalities.argentinean": "Argentinean",
    "page.body.kyc.nationalities.armenian": "Armenian",
    "page.body.kyc.nationalities.australian": "Australian",
    "page.body.kyc.nationalities.austrian": "Austrian",
    "page.body.kyc.nationalities.azerbaijani": "Azerbaijani",
    "page.body.kyc.nationalities.bahamian": "Bahamian",
    "page.body.kyc.nationalities.bahraini": "Bahraini",
    "page.body.kyc.nationalities.bangladeshi": "Bangladeshi",
    "page.body.kyc.nationalities.barbadian": "Barbadian",
    "page.body.kyc.nationalities.barbudans": "Barbudans",
    "page.body.kyc.nationalities.batswana": "Batswana",
    "page.body.kyc.nationalities.belarusian": "Belarusian",
    "page.body.kyc.nationalities.belgian": "Belgian",
    "page.body.kyc.nationalities.belizean": "Belizean",
    "page.body.kyc.nationalities.beninese": "Beninese",
    "page.body.kyc.nationalities.bhutanese": "Bhutanese",
    "page.body.kyc.nationalities.bolivian": "Bolivian",
    "page.body.kyc.nationalities.bosnian": "Bosnian",
    "page.body.kyc.nationalities.brazilian": "Brazilian",
    "page.body.kyc.nationalities.british": "British",
    "page.body.kyc.nationalities.bruneian": "Bruneian",
    "page.body.kyc.nationalities.bulgarian": "Bulgarian",
    "page.body.kyc.nationalities.burkinabe": "Burkinabe",
    "page.body.kyc.nationalities.burmese": "Burmese",
    "page.body.kyc.nationalities.burundian": "Burundian",
    "page.body.kyc.nationalities.cambodian": "Cambodian",
    "page.body.kyc.nationalities.cameroonian": "Cameroonian",
    "page.body.kyc.nationalities.canadian": "Canadian",
    "page.body.kyc.nationalities.cape.verdean": "Cape Verdean",
    "page.body.kyc.nationalities.central.african": "Central African",
    "page.body.kyc.nationalities.chadian": "Chadian",
    "page.body.kyc.nationalities.chilean": "Chilean",
    "page.body.kyc.nationalities.chinese": "Chinese",
    "page.body.kyc.nationalities.colombian": "Colombian",
    "page.body.kyc.nationalities.comoran": "Comoran",
    "page.body.kyc.nationalities.congolese": "Congolese",
    "page.body.kyc.nationalities.costa.rican": "Costa Rican",
    "page.body.kyc.nationalities.croatian": "Croatian",
    "page.body.kyc.nationalities.cuban": "Cuban",
    "page.body.kyc.nationalities.cypriot": "Cypriot",
    "page.body.kyc.nationalities.czech": "Czech",
    "page.body.kyc.nationalities.danish": "Danish",
    "page.body.kyc.nationalities.djibouti": "Djibouti",
    "page.body.kyc.nationalities.dominican": "Dominican",
    "page.body.kyc.nationalities.dutch": "Dutch",
    "page.body.kyc.nationalities.east.timorese": "East Timorese",
    "page.body.kyc.nationalities.ecuadorean": "Ecuadorean",
    "page.body.kyc.nationalities.egyptian": "Egyptian",
    "page.body.kyc.nationalities.emirian": "Emirian",
    "page.body.kyc.nationalities.equatorial.guinean": "Equatorial Guinean",
    "page.body.kyc.nationalities.eritrean": "Eritrean",
    "page.body.kyc.nationalities.estonian": "Estonian",
    "page.body.kyc.nationalities.ethiopian": "Ethiopian",
    "page.body.kyc.nationalities.fijian": "Fijian",
    "page.body.kyc.nationalities.filipino": "Filipino",
    "page.body.kyc.nationalities.finnish": "Finnish",
    "page.body.kyc.nationalities.french": "French",
    "page.body.kyc.nationalities.gabonese": "Gabonese",
    "page.body.kyc.nationalities.gambian": "Gambian",
    "page.body.kyc.nationalities.georgian": "Georgian",
    "page.body.kyc.nationalities.german": "German",
    "page.body.kyc.nationalities.ghanaian": "Ghanaian",
    "page.body.kyc.nationalities.greek": "Greek",
    "page.body.kyc.nationalities.grenadian": "Grenadian",
    "page.body.kyc.nationalities.guatemalan": "Guatemalan",
    "page.body.kyc.nationalities.guinea.bissauan": "Guinea-Bissauan",
    "page.body.kyc.nationalities.guinean": "Guinean",
    "page.body.kyc.nationalities.guyanese": "Guyanese",
    "page.body.kyc.nationalities.haitian": "Haitian",
    "page.body.kyc.nationalities.herzegovinian": "Herzegovinian",
    "page.body.kyc.nationalities.honduran": "Honduran",
    "page.body.kyc.nationalities.hungarian": "Hungarian",
    "page.body.kyc.nationalities.i.kiribati": "I-Kiribati",
    "page.body.kyc.nationalities.icelander": "Icelander",
    "page.body.kyc.nationalities.indian": "Indian",
    "page.body.kyc.nationalities.indonesian": "Indonesian",
    "page.body.kyc.nationalities.iranian": "Iranian",
    "page.body.kyc.nationalities.iraqi": "Iraqi",
    "page.body.kyc.nationalities.irish": "Irish",
    "page.body.kyc.nationalities.israeli": "Israeli",
    "page.body.kyc.nationalities.italian": "Italian",
    "page.body.kyc.nationalities.ivorian": "Ivorian",
    "page.body.kyc.nationalities.jamaican": "Jamaican",
    "page.body.kyc.nationalities.japanese": "Japanese",
    "page.body.kyc.nationalities.jordanian": "Jordanian",
    "page.body.kyc.nationalities.kazakhstani": "Kazakhstani",
    "page.body.kyc.nationalities.kenyan": "Kenyan",
    "page.body.kyc.nationalities.kittian.nevisian": "Kittian and Nevisian",
    "page.body.kyc.nationalities.kuwaiti": "Kuwaiti",
    "page.body.kyc.nationalities.kyrgyz": "Kyrgyz",
    "page.body.kyc.nationalities.laotian": "Laotian",
    "page.body.kyc.nationalities.latvian": "Latvian",
    "page.body.kyc.nationalities.lebanese": "Lebanese",
    "page.body.kyc.nationalities.liberian": "Liberian",
    "page.body.kyc.nationalities.libyan": "Libyan",
    "page.body.kyc.nationalities.liechtensteiner": "Liechtensteiner",
    "page.body.kyc.nationalities.lithuanian": "Lithuanian",
    "page.body.kyc.nationalities.luxembourger": "Luxembourger",
    "page.body.kyc.nationalities.macedonian": "Macedonian",
    "page.body.kyc.nationalities.malagasy": "Malagasy",
    "page.body.kyc.nationalities.malawian": "Malawian",
    "page.body.kyc.nationalities.malaysian": "Malaysian",
    "page.body.kyc.nationalities.maldivan": "Maldivan",
    "page.body.kyc.nationalities.malian": "Malian",
    "page.body.kyc.nationalities.maltese": "Maltese",
    "page.body.kyc.nationalities.marshallese": "Marshallese",
    "page.body.kyc.nationalities.mauritanian": "Mauritanian",
    "page.body.kyc.nationalities.mauritian": "Mauritian",
    "page.body.kyc.nationalities.mexican": "Mexican",
    "page.body.kyc.nationalities.micronesian": "Micronesian",
    "page.body.kyc.nationalities.moldovan": "Moldovan",
    "page.body.kyc.nationalities.monacan": "Monacan",
    "page.body.kyc.nationalities.mongolian": "Mongolian",
    "page.body.kyc.nationalities.moroccan": "Moroccan",
    "page.body.kyc.nationalities.mosotho": "Mosotho",
    "page.body.kyc.nationalities.motswana": "Motswana",
    "page.body.kyc.nationalities.mozambican": "Mozambican",
    "page.body.kyc.nationalities.namibian": "Namibian",
    "page.body.kyc.nationalities.nauruan": "Nauruan",
    "page.body.kyc.nationalities.nepalese": "Nepalese",
    "page.body.kyc.nationalities.new.zealander": "New Zealander",
    "page.body.kyc.nationalities.nicaraguan": "Nicaraguan",
    "page.body.kyc.nationalities.nigerian": "Nigerian",
    "page.body.kyc.nationalities.nigerien": "Nigerien",
    "page.body.kyc.nationalities.north.korean": "North Korean",
    "page.body.kyc.nationalities.northern.irish": "Northern Irish",
    "page.body.kyc.nationalities.norwegian": "Norwegian",
    "page.body.kyc.nationalities.omani": "Omani",
    "page.body.kyc.nationalities.pakistani": "Pakistani",
    "page.body.kyc.nationalities.palauan": "Palauan",
    "page.body.kyc.nationalities.panamanian": "Panamanian",
    "page.body.kyc.nationalities.papua.new.guinean": "Papua New Guinean",
    "page.body.kyc.nationalities.paraguayan": "Paraguayan",
    "page.body.kyc.nationalities.peruvian": "Peruvian",
    "page.body.kyc.nationalities.polish": "Polish",
    "page.body.kyc.nationalities.portuguese": "Portuguese",
    "page.body.kyc.nationalities.qatari": "Qatari",
    "page.body.kyc.nationalities.romanian": "Romanian",
    "page.body.kyc.nationalities.russian": "Russian",
    "page.body.kyc.nationalities.rwandan": "Rwandan",
    "page.body.kyc.nationalities.saint.lucian": "Saint Lucian",
    "page.body.kyc.nationalities.salvadoran": "Salvadoran",
    "page.body.kyc.nationalities.samoan": "Samoan",
    "page.body.kyc.nationalities.san.marinese": "San Marinese",
    "page.body.kyc.nationalities.sao.tomean": "Sao Tomean",
    "page.body.kyc.nationalities.saudi": "Saudi",
    "page.body.kyc.nationalities.scottish": "Scottish",
    "page.body.kyc.nationalities.senegalese": "Senegalese",
    "page.body.kyc.nationalities.serbian": "Serbian",
    "page.body.kyc.nationalities.seychellois": "Seychellois",
    "page.body.kyc.nationalities.sierra.leonean": "Sierra Leonean",
    "page.body.kyc.nationalities.singaporean": "Singaporean",
    "page.body.kyc.nationalities.slovakian": "Slovakian",
    "page.body.kyc.nationalities.slovenian": "Slovenian",
    "page.body.kyc.nationalities.solomon.islander": "Solomon Islander",
    "page.body.kyc.nationalities.somali": "Somali",
    "page.body.kyc.nationalities.south.african": "South African",
    "page.body.kyc.nationalities.south.korean": "South Korean",
    "page.body.kyc.nationalities.spanish": "Spanish",
    "page.body.kyc.nationalities.sri.lankan": "Sri Lankan",
    "page.body.kyc.nationalities.sudanese": "Sudanese",
    "page.body.kyc.nationalities.surinamer": "Surinamer",
    "page.body.kyc.nationalities.swazi": "Swazi",
    "page.body.kyc.nationalities.swedish": "Swedish",
    "page.body.kyc.nationalities.swiss": "Swiss",
    "page.body.kyc.nationalities.syrian": "Syrian",
    "page.body.kyc.nationalities.taiwanese": "Taiwanese",
    "page.body.kyc.nationalities.tajik": "Tajik",
    "page.body.kyc.nationalities.tanzanian": "Tanzanian",
    "page.body.kyc.nationalities.thai": "Thai",
    "page.body.kyc.nationalities.togolese": "Togolese",
    "page.body.kyc.nationalities.tongan": "Tongan",
    "page.body.kyc.nationalities.trinidadian.tobagonian":
        "Trinidadian/Tobagonian",
    "page.body.kyc.nationalities.tunisian": "Tunisian",
    "page.body.kyc.nationalities.turkish": "Turkish",
    "page.body.kyc.nationalities.tuvaluan": "Tuvaluan",
    "page.body.kyc.nationalities.ugandan": "Ugandan",
    "page.body.kyc.nationalities.ukrainian": "Ukrainian",
    "page.body.kyc.nationalities.uruguayan": "Uruguayan",
    "page.body.kyc.nationalities.uzbekistani": "Uzbekistani",
    "page.body.kyc.nationalities.venezuelan": "Venezuelan",
    "page.body.kyc.nationalities.vietnamese": "Vietnamese",
    "page.body.kyc.nationalities.welsh": "Welsh",
    "page.body.kyc.nationalities.yemenite": "Yemenite",
    "page.body.kyc.nationalities.zambian": "Zambian",
    "page.body.kyc.nationalities.zimbabwean": "Zimbabwean"
}
export const nationalities = Object.keys(nationalitiesNames)
