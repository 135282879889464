import React from 'react'

// react router
import { useHistory, useLocation } from 'react-router-dom'

// icons
import { ReactComponent as UserIcon } from '../../../assets/icons/imported/profile.svg'
import { ReactComponent as LockIcon } from '../../../assets/icons/imported/lock.svg'
import { ReactComponent as BellIcon } from '../../../assets/icons/imported/bell.svg'
import { ReactComponent as DocumentIcon } from '../../../assets/icons/imported/document.svg'
import { ReactComponent as TVIcon } from '../../../assets/icons/imported/tv.svg'

//redux
import { useDispatch, useSelector } from 'react-redux'

// styles
import Styles from './profile.module.css'

// components
import SideNavButton from '../../../components/SideNavButton/SideNavButton'

// tabs
import ProfileTab from '../components/ProfileTab/ProfileTab'
import SecurityTab from '../components/SecurityTab/SecurityTab'
import NotificationsTab from '../components/NotificationsTab/NotificationsTab'
import AccountActivitesTab from '../components/AccountActivitesTab/AccountActivitesTab'
import KYCVerificationTab from '../components/KYCVerificationTab/KYCVerificationTab'

function Profile() {
    const userData = useSelector(state => state.userData.value)

    const history = useHistory()
    const location = useLocation()

  return (
    <div className={`${Styles.pageContainer}`}>
        
        {/* side nav */}
        <div className={`${Styles.card}`}>

            {/* side nav header */}
            <div className={Styles.sideNavHeader}>
                <img src='/img/avatar.svg'/>
                <div>James Gordon</div>
                <span>UID: {userData?.uid}</span>
            </div>

            {/* side nav buttons container */}
            <div className={Styles.sideNavButtonsContainer}>
                <SideNavButton
                    text='My Profile'
                    icon={<UserIcon width={12}/>}
                    isSelected={location.pathname.endsWith('/profile')}
                    onClick={() => history.replace('/account/profile')}
                />
                <SideNavButton
                    text='Security'
                    icon={<LockIcon width={12}/>}
                    isSelected={location.pathname.endsWith('/security')}
                    onClick={() => history.replace('/account/security')}
                />
                <SideNavButton
                    text='Notification Preferences'
                    icon={<BellIcon width={13} />}
                    isSelected={location.pathname.endsWith('/notification')}
                    onClick={() => history.replace('/account/notification')}
                />
                <SideNavButton
                    text='Account Activities'
                    icon={<TVIcon width={14} />}
                    isSelected={location.pathname.endsWith('/activities')}
                    onClick={() => history.replace('/account/activities')}
                />
                <SideNavButton
                    text='KYC Verification'
                    icon={<DocumentIcon width={12} />}
                    isSelected={location.pathname.endsWith('/kyc')}
                    onClick={() => history.replace('/account/kyc')}
                />
            </div>

        </div>

        {/* main container */}
        <div className={`${Styles.card}`}>
            {
                location.pathname.endsWith('/profile') && (
                    <ProfileTab userData={userData} />
                )
            }
            {
                location.pathname.endsWith('/security') && (
                    <SecurityTab />
                )
            }
            {
                location.pathname.endsWith('/notification') && (
                    <NotificationsTab />
                )
            }
            {
                location.pathname.endsWith('/activities') && (
                    <AccountActivitesTab />
                )
            }
            {
                location.pathname.endsWith('/kyc') && (
                    <KYCVerificationTab />
                )
            }
        </div>

    </div>
  )
}

export default Profile