import React from 'react'

// styles imports
import Styles from './marketRow.module.css'

function MarketRow({
    coinIcon,
    coinName,
    coinPrice,
    coinPriceChange,
    twentyFourHoursHighPrice,
    twentyFourHoursLowPrice
}) {
  return (
    <div className={Styles.rowContainer}>

    </div>
  )
}

export default MarketRow