import React, { forwardRef, useState } from 'react'

//icons
import { FiEye, FiEyeOff } from 'react-icons/fi'

// styles
import Styles from './input.module.css'

// MUI
import SelectUnstyled from '@mui/base/SelectUnstyled'
import OptionUnstyled from '@mui/base/OptionUnstyled'

// components
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import './phoneNumber.css'

const Input = forwardRef(({
    id,
    name,
    placeholder,
    type = 'text',
    onChange = () => {},
    icon,
    error,
    style,
    inputLabel,
    containerStyle,
    inputIconContainerStyle,
    ...rest 
}, ref) => {

    const [passwordShown, setPasswordShown] = useState(false)

    if (type === 'password') {
        return (
            <div className={Styles.inputContainer}>
            {
                inputLabel && (
                    <span className={Styles.labelSpan}>{inputLabel}</span>
                )
            }
            <div className={`${Styles.passwordInputContainer} ${error?.message && Styles.errorInput}`} style={{...containerStyle}}>
                <input
                    type={passwordShown ? 'text' : 'password'}
                    placeholder={placeholder}
                    id={id}
                    onChange={onChange}
                    name={name}
                    ref={ref}
                    {...rest}
                    style={{ ...style }}
                    />
                <div onClick={() => setPasswordShown(!passwordShown)}>
                {
                    passwordShown ? (
                        <FiEye color='#9295A6' />
                        ) : (
                    <FiEyeOff color='#9295A6' />
                    )
                }
                </div>
            </div>
            <span className={Styles.errorSpan}>
                {
                    error?.message ? error.message : ' '
                }
            </span>
        </div>
        )
    } else if (type === 'text') {
        return (
            <div className={`${Styles.inputContainer}`} style={containerStyle}>
                {
                    inputLabel && (
                        <span className={Styles.labelSpan}>{inputLabel}</span>
                    )
                }
                <div className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`} style={inputIconContainerStyle}>
                    <input
                    type={type}
                    className={`${Styles.input}`}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    onChange={onChange}
                    ref={ref}
                    {...rest}
                    style={{ ...style }}
                    />
                    {icon && (<span className={Styles.inputIcon}>{icon}</span>)}
                </div>
                <span className={Styles.errorSpan}>
                    {
                        error?.message ? error.message : ' '
                    }                
                </span>
            </div>
      )
    } else if (type === 'phoneNumber') {
        return (
            <div className={`${Styles.inputContainer}`} style={containerStyle}>
                {
                    inputLabel && (
                        <span className={Styles.labelSpan}>{inputLabel}</span>
                    )
                }
                <div className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`} style={inputIconContainerStyle}>
                     
                    <PhoneInput
                        country={'us'}
                        containerStyle={{
                            border: 'none !important',
                            background: 'none !important',
                            margin: '3px 5px'
                        }}
                        containerClass={Styles.phoneInputContainer}
                        inputStyle={{
                            border: 'none',
                            background: 'none',
                            borderColor: 'transparent !important',
                            color: 'white',
                            boxShadow: 'none !important',
                            '&:focus': {
                                boxShadow: 'none !important'
                            }
                        }}
                        inputClass={Styles.phoneInput}
                        buttonClass={Styles.flagButton}
                        buttonStyle={{
                            // backgroundColor: 'transparent',
                            // border: 'none',
                            // outline: 'none',
                            // '&:hover': {
                            //     backgroundColor: 'transparent !important'
                            // }
                        }}
                        dropdownStyle={{
                            backgroundColor: 'var(--secondary-color)',
                            fontSize: 12,
                            boxShadow: '0 5px 8px rgba(0, 0, 0, 0.55)',
                            border: 'none',
                            borderRadius:  5
                        }}
                        onChange={onChange}
                        // value={this.state.phone}
                        // onChange={phone => this.setState({ phone })}
                    />
                    <span className={Styles.inputIcon}>{icon}</span>
                </div>
                <span className={Styles.errorSpan}>
                    {
                        error?.message ? error.message : ' '
                    }                
                </span>
            </div>
        )
    } else if (type === 'select') {
        return (
            <div className={`${Styles.inputContainer}`} style={containerStyle}>
                {
                    inputLabel && (
                        <span className={Styles.labelSpan}>{inputLabel}</span>
                    )
                }
                <div className={`${Styles.inputIconContainer} ${error?.message && Styles.errorInput}`} style={inputIconContainerStyle}>
                    <SelectUnstyled
                        className={Styles.selectContainer}
                        defaultValue='en'
                    >
                        {/* <div className={Styles.optionsContainer}> */}
                            <OptionUnstyled value='en'>English</OptionUnstyled>
                            <OptionUnstyled value='ar'>Arabic</OptionUnstyled>
                            <OptionUnstyled value='fr'>French</OptionUnstyled>
                            <OptionUnstyled value='ru'>Russian</OptionUnstyled>
                        {/* </div> */}
                    </SelectUnstyled>
                    {icon && (<span className={Styles.inputIcon}>{icon}</span>)}
                </div>
                <span className={Styles.errorSpan}>
                    {
                        error?.message ? error.message : ' '
                    }                
                </span>
            </div>
        )
    }
})

Input.displayName = 'Input'
export default Input