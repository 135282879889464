import React from 'react'

// redux
import { useSelector } from 'react-redux'

// styles
import Styles from './convert.module.css'

// icons
import { ReactComponent as ConvertIcon } from '../../../assets/icons/imported/convert.svg'

// components
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import Button from '../../../components/Button/Button'


function Convert() {

    const marketsList = useSelector(state => state.marketList.value)
    const selectedMarket = useSelector(state => state.selectedMarket.value)

  return (
    <div className={Styles.card}>
        <h5>Convert</h5>
        <CurrencyInput
           title='From'
           type="number"
           placeholder="0.00"
           currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
           coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])} 
           secondaryTitle='Balance: 100.0'
        />

        <ConvertIcon color='var(--gray-color)'/>

        <CurrencyInput
           title='To'
           type="number"
           placeholder="0.00"
           currencyImg={marketsList.find(el => el.id === selectedMarket.id)?.icon_url}
           coinName={useSelector(state => state.selectedMarket.value?.name?.split('/')[0])} 
           secondaryTitle='Balance: 100.0'
        />

        <Button
            text='Convert'
        />
    </div>
  )
}

export default Convert