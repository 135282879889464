import React from 'react'

// styles
import Styles from './securityTab.module.css'

// icons
import EmailAuthIcon from '../../assets/security/email-authentication.svg'
import SMSAuthIcon from '../../assets/security/sms-authentication.svg'
import GoogleAuthIcon from '../../assets/security/google-authentication.svg'

// components
import Button from '../../../../components/Button/Button'
import Input from '../../../../components/Input/Input'

function SecurityTab() {
  return (
    <div className={Styles.tabContainer}>
        <h4>Security</h4>

        <div className={Styles.authenticatorRow}>
          <div className={Styles.authenticatorRowLeft}>
            <img src={EmailAuthIcon}/>
            <div>
              <span>Email Authentication</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>
          <Button
            theme='dark'
            text='Disable'
            style={{
              width: 'fit-content',
              transform: 'scale(.85)'
            }}
          />
        </div>

        <div className={Styles.authenticatorRow}>
          <div className={Styles.authenticatorRowLeft}>
            <img src={SMSAuthIcon}/>
            <div>
              <span>SMS Authentication</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>
          <Button
            theme='light'
            text='Enable'
            style={{
              width: 'fit-content',
              transform: 'scale(.85)'
            }}
          />
        </div>

        <div className={Styles.authenticatorRow}>
          <div className={Styles.authenticatorRowLeft}>
            <img src={GoogleAuthIcon}/>
            <div>
              <span>Google Authentication</span>
              <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
          </div>
          <Button
            theme='light'
            text='Enable'
            style={{
              width: 'fit-content',
              transform: 'scale(.85)'
            }}
          />
        </div>

        {/* password section */}
        <div className={Styles.passwordSection}>

          {/* section header */}
          <div>
            <h6>Password</h6>
            <span className={Styles.smallLabel}>Set a unique password to protect your personal account.</span>
          </div>

          <Input
            type='password'
            containerStyle={{
              backgroundColor: 'black'
            }}
            style={{
              backgroundColor: 'black'
            }}
            inputLabel='Old Password'
          />

          {/* new password container */}
          <div className={Styles.newPasswordContainer}>
            <Input
              type='password'
              containerStyle={{
                backgroundColor: 'black'
              }}
              style={{
                backgroundColor: 'black'
              }}
              inputLabel='New Password'
            />
            <Input
              type='password'
              containerStyle={{
                backgroundColor: 'black'
              }}
              style={{
                backgroundColor: 'black'
              }}
              inputLabel='Re-enter Password'
            />
          </div>

          {/* change password footer */}
          <div className={Styles.tabFooterContainer}>
              <span className={Styles.smallLabel} style={{flex: 1}}>To ensure your account is well protected, please use 8 or more characters with a mix of letters, numbers & symbols.</span>
              <Button
                text='Save Password'
                style={{
                  width: 'fit-content'
                }}
              />
          </div>

        </div>
    </div>
  )
}

export default SecurityTab