import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { _login } from '../../redux/actions'
import _, { isEmpty } from 'lodash'
import { Tab, Tabs, Row, Col, Nav } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from "react-intl"

const Activity = () => {
    const data = []
    return (
        <>
            <Tabs defaultActiveKey="actions">
                <Tab eventKey="actions" title={(<FormattedMessage id='page.body.profile.header.accountActivity.content.action' />)}>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id='page.body.profile.header.accountActivity.content.date' /></th>
                                    <th><FormattedMessage id='page.body.profile.header.accountActivity.content.action' /></th>
                                    <th><FormattedMessage id='page.body.profile.header.accountActivity.content.result' /></th>
                                    <th><FormattedMessage id='page.body.profile.header.accountActivity.content.addressip' /></th>
                                    <th><FormattedMessage id='page.body.profile.header.accountActivity.content.userAgent' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(market => (
                                    <tr data-href="exchange-light.html">
                                        <td>
                                            {market.name}
                                        </td>
                                        <td>
                                            <img src={'img/icon/1.png'} alt="eth" /> {market.base_unit.toUpperCase()}
                                        </td>
                                        <td>{market.last}</td>
                                        <td className={market.price_change_percent[0] === '-' ? 'red' : 'green'}>{market.price_change_percent}</td>
                                        {/* <td>7444.91</td>
                                <td>7267.06</td> */}
                                        <td>{market.vol}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Tab>
            </Tabs>
        </>
    )
}
export default injectIntl(Activity)