import React from 'react'
import { Link } from 'react-router-dom'

// styles
import Styles from './footerLink.module.css'

function FooterLink({
  text,
  to = '/',
  animationDirection = 'right'
}) {
  return (
    <Link className={`${Styles.link} ${animationDirection === 'right' ? Styles.right : Styles.up}`} to={to}>{text}</Link>
  )
}

export default FooterLink