import { createSlice } from '@reduxjs/toolkit'

export const orderBookSlice = createSlice({
  name: 'orderBook',
  initialState: {
    value: {}
  },
  reducers: {
    changeOrderBook: (state, payload) => {
        state.value = payload.payload
    },
    clearOrderBook: (state, payload) => {
      state.value = []
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeOrderBook, clearOrderBook } = orderBookSlice.actions

export default orderBookSlice.reducer