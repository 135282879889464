import React, { useState, useContext } from 'react'

// react router
import { Link, useHistory } from 'react-router-dom'
// import { Form, Input, Label, FormFeedback, Button } from 'reactstrap'
// import classnames from 'classnames'
// import FormGroup from '@mui/material/FormGroup'

// utilities
import _, { isEmpty } from 'lodash'

// context
import { ThemeContext } from '../../../../context/ThemeContext'

// translations
import { FormattedMessage, injectIntl } from "react-intl"

// redux
import { _login } from '../../redux/actions'
import { useDispatch } from "react-redux"
import { setUser } from "../../redux/slices/userDataSlice"

// styles
import Styles from './login.module.css'

// components
import { Form } from 'reactstrap'
import Button from '../../../../components/Button/Button'
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// photos
import illustrationPhoto from '../../assets/images/login-signup-illustration.png'

// form validations
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Input from '../../../../components/Input/Input'

// login schema
const logInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required')
})


const login = () => {

  const [rememberMe, setRememberMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(logInSchema),
    mode: 'onSubmit'
  })
  const theme = useContext(ThemeContext).data
  // console.log(theme)
  const dispatch = useDispatch()
  const history = useHistory()

  document.title = "Wenbit: Login"
  const _loginSuccessCallback = (res) => {
    // console.log(res)
    if (res?.email) {
      dispatch(setUser(res))
      if (rememberMe) {
        console.log("REMEMBER")
        const user = {
          email: res.email,
          csrf_token: res.csrf_token,
          remember_me: true
        }
        sessionStorage.setItem('user', JSON.stringify(user))
      } else {
        const user = {
          email: res.email,
          csrf_token: res.csrf_token
        }
        sessionStorage.setItem('user', JSON.stringify(user))
      }
      const userJson = sessionStorage.getItem('user')
      if (userJson) {
        const user = JSON.parse(userJson)
        // authenticate the user based on the email and CSRF token
        // ...
        if (user.remember_me) {
          // set a persistent cookie
          document.cookie = `_barong_session=${res.session}; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/; Secure; SameSite=Strict`
        } else {
          // set a session cookie
          document.cookie = `_barong_session=${res.session}; path=/; Secure; SameSite=Strict`
        }
        // remove the user data from session storage
        //sessionStorage.removeItem('user')
      } else {
        // authentication failed, remove the user data from session storage
        //sessionStorage.removeItem('user')
      }
      history.push('/trade')

    }
  }

  const onSubmit = (data) => {
    setIsLoading(true)
    // console.log(data)
    if (isEmpty(errors)) {
      _login(
        {
          email: data.email,
          password: data.password
        },
        (data) => {
          setIsLoading(false)
          _loginSuccessCallback(data)
        },
        (error) => {
          console.log(error)
          setIsLoading(false)
          toast.error(
            <FormattedMessage id={error.errors[0] ?? "page.body.history.withdraw.content.status.errored"} />
          )
        }
      )
    }
  }

  return (
    <div className={Styles.pageContainer}>

      {/* illustration container */}
      <div className={Styles.illustrationContainer}>
        <img
          src={illustrationPhoto}
        />
      </div>

      {/* form container */}
      <div className={Styles.formContainer}>

        {/* logo */}
        <img
          src='https://exchange.wenbit.com/Wenbit-Logo-Light-RGB.svg'
          onClick={() => history.replace('/trade')}
          style={{ cursor: 'pointer' }}
        />

        {/* form */}
        <Form onSubmit={handleSubmit(onSubmit)} className={Styles.formDiv}>

          {/* title */}
          <span className={Styles.title}><FormattedMessage id={"page.header.signInToWenbit"} /></span>

          {/* mail input */}

          <Input
            id='email'
            placeholder='Email'
            error={errors.email}
            {...register('email')}
          />

          {/* <input
            type='text'
            className={Styles.mailInput}
            placeholder='Email'
            id='email'
            onChange={e => {
              setEmail(e.target.value)
            }}
            {...register('email')}
          /> */}
          {/* {errors.email && <span>{errors.email.message}</span>} */}

          {/* password input */}
          <Input
            type='password'
            {...register('password')}
            id='password'
            placeholder='Password'
            error={errors.password}
          />
          {/* <div className={Styles.passwordInputContainer}>
            <input
              type={passwordShown ? 'text' : 'password'}
              placeholder='Password'
              id='password'
              onChange={e => {
                setPassword(e.target.value)
              }}
              {...register('password')}
            />
            <div onClick={() => setPasswordShown(!passwordShown)}>
              {
                passwordShown ? (
                  <FiEye color='#9295A6' />
                ) : (
                  <FiEyeOff color='#9295A6' />
                )
              }
            </div>
          </div> */}
          {/* {errors.password && <span>{errors.password.message}</span>} */}

          {/* forgot password link */}
          <Link to={'/recover-password'} className={Styles.forgotPassword}><FormattedMessage id={"page.header.signIn.forgotPassword"} /></Link>

          {/* remember me? */}
          <CheckboxInput
            label='Remember me'
            labelFor='remember-me'
            checkboxId='remember-me'
            checked={rememberMe}
            setChecked={setRememberMe}
            containerStyle={{ margin: '20px 0' }}
          />

          {/* sign in button */}
          <Button
            text={<FormattedMessage id={"page.header.signIn"} />}
            type='submit'
            isLoading={isLoading}
            style={{ marginTop: 30 }}
          />

          {/* register link */}
          <span className={Styles.registerSpan}><FormattedMessage id={"page.signIn.signupExists"} /><Link to='/signup'><FormattedMessage id={"page.signIn.signup"} /></Link></span>
        </Form>
      </div>

    </div>
    // <>
    //   <div className="vh-100 d-flex justify-content-center">
    //     <div className="form-access my-auto">
    //       <Form action='/' onSubmit={handleSubmit(onSubmit)}>
    //         <span><FormattedMessage id={"page.header.navbar.signIn"} /></span>
    //         <FormGroup className="form-group">
    //           <Input
    //             autoFocus
    //             style={theme === 'dark' ? { color: "white" } : { color: "black" }}
    //             type='email'
    //             id='email'
    //             name='email'
    //             required
    //             placeholder='Email Address'
    //             onChange={e => {
    //               setEmail(e.target.value)
    //             }}
    //           // className={classnames('input-group-merge', { 'is-invalid': errors['email'] })}

    //           />
    //         </FormGroup>

    //         {/* <FormGroup className="form-group">
    //           <Input id='email' className="form-control" type='email' placeholder='Email Address' required />
    //         </FormGroup> */}

    //         <FormGroup className="form-group">
    //           <Input
    //             style={theme === 'dark' ? { color: "white" } : { color: "black" }}
    //             id='password'
    //             placeholder='Password'
    //             type='password'
    //             name='password'
    //             required
    //             onChange={e => {
    //               setPassword(e.target.value)
    //             }}
    //           // className={classnames('input-group-merge', { 'is-invalid': errors['password'] })}
    //           />
    //         </FormGroup>
    //         {/* <FormGroup className="form-group">
    //           <Input id='password' className="form-control" type='password' placeholder='Password' required />
    //         </FormGroup> */}

    //         <div className="text-right">
    //           <Link to="/reset"><FormattedMessage id={"page.header.signIn.forgotPassword"} /></Link>
    //         </div>
    //         <div className="custom-control custom-checkbox">
    //           <input
    //             type="checkbox"
    //             className="custom-control-input"
    //             id="form-checkbox"
    //             onChange={e => setRememberMe(e.target.checked)}
    //           />
    //           <label className="custom-control-label" htmlFor="form-checkbox">
    //             Remember me
    //           </label>
    //         </div>
    //         <Button color='primary' type="submit" className="btn btn-primary">
    //         <FormattedMessage id={"page.header.navbar.signIn"} />
    //         </Button>
    //       </Form>
    //       <h2>
    //       <FormattedMessage id={"page.signIn.signupExists"} /> <Link to="/signup"><FormattedMessage id={"page.signIn.signup"} /></Link>
    //       </h2>
    //     </div>
    //   </div>
    // </>
  )
}
export default injectIntl(login)